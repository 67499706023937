/****************
Night Mood
****************/

.btn-night-mode .btn-night-mode-switch {
    display: inline-block;
    height: 18px;
    width: 37px;
    top: 35%;
    right: 13px;
    position: absolute;
}

.btn-night-mode .uk-switch-button {
    background-color: rgba(0, 0, 0, 0.09);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: .2s;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}

.btn-night-mode .uk-switch-button:before {
    content: '';
    background-color: #a9a9a9;
    position: absolute;
    width: 21px;
    height: 21px;
    left: 0px;
    bottom: -1px;
    border-radius: 50%;
    transition-property: transform, box-shadow;
    transition-duration: .2s;
}

.night-mode .btn-night-mode .uk-switch-button {
    background-color: #4e4e4e !important;
}

.night-mode .btn-night-mode .uk-switch-button:before {
    transform: scale(1.3);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
    transform: translateX(17px);
    background: #fff;
}


.night-mode html , 
.night-mode body , 
.night-mode .main_content ,
.night-mode .bg-gradient-warning  ,
.night-mode .sidebar ,
.night-mode .nav-profile ,
.night-mode .post .post-add-comment-text-area,
.night-mode .post .post-add-comment-text-area input{
    background-color: #000 !important;
    border-color: #242424 !important;
    color: hsla(0, 0%, 100%, 0.79) !important;
}

.night-mode body {
    background: #000 !important;
	height: auto;
    float: left;
    width: 100%;
}

.night-mode body::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}

.night-mode body::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.night-mode body::-webkit-scrollbar-corner {
    background-color: transparent;
}

.night-mode body::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(51,51,51,.6);
    -webkit-box-shadow: inset -1px -1px 0 rgba(51,51,51,.05), inset 1px 1px 0 rgba(51,51,51,.05);
}

.night-mode .header {
    background: #242424;
}

.night-mode .srch10 {
    background: rgba(255, 255, 255, 0.15)!important;
    border: 0 !important;
    color: #393939 !important;
}

.night-mode i.icon1 {
    font-size: 16px !important;
    color: rgba(255, 255, 255, 0.9) !important;
}

.night-mode .ui[class*="left icon"].swdh10.input>input:focus {
	background-color: #fff !important;
}

.night-mode .srch10:focus+.icon1 {
	color: #333 !important;
}

.night-mode .menu.dropdown_ms {
    width: 300px;
    margin-top: 12px !important;
    border-top: 1px solid #242424 !important;
    border-radius: 3px !important;
    min-width: 300px !important;
    background: #171717 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.night-mode .menu.dropdown_mn {
    width: 300px;
    margin-top: 12px !important;
    border-top: 1px solid #242424 !important;
    border-radius: 3px !important;
    min-width: 300px !important;
    background: #171717 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.night-mode .menu.dropdown_account {
    margin-top: 10px !important;
    border-top: 1px solid #242424 !important;
    border-radius: 3px !important;
	background: #171717 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.night-mode .channel_my {
    padding: 15px !important;
    border-bottom: 1px solid #242424 !important;
    min-width: 100%;
    max-width: 100%;
    white-space: normal;
}

.night-mode  .pd_content p {
    color: #c7c7c7 !important;
}

.night-mode .ui.dropdown .menu>.channel_my:hover {
    color: #fff !important;
    background: #333 !important;
}

.night-mode .vbm_btn {
    color: #fff !important;
}

.night-mode .vbm_btn:hover {
    background: #cc2622;
    color: #fff !important;
}

.night-mode  .option_links:hover, .night-mode  .option_links:focus {
    color: #fff !important;
}

.night-mode .night_mode_switch__btn a i {
	background-color: #333 !important;
    color: white !important;
}

.night-mode .pd_content span {
    color: #c7c7c7;
}

.night-mode .night_mode_switch__btn {
    border-bottom: 1px solid #242424;
}

.night-mode .ui.dropdown .menu>.channel_item {
    color: #c7c7c7 !important;
    padding: .78571429rem 1.14285714rem!important;
    font-size: 14px !important;
}

.night-mode .ui.dropdown .menu>.channel_item:hover {
    color: #fff !important;
    background: #333 !important;
}

.night-mode .option_links .icon_wishlist {
    background: rgba(255,255,255,0.2);
    color: #c7c7c7;
}

.night-mode .option_links .icon_wishlist:hover {
    background: rgba(255,255,255,0.2);
    color: #fff;
}

.night-mode .noti_count1 {
    background: #f55d2c;
}

.night-mode .option_links .icon_noti {
	background: rgba(255,255,255,0.2);
    color: #c7c7c7;
}

.night-mode .option_links .icon_noti:hover {
    background: rgba(255,255,255,0.2);
    color: #fff;
}

.night-mode .noti_count {
    background: #f55d2c;
}

.night-mode .opts_account {
    background: rgba(255,255,255,0.2);
}

.night-mode .opts_account:hover {
    color: #fff !important;
}

.night-mode .ui.inline.dropdown.loc-title>.text {
    color: #fff;
}

.night-mode .menu.dropdown_loc {
    width: 170px;
    margin-top: 17px !important;
    border-top: 1px solid #242424 !important;
    border-radius: 3px !important;
    min-width: 170px !important;
    background: #171717 !important;
}


.night-mode .bg-dark1 {
    background-color: #242424 !important;
    z-index: 999;
}

.night-mode .navbar-light .main_nav .nav-link {
    color: #c7c7c7 !important;
}

.night-mode .navbar-light .main_nav .nav-link.active {
    color: #fff !important;
}

.night-mode .navbar-light .main_nav .nav-link:hover {
    color: #fff !important;
}

.night-mode .sub-header-group {
    border-top: 1px solid #333;
}

.night-mode .category_drop {
    color: #fff !important;
    border-right: 1px solid #333;
}

.night-mode .cart__btn {
    color: #fff !important;
}

.night-mode .menu.dropdown_page {
    margin-top: 14px !important;
    border-top: 1px solid #242424 !important;
    border-radius: 3px !important;
    min-width: 170px !important;
    background: #171717 !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.night-mode .menu_toggle_btn {
    background: #333 !important;
    color: #fff !important;
}

.night-mode .cate__btn {
    color: #c7c7c7 !important;
}

.night-mode .cate__btn:hover {
    color: #fff !important;
}

.night-mode .search__btn {
    color: #fff !important;
    background: #333;
}

.night-mode .hover-btn:hover {
	background: #f55d2c;
	color: #fff !important;
	background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
}

.night-mode .mt1-5 {
    border-top: 1px solid #242424;
}




 /* all theme custom cards */ 
.night-mode .nav-single-group ,
.night-mode .setting-menu ,
.night-mode .group-card ,
.night-mode .events-list ,
.night-mode .event-info-date ,
.night-mode  ,
.night-mode .nav-profile ,
.night-mode .post ,
.night-mode .post-new ,
.night-mode .profile-friend-card ,
.night-mode .contact-list-box{
    background-color: #000;
    box-shadow: 0px 2px 10px 0px #0000001a;
}

.night-mode #logo img {
      display:none;
  }
 .night-mode #logo .logo-inverse{
      display:inline !important
  }
 
/* Titles & texts */
.night-mode h1, .night-mode h2, .night-mode h3, .night-mode h4, .night-mode h5, .night-mode h6 {
    color: #fff !important;
}


/* button hover */
.night-mode .button:hover, .night-mode .button.danger:hover, .night-mode .button.grey:hover, .night-mode .button-info:hover, .night-mode .button.white:hover, .night-mode .button.success:hover, .night-mode .button.secondary:hover, .night-mode .button.primary:hover, .night-mode .button.light:hover, .night-mode .button.dark:hover, .night-mode .button.default:hover, .night-mode .Markup-botton:hover, .night-mode :not(pre)>code:hover, .night-mode .uk-label:hover, .night-mode .uk-icon-button:hover, .night-mode .uk-modal-close-full:hover, .night-mode .uk-pagination>.uk-active>*, .night-mode .slidenav-next:hover, .night-mode .slidenav-prev:hover, .night-mode #backtotop a:hover, .night-mode .comment-by a.reply, .night-mode .Markup-botton:hover, .night-mode .Markup-botton::before, .night-mode .grid-slider-header .slide-nav-prev, .night-mode .grid-slider-header .slide-nav-next ,
.night-mode ul.dropdown-user-menu li a i  ,
.night-mode header .head_user .opts_icon:hover,
.night-mode header .head_user .opts_icon:focus ,
.night-mode header .opts_icon_link:hover ,
.night-mode .post-comment-opt:hover, .night-mode .post-comment-opt:focus ,
.night-mode header .icon-browse .icon-menu-item:hover{
    background-color: #242424  !important;
    color: white !important;
}


/* reset links */
.night-mode a {
    color: #fff !important;
}

.night-mode .offer-item {
    background: #333;
}

.night-mode .Offer-shop-btn {
    color: #fff !important;
}

.night-mode .category-item {
    background: #333;
}

.night-mode .cate-slider .owl-nav .owl-prev {
    color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .cate-slider .owl-nav .owl-next {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .featured-slider .owl-nav .owl-prev {
    color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .featured-slider .owl-nav .owl-next {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .testimonial-slider .owl-nav .owl-prev {
    color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .testimonial-slider .owl-nav .owl-next {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .life-slider .owl-nav .owl-prev {
    color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode .life-slider .owl-nav .owl-next {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode #sync1 .owl-nav .owl-prev {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}

.night-mode #sync1 .owl-nav .owl-next {
	color: #fff !important;
    background: #333 !important;
    border: 1px solid #000 !important;
}


.night-mode .product-item {
    background: #333;
	box-shadow: 0 1px 2px 0 #000;
}

.night-mode .product-text-dt p {
    color: #c7c7c7;
}

.night-mode .quantity.buttons_added .minus, .night-mode .quantity.buttons_added .plus {
    background-color: #f55d2c;
}

.night-mode .footer {
    background: #333;
}

.night-mode .footer-first-row {
    border-bottom: 1px solid #242424;
}

.night-mode  .footer-second-row {
    border-bottom: 1px solid #242424;
}

.night-mode .footer-last-row {
    background: #333;
}

.night-mode .copyright-text {
    color: #c7c7c7;
}

.night-mode .newsletter-input .form-control {
    color: #f9f9f9;
    border: 1px solid #000;
	background: #000;
}

.night-mode input[type=text] {
    color: #fff !important;
}

.night-mode .side-cart-header {
    background: #000;
}

.night-mode .bs-canvas-body {
    background: #333;
	bottom: 189px;
}

.night-mode .cart-top-total {
    background: #333;
    border-bottom: 1px solid #242424;
}

.night-mode .cart-total-dil span {
    color: #c7c7c7;
}

.night-mode .cart-item {
    background: #333;
    border-bottom: 1px solid #242424;
}

.night-mode .cart-product-img img {
    border: 1px solid #000;
}

.night-mode .bs-canvas-footer {
    background: #000;
    border-top: 1px solid #242424;
}

.night-mode .main-total-cart {
    border-bottom: 1px solid #242424;
}

.night-mode .cate-header {
    background: #000;
}

.night-mode .category-model-content {
    background-color: #333 !important;
}

.night-mode .single-cat-item .text {
    color: #fff;
}

.night-mode .single-cat-item:hover {
	background: #242424;
}

.night-mode .morecate-btn {
    border-top: 1px solid #242424;
    color: #fff;
}

.night-mode .default-dt {
    background: #333;
}

.night-mode .default-title p {
    color: #c7c7c7;
}

.night-mode .about-content p {
    color: #c7c7c7;
}

.night-mode .white-bg {
    background: #333;
}

.night-mode .about-step p {
    color: #c7c7c7;
}

.night-mode .team-item {
    background: #333;
}

.night-mode .team-item span {
    color: #c7c7c7;
}

.night-mode .scl-btn {
    background: #f55d2c;
}

.night-mode .how-order-steps {
    background: #333;
}

.night-mode .gambo-Breadcrumb {
    background: #333;
    border-top: 1px solid #242424;
}

.night-mode .gambo-Breadcrumb .breadcrumb .breadcrumb-item a {
    color: #c7c7c7 !important;
}

.night-mode .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    color: #fff;
}

.night-mode .gambo-Breadcrumb .breadcrumb .breadcrumb-item a:hover {
    color: #fff !important;
}

.night-mode .blog-des-dt142 p {
    color: #c7c7c7;
}

.night-mode .like-share span {
    color: #fff;
}

.night-mode .date-icons-group {
    border-bottom: 1px solid #242424;
}

.night-mode .cmmnt_item {
    background: #333;
}

.night-mode .time_145 {
    color: #c7c7c7;
}

.night-mode .rvds10 {
    color: #c7c7c7;
}

.night-mode .all-comment {
    border-bottom: 1px solid #242424;
}

.night-mode .leave-comment span {
    color: #c7c7c7;
}

.night-mode label.control-label {
    color: #fff;
}

.night-mode .ui[class*="left icon"].swdh19.input>input {
    background: #000;
}

.night-mode input {
    color: #fff !important;
}

.night-mode .ui.input>input {
    border: 1px solid #333 !important;
}

.night-mode .ui.input>input:focus {
    border: 1px solid #f55d2c !important;
}

.night-mode .form-control {
    color: #fff !important;
    font-weight: 500 !important;
    background-color: #000 !important;
    border: 1px solid #333 !important;
}

.night-mode .form-control:focus {
    border: 1px solid #f55d2c !important;
}

.night-mode .ui.input>input {
    color: #fff !important;
}

.night-mode .quantity .input-text.qty {
    background: #000;
}

.night-mode .quantity .input-text.qty.white-bg-input {
    border: 1px solid #000;
}

.night-mode .testi-item {
    background: #333;
}

.night-mode .testo-text {
    color: #c7c7c7;
}

.night-mode .team-emp-dt p {
    color: #c7c7c7;
}

.night-mode .pdpt-bg {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .custom-control-label {
    color: #fff !important;
}

.night-mode .custom-control-label::before {
    background-color: #333;
    border: 1px solid #727272;
}

.night-mode .custom-checkbox .custom-control-label::before {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.night-mode .ui.selection.dropdown.form-dropdown:hover {
    border-color: #242424 !important;
}

.night-mode .ui.selection.dropdown.form-dropdown {
    color: #fff !important;
    border: 1px solid #333 !important;
}

.night-mode .ui.selection.dropdown {
    background: #000;
    color: #fff;
}

.night-mode .ui.selection.active.dropdown.form-dropdown {
    border-color: #f55d2c !important;
}

.night-mode .ui.selection.active.dropdown.form-dropdown .menu {
    border-color: #f55d2c !important;
}

.night-mode .ui.active.search.dropdown input.search:focus+.text {
    color: rgba(255,255,255,.87)!important;
}

.night-mode .ui.selection.visible.dropdown.form-dropdown>.text:not(.default) {
    color: #fff !important;
}

.night-mode .ui.dropdown .menu {
    background: #333;
    border: 1px solid #333;
}

.night-mode  .ui.selection.dropdown .menu>.item {
    border-top: 1px solid #242424 ;
	color: #c7c7c7 !important;
}

.night-mode  .ui.selection.dropdown .menu>.item:hover {
	color: #fff !important;
}

.night-mode .job-bg1 {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .job-item span {
    color: #c7c7c7;
}

.night-mode .checkout {
    background-color: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .wizard-btn {
    color: #fff;
}

.night-mode .checkout-step-body p {
    color: #c7c7c7;
}

.night-mode .login-phone:after {
    color: #fff;
}

.night-mode .checkout-step {
    border-top: 1px solid #242424;
    color: #fff;
}

.night-mode .ui.checkbox.chck-rdio label {
    color: #fff !important;
}

.night-mode .date-slider-group label {
    background: #242424;
}

.night-mode .radio-label_1 {
    color: #fff;
    background: #242424;
    border: 1px solid #242424;
}

.night-mode .radio-label_1:hover {
	background: #000;
    transition: all .4s ease-in-out;
}

.night-mode .pdpt-title h4 {
    border-bottom: 1px solid #242424;
}

.night-mode .total-checkout-group {
    border-top: 1px solid #242424;
}

.night-mode .cart-item:last-child {
    border-bottom: 0;
}

.night-mode .promo-link45 {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .checkout-safety-alerts p {
    border-bottom: 1px solid #242424;
    color: #fff;
}

.night-mode .checkout-safety-alerts {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .payment-secure {
    color: #fff;
}

.night-mode .panel-title a {
    background: #333;
}

.night-mode .panel-body {
    color: #fff;
    background: #333;
    border-top: 1px solid #242424;
}

.night-mode .contact-form {
    background: #333;
}

.night-mode .contact-title p {
    color: #c7c7c7;
}

.night-mode .dashboard-group {
    background: rgba(51,51,51, 0.3);
}

.night-mode .earn-points {
    color: #fff;
    background: rgba(255,255,255, 0.3);
}

.night-mode .left-side-tabs {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .user-item {
    border-bottom: 1px solid #242424;
    color: #fff;
}

.night-mode .user-item:last-child {
    border-bottom: 0;
}

.night-mode .user-item:hover { 
	color: #fff;
    background:#242424;
}

.night-mode .address-dt-all p {
    color: #c7c7c7;
}

.night-mode .address-item {
    border-bottom: 1px solid #242424;
}

.night-mode .product-now label {
    background: #242423;
    color: #fff;
}

.night-mode .user-dt p {
    color: #fff;
}

.night-mode .user-dt p a {
    color: #fff !important;
}

.night-mode .user-dt p a:hover {
    color: #f55d2c !important;
}

.night-mode .pdpt-title h6 {
    border-bottom: 1px solid #242424;
}

.night-mode .order-dt47 p {
    color: #c7c7c7;
}

.night-mode .order-title {
    color: #fff;
}

.night-mode .order-title span {
    background: #fff;
    color: #000;
}

.night-mode .order-title span:hover {
	color: #fff;
    background: #f55d2c;
}

.night-mode [data-inverted][data-position~=top][data-tooltip]:before {
    background: #fff !important;
}

.night-mode [data-tooltip][data-inverted]:after {
    background: #fff !important;
    color: #000;
}

.night-mode .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #fff;
}

.night-mode .bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    background: #f55d2c !important;
}

.night-mode  .bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    background: #f55d2c;
}

.night-mode .bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    background: #fff;
}

.night-mode .alert-offer {
    border-top: 1px solid #242424;
	color: #fff;
}

.night-mode .call-bill {
    border-top: 1px solid #242424;
}

.night-mode .delivery-man {
    color: #fff;
}

.night-mode .reward-body-all li {
	border-right: 1px solid #242424;
}

.night-mode .reward-body-all li:last-child {
	border-right: 0;
}

.night-mode .total-rewards span {
    color: #c7c7c7;
}

.night-mode .rewrd-title {
    color: #fff;
}

.night-mode .date-reward {
    background: #242424;
    border: 1px dashed #efefef;
    color: #fff;
}

.night-mode .gambo-body-cash p {
    color: #c7c7c7;
}

.night-mode .ucp-table thead {
    color: #fff !important;
}

.night-mode .ucp-table thead tr th {
    color: #fff;
    background-color: #242424;
}

.night-mode .ucp-table td, .night-mode .ucp-table th {
    border-top: 1px solid #242424 !important;
}

.night-mode .ucp-table tbody tr td {
    color: #fff;
}

.night-mode .ucp-table tbody {
    background: #333;
}

.night-mode .purchase-history-left p {
    color: #fff;
}

.night-mode .purchase-history-left span {
    color: #c7c7c7;
}

.night-mode .history-list li {
    border-bottom: 1px solid #242424;
}

.night-mode .history-list li:last-child {
    border-bottom: 0;
}

.night-mode .scrollstyle_4::-webkit-scrollbar {
    width: 4px;
    background-color: #242424;
}

.night-mode .scrollstyle_4::-webkit-scrollbar-thumb {
    background-color: #242424;
}

.night-mode .scrollstyle_4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #141414;
}

.night-mode .cart-close-btn {
    color: #fff;
}

.night-mode .cart-close-btn:hover {
    color: #f55d2c;
}

.night-mode .more-link14 {
    border-top: 1px solid #242424;
}

.night-mode .smll-history p {
    color: #fff;
}

.night-mode .panel-body p {
    color: #fff;
}

.night-mode .form-dt {
    border: 1px solid #242424;
    background: #333;
}

.night-mode .lgn_icon {
    color: #fff;
}

.night-mode .signup-link p a {
    background: #333;
}

.night-mode .signup-link p a:hover {
    color: #fff !important;
	box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
}

.night-mode .job-main-dt span {
    color: #c7c7c7;
}

.night-mode .job-des-dt142 p {
    color: #c7c7c7;
}

.night-mode .job-main-dt {
    border-bottom: 1px solid #242424;
}

.night-mode .job-req-dt {
    border-bottom: 1px solid #242424;
    border-top: 1px solid #242424;
}

.night-mode .custom-file-label {
    color: #afafaf !important;
    background-color: #000 !important;
    border: 1px solid #242424 !important;
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.night-mode .ques-142 {
    border-bottom: 1px solid #242424;
}

.night-mode .ui.checkbox label {
    color: #fff !important;
}

.night-mode .offers-item {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .offers-text p {
    color: #c7c7c7;
}

.night-mode .order-placed-dt p {
    color: #c7c7c7;
}

.night-mode .delivery-address-bg {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .address-placed-dt1 p {
    color: #fff;
}

.night-mode .address-placed-dt1 p span {
    color: #c7c7c7;
}

.night-mode .st-hm {
    color: #fff;
}

.night-mode .placed-bottom-dt {
    color: #c7c7c7;
}

.night-mode .placed-bottom-dt span {
    font-weight: 600;
    color: #fff;
}

.night-mode .title585 {
    border-bottom: 1px solid #242424;
}

.night-mode .stay-invoice {
    border-top: 1px solid #242424;
    border-bottom: 1px solid #242424;
}

.night-mode .top-posts li {
    border-bottom: 1px solid #242424;
}

.night-mode .blog-time {
    color: #c7c7c7;
}

.night-mode .blog-item {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .blog-detail p {
    color: #c7c7c7;
}

.night-mode .fcrse_3 {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .blogleft12 li {
	border-bottom: 1px solid #242424;
}

.night-mode .blogleft12 li:last-child {
    border-bottom: 0;
}

.night-mode .press-title p {
    color: #c7c7c7;
}

.night-mode .press-item {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .press-item span {
    color: #c7c7c7;
}

.night-mode .press-item p {
    color: #fff;
}

.night-mode .policy-des-dt {
    border-bottom: 1px solid #242424;
}

.night-mode .policy-des-dt:last-child {
    border-bottom: 0;
}

.night-mode .bootstrap-tagsinput {
    border: 1px solid #333 !important;
	background-color: #333 !important;
}

.night-mode .filter-btn {
    color: #fff;
    background: #000;
}

.night-mode .filter-body {
    background: #333 !important;
}

.night-mode .cte-select label {
    background: #242424;
    color: #fff;
}

.night-mode .cte-select label:hover {
	background: #f55d2c;
	color: #fff;
}

.night-mode .webproduct {
    color: #c7c7c7;
}

.night-mode .product-dt-view {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .no-stock p {
    color: #fff;
}

.night-mode .no-stock p span {
    color: #c7c7c7;
}

.night-mode .pp-descp {
    color: #c7c7c7;
}

.night-mode .product-group-dt {
    background: #242424;
}

.night-mode .main-price {
    color: #fff;
}

.night-mode .main-price span {
    color: #c7c7c7;
}

.night-mode .pdp-details {
    background: #242424;
}

.night-mode .pdp-text-dt span {
    color: #fff;
}

.night-mode .pdp-text-dt p {
    color: #c7c7c7;
}

.night-mode .pdp-details ul li {
    border-right: 1px solid #333;
}

.night-mode .pdp-details ul li:last-child {
    border-right: 0;
}

.night-mode .color-discount:after {
    content: '-';
    color: #fff;
}

.night-mode .pdct-dt-step p {
    color: #c7c7c7;
}

.night-mode .product_attr {
    color: #c7c7c7;
}

.night-mode .bill-detail {
    background: #333;
    box-shadow: 0 1px 2px 0 #242424;
}

.night-mode .itm-ttl {
    color: #fff;
}

.night-mode .bill-dt-step {
    border-bottom: 1px solid #242424;
}

.night-mode .item-prdct {
    color: #c7c7c7;
}

.night-mode .bill-address {
    color: #c7c7c7;
}

.night-mode .bill-dt-sl {
    color: #fff;
}

.night-mode .dly-loc {
    color: #fff;
}

.night-mode .descp-bll-dt {
    color: #c7c7c7;
}

.night-mode .thnk-ordr {
    color: #fff;
}