// header search

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
  z-index: 2;

  font-size: 13px;
  font-weight: 500;

  //

  outline: 0;
  min-width: max-content;
  margin: 0;
  padding: 0 0;
  background: #fff;

  text-shadow: none;
  text-align: left;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  transition: opacity 0.1s ease;
  z-index: 11;

  margin-top: 10px !important;
  border-top: 1px solid #fff !important;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

// override cart button padding

// .header_cart {
//   .cart__btn {
//     padding: 29px 28px 5px !important;
//   }
// }

@media (min-width: 992px) {
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
}

.zeynep-opened .zeynep-overlay {
  display: block !important;
}

.offers-slider {
  // .slick-prev {
  //   left: -22px;
  //   font-size: 30px !important;
  //   position: absolute;
  //   top: 50%;
  //   text-align: center;
  //   height: 42px !important;
  //   width: 42px !important;
  //   padding: 4px 0 !important;
  //   color: #2b2f4c !important;
  //   background: #fff !important;
  //   border-radius: 100% !important;
  //   border: 1px solid #e5e5e5 !important;
  //   box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.7);

  //   &:hover {
  //     color: #fff !important;
  //     opacity: 1;
  //     background: #0157a7 !important;
  //     border: 1px solid #0157a7 !important;
  //     box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.7);
  //   }
  // }

  // .item {
  //   margin: 0 7px;
  // }
}

.container-fluid {
  padding-right: 30px;
  padding-left: 30px;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 1280px 250px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

box {
  height: 104px;
  width: 100px;
}

lines {
  height: 25px;
  margin-top: 10px;
  width: 300px;
}

photo {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}
banner {
  display: block !important;
  width: 100%;
  height: 250px;
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.ui.dropdown.active.visible {
  &::after {
    content: " ";
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 145px;
    left: 24px;
    height: 10px;
  }
}

// search

.search-sm {
  .ui[class*="left icon"].swdh10.input > input {
    border-radius: 0 !important;
  }
}

@media (max-width: 575.98px) {
  .mt1-5 {
    margin-top: 170px !important;
  }

  .react-autosuggest__suggestions-container--open {
    margin-top: 0px !important;
  }
}

.no-rating {
  font-size: 0.7rem;
  color: #e2e2e2;
}

.slick-list {
  margin: 0 -7px;
  .item {
    padding: 0 7px;
  }
}

.product-sort {
  .ui.selection.dropdown {
    padding: 0 0.8rem;
  }
}

.slick-prev {
  left: -10px !important;
  z-index: 1;
}
.slick-next {
  right: 5px !important;
  z-index: 1;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}
.next-slick-arrow,
.prev-slick-arrow {
  font-size: 22px !important;
  height: 30px !important;
  width: 30px !important;

  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);
  text-align: center;

  &:hover {
    color: #fff !important;
    opacity: 1;
    background: #0157a7 !important;
    border: 1px solid #0157a7 !important;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);
  }
}

.bg-carousel {
  .slick-prev {
    left: -26px !important;
    z-index: 1;
  }
  .slick-next {
    right: -13px !important;
    z-index: 1;
  }
}

.form-control {
  &:focus {
    border: 1px solid #5eb8f5 !important;
  }
}
.form-control.is-invalid {
  border: 1px solid #dc3545 !important;
}
.featured-slider {
  .slick-list > .slick-track {
    margin-left: 0;
    margin: 0 auto;
  }
}
.offers-slider {
  .slick-list > .slick-track {
    margin-left: 0;
  }
}

.checkout-atr-list {
  display: flex;
  li {
    border: 1px solid #ccc;
    line-height: 2rem;
    margin: 0 5px;
    clear: both;
    font-size: 12px;
    font-weight: 400;

    width: 100%;
    &:first-child {
      margin-left: 0px;
    }
  }
}

.map-responsive {
  padding-bottom: 0px;

  .uil-map-marker-alt {
    color: #dc3545;
    font-size: 2rem;
    cursor: pointer;
  }
}

.header_cart {
  vertical-align: unset;
}

span.like-active {
  background-color: #064b73 !important;
  color: #fff !important;
}
.like-icon {
  &:hover {
    background-color: #0082d5 !important;
    color: #fff !important;
  }
}
.like-active {
  &:hover {
    background-color: #064b73 !important;
    color: #fff !important;
  }
}
.quantity {
  input {
    &:disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

div#myPortal {
  z-index: 9;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media (min-width: 641px) {
  div#myPortal {
    position: absolute;
    z-index: 9;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }
}

.fb-login-btn {
  background-color: #4c69ba;
  .uil {
    font-size: 20px;
  }
}
.google-login-btn {
  background-color: #d63731;
  .uil {
    font-size: 20px;
  }
}

.address-verification-modal {
  .verify-btn {
    font-size: 13px;
    font-weight: 500;
    padding: 0 15px;
    height: 40px;
    border: 0;
    color: #fff;
    background: #0157a7;
    border-radius: 5px;
    text-align: center;
  }
  .cancel-btn {
    font-size: 13px;
    font-weight: 500;
    padding: 0 15px;
    height: 40px;
    border: 0;
    color: #fff;
    background: #ccc;
    border-radius: 5px;
    text-align: center;
  }
}
ul.cte-select li a {
  color: #2b2f4c;
}