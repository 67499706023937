.checkout-wrapper {
	padding-top: 40px; 
	padding-bottom:40px; 
	background-color: #fafbfa;
}

.checkout {    
	background-color: #fff;
    box-shadow: 0 1px 2px 0 #e9e9e9;
    font-size: 14px;
    border-radius: 5px;
}

.checkout-card {  }

.checkout-step {
    border-top: 1px solid #cccccc;
    color: #2b2f4c;
    font-size: 14px;
    padding: 30px;
    position: relative;
     background: #f6f7f8;
}

.checkout-step:first-child {
    border-top: 0;
}

.checkout-step-number {
    border-radius: 5px;
    border: 1px solid #0157a7;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    height: 32px;
    margin-right: 20px;
    padding: 6px;
    text-align: center;
    width: 32px;
    color: #fff;
    background: #0157a7;
}

.checkout-step-title{ 
	font-size: 18px;
    font-weight: 500;
    vertical-align: middle;
	display: inline-block; 
	margin: 0px;
}

.wizard-btn {
    background: transparent;
    border: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #2b2f4c;
    text-transform:uppercase;
}
 
.checout-address-step { }

.checout-address-step .form-group{
	margin-bottom: 18px;
	display: inline-block;
    width: 100%;
}

.checkout-step-body{ 
	padding-left: 0; 
	padding-top: 30px;
}

.checkout-step-active{
	display: block;
}

.checkout-step-disabled{ 
	display: none;
}

.checkout-login{position: relative;}

.login-phone{
	display: inline-block; width:100%;
}

.login-phone:after {
    content: '+971  ';
    font-size: 14px;
    left: 35px;
	top: 20px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    color: #2b2f4c;
}

.login-phone:before {
    content: "";
    font-style: normal;
    color: #333;
    font-size: 18px;
    left: 12px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.login-phone:after, .login-phone:before {
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.login-phone .form-control {
    padding-left: 68px;
    font-size: 14px;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    color: #2b2f4c;
	border: 1px solid #efefef;
}

.checkout-login .btn {
	height: 42px;     
	line-height: 1.8;
}
 
.otp-verifaction {
	margin-top: 20px;
}

.checkout-sidebar {
	background-color: #fff;
    border:1px solid #eaefe9; 
	padding: 30px;
	margin-bottom: 30px;
}

.checkout-sidebar-merchant-box {
	background-color: #fff;
    border:1px solid #eaefe9; 
	margin-bottom: 30px;
}

.checkout-total {
	border-bottom: 1px solid #eaefe9; 
	padding-bottom: 10px;
	margin-bottom: 10px; 
}

.checkout-invoice {
	display: inline-block;
    width: 100%;
}

.checout-invoice-title {    
	float: left; 
	color: #30322f;
}

.checout-invoice-price {  
	float: right;
	color: #30322f;
}

.checkout-charges {
	display: inline-block;
    width: 100%;
}

.checout-charges-title{
	float: left; 
}

.checout-charges-price {
	float: right;
}

.charges-free {
	color: #43b02a; 
	font-weight: 600;
}

.checkout-payable {
	display: inline-block;
    width: 100%; 
	color: #333;
}

.checkout-payable-title {
	float: left; 
}

.checkout-payable-price {
	float: right;
}

.checkout-cart-merchant-box { 
	padding: 20px;
	display: inline-block;
	width: 100%; 
	border-bottom: 1px solid #eaefe9;
	padding-bottom: 20px; 
}

.checkout-cart-merchant-name {
	color: #30322f;
	float: left;
}

.checkout-cart-merchant-item { 
	float: right; 
	color: #30322f;
}

.checkout-cart-products {}

.checkout-cart-products .checkout-charges { 
	padding: 10px 20px;
    color: #333;
}

.checkout-cart-item { 
	border-bottom: 1px solid #eaefe9;
    box-sizing: border-box;
    display: table;
    font-size: 12px;
    padding: 22px 20px;
    width: 100%;
}
.checkout-item-list {}

.checkout-item-count { 
	float: left; 
}

.checkout-item-img {
	width: 60px; 
	float: left;
}

.checkout-item-name-box { 
	float: left;
}

.checkout-item-title { 
	color: #30322f; 
	font-size: 14px;
}

.checkout-item-unit {  }

.checkout-item-price {
	float: right;
	color: #30322f; 
	font-size: 14px;
	font-weight: 600;
}


.checkout-viewmore-btn {
	padding: 10px; 
	text-align: center;
}

.header-checkout-item {
	text-align: right; 
	padding-top: 20px;
}

.checkout-promise-item {
    background-repeat: no-repeat;
    background-size: 14px;
    display: inline-block;
    margin-left: 20px;
    padding-left: 24px;
    color: #30322f;
}

.checkout-promise-item i{ 
	padding-right: 10px;
	color: #43b02a;
}