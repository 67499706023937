@charset "utf-8";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  /*vertical-align: baseline;*/
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
::-moz-selection {
  /* Code for Firefox */
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}

ol,
ul {
  list-style: none;
  margin-bottom: 0 !important;
}
a:hover {
  color: inherit !important;
  text-decoration: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
button {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  margin: 0;
  padding: 0;
}
a:focus {
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: none !important;
}
button {
  outline: none;
}

button:focus {
  outline: none !important;
}

input {
  outline: none;
}
textarea {
  outline: none;
}

p {
  font-size: 13px;
  line-height: 26px;
  color: #333;
}

a {
  cursor: pointer !important;
  color: #0157a7;
}

/* ================ Typography Css =============== */
h1 {
  font-size: 2em;
  font-family: "Lato", sans-serif !important;
  /* 2x body copy size = 32px */
  line-height: 1.25;
  /* 45px / 36px */
}
h2 {
  font-size: 1.625em;
  font-family: "Lato", sans-serif !important;
  /* 1.625x body copy size = 26px */
  line-height: 1.15384615;
  /* 30px / 26px */
}
h3 {
  font-size: 1.375em;
  font-family: "Lato", sans-serif !important;
  /* 1.375x body copy size = 22px */
  line-height: 1.13636364;
  /* 25px / 22px */
}
h4 {
  font-size: 1.125em;
  font-family: "Lato", sans-serif !important;
  /* 1.125x body copy size = 18px */
  line-height: 1.11111111;
}
h5 {
  font-size: 1.1em;
  font-family: "Lato", sans-serif !important;
  /* 1.125x body copy size = 18px */
  line-height: 1.1;
}
h6 {
  font-size: 1em;
  font-family: "Lato", sans-serif !important;
  /* 1.125x body copy size = 18px */
  line-height: 1.05;
}
blockquote {
  font-size: 1.25em;
  font-family: "Lato", sans-serif !important;
  /* 20px / 16px */
  line-height: 1.25;
  /* 25px / 20px */
}
@media (min-width: 43.75em) {
  h1 {
    font-size: 2.5em;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
  h2 {
    font-size: 2em;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
  h3 {
    font-size: 1.5em;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
  h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
  blockquote {
    font-size: 1.5em;
    /* 24px / 16px = */
    line-height: 1.45833333;
    /* 35px / 24px */
  }
}
@media (min-width: 56.25em) {
  h1 {
    font-size: 3em;
    /* 3x body copy size = 48px */
    line-height: 1.05;
    /* keep to a multiple of the 20px line height 
	and something more appropriate for display headings */
  }
  h2 {
    font-size: 2.25em;
    /* 2.25x body copy size = 36px */
    line-height: 1.25;
  }
  h3 {
    font-size: 1.75em;
    /* 1.75x body copy size = 28px */
    line-height: 1.25;
  }
}

/* ================ CROSS BROWSER COMPATABILITY =============== */

.ask-question,
nav,
.notification-box.active,
nav ul li a,
.user-account-settingss.active,
.user-info a,
.suggestion-usd > span i,
.view-more > a,
.tags-sec ul li a,
.post-st ul li a,
.ed-opts > a,
.bk-links li a,
.like-com li a,
.user-profy > a,
.slick-previous:before,
.slick-nexti:before,
.comment > a,
.sign_link > a,
.post-popup,
.post-project > a,
.post-project-fields form ul li button,
.post-project-fields form ul li a,
.sign-control li a,
.cover-sec > a,
.star-descp > a,
.message-btn a,
.pf-gallery ul li,
.gallery_pt:before,
.gallery_pt > a,
.overview-box,
.skl-name,
.conv-list,
.save-stngs ul li button,
.forum-links.active,
.react-links li a,
.next-prev > a,
nav ul li:hover > ul,
.user-pro-img > a i {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

/* === Body Scrollbar === */

body::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
body::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
body::-webkit-scrollbar-corner {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.05),
    inset 1px 1px 0 rgba(0, 0, 0, 0.05);
}

html {
  font-size: 13px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif !important;
  font-size: 0.575rem;
  line-height: 1.42857143;
  color: #2b2f4c;
  background: #fff !important;
  letter-spacing: 0.05rem;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  height: 50px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

input[type="text"] {
  color: #2b2f4c !important;
}

/* --- Night Mode Btn --- */

.night_mode_switch__btn {
  border-bottom: 1px solid #efefef;
}

.night_mode_switch__btn a {
  /*display: block;*/
  font-size: 13px;
  transition: 0.3s;
  font-weight: 500;
  border-radius: 10px;
  color: #333;
  padding: 15px;
  position: relative;
  display: flex;
  align-items: center;
  width: 220px;
}

.night_mode_switch__btn a i {
  font-size: 120%;
  height: 30px;
  width: 30px;
  background: #ffecec;
  border-radius: 100%;
  margin-right: 10px;
  padding: 6px 4px 0;
  display: inline-block;
}

.btn-night-mode .btn-night-mode-switch {
  display: inline-block;
  height: 18px;
  width: 37px;
  top: 35%;
  right: 13px;
  position: absolute;
}

/*--- search cate scroll ---*/

.search-by-cat::-webkit-scrollbar {
  width: 4px;
}

.search-by-cat::-webkit-scrollbar-thumb {
  height: 50px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.bs-canvas-body::-webkit-scrollbar {
  width: 4px;
}

.bs-canvas-body::-webkit-scrollbar-thumb {
  height: 50px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.scrollstyle_4::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1;
}

.scrollstyle_4::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
}

.scrollstyle_4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #f5f5f5;
}

/*--- Header ---*/

.header {
  /*position: fixed;*/
  z-index: 999;
  width: 100%;
  float: left;
  background: #fff;
  box-shadow: 0px 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.01);
}

.top-header-group {
  float: left;
  width: 100%;
  display: block;
  background: #eaeded;
}

.top-header {
  float: left;
  width: 100%;
  display: flex;
  height: auto;
  align-items: center;
  /* padding: 5px 0px;*/
  /* border-top: 3px solid #0157a7;*/
}

.main_logo {
  float: left;
  width: 390px;
  margin-left: 10px;
  padding: 15px 0px;
}

.blog_logo {
  padding: 12px 0px;
}

.logo-inverse {
  display: none;
}

.search120 {
  float: left;
  margin-left: 50px;
  width: 100%;
}

.main_logo img {
  width: 100%;
  padding-top: 0;
}

.res_main_logo {
  display: none;
  width: 100px;
}

.res_main_logo img {
  width: 100%;
  padding-top: 3px;
}

.srch10 {
  background: #eaeded !important;
  border: 0 !important;
  color: #2b2f4c !important;
}

i.icon1 {
  font-size: 16px !important;
}

.ui.swdh10 {
  font-size: 13px !important;
  width: 100%;
}

.ui[class*="left icon"].swdh10.input > input {
  padding-left: 3.2em !important;
  padding-right: 1em !important;
  font-size: 13px;
  font-weight: 500;
  border: 0 !important;
  border-radius: 30px !important;
}

.ui.search .prompt {
  border-radius: 0.3rem !important;
  width: 100%;
}

.header_right {
  margin-left: auto;
  float: right;
  padding-right: 15px;
}

.header_right ul li {
  display: inline-block;
  vertical-align: middle;
}

.option_links {
  padding: 10px 9px;
  font-size: 20px;
  color: #91699c;
  position: relative;
}

.option_links i {
  font-size: 18px;
}

.option_links .icon_wishlist {
  background: #eaeded;
  color: rgba(1, 87, 167, 1);
  height: 35px;
  width: 35px;
  display: inline-block;
  padding: 10px 5px;
  border-radius: 100%;
}

.option_links .icon_noti {
  background: rgba(236, 25, 89, 1);
  color: #fff;
  height: 35px;
  width: 35px;
  display: inline-block;
  padding: 10px 5px;
  border-radius: 100%;
}

.option_links:hover,
.option_links:focus {
  color: #2b2f4c !important;
}

.noti_count {
  font-size: 11px;
  position: absolute;
  top: 0px;
  right: 8px;
  background: #0157a7;
  color: #fff;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 1.5;
  border-radius: 100%;
  font-weight: 500;
}

.noti_count1 {
  font-size: 11px;
  font-weight: 600;
  position: absolute;
  top: 0px;
  right: 8px;
  background: #c00;
  color: #fff;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 1.5;
  border-radius: 100%;
}
.noti_count2 {
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 5px;
  right: 20px;
  background: #c00;
  color: #fff;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 2;
  border-radius: 100%;
  border: 1px solid #fff;
}
.opts_account {
  font-weight: 500;
  color: #2b2f4c;
  float: right;
  padding: 10px;
  background: #ffffff;
  border-radius: 30px;
  margin-left: 17px;
  border-bottom-right-radius: 0px;
}

.opts_account img {
  width: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  margin-left: 0;
}

.opts_account .user__name {
  margin-left: 0px;
  font-size: 0.9rem;
}

.opts_account:hover {
  color: #0157a7 !important;
}

.icon__1 {
  font-size: 18px;
  margin-right: 10px;
}

.left_title {
  padding: 20px 17px 10px;
  font-size: 12px;
  font-weight: 500;
  color: #afafaf;
  text-align: left;
  margin-bottom: 0;
}

.user_img img {
  width: 30px;
  border-radius: 100%;
  margin-left: 17px;
  margin-right: 15px;
}

.menu.dropdown_account {
  margin-top: 10px !important;
  border-top: 1px solid #fff !important;
  border-radius: 3px !important;
}

.menu.dropdown_account_rl {
  min-width: 220px !important;
}

.rl-account-step p {
  font-size: 13px;
  font-weight: 400;
}

.rl-account {
  text-align: center;
  padding: 15px;
}

.rl-account-step {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #efefef;
}

.rl-account-step:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.lr-acount-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 8px 10px;
  font-size: 13px;
  line-height: 16px;
  border-radius: 4px;
  color: #fff;
  background-color: #0157a7;
}

.menu.dropdown_mn {
  width: 300px;
  margin-top: 12px !important;
  border-top: 1px solid #fff !important;
  border-radius: 3px !important;
  min-width: 300px !important;
}

.menu.dropdown_ms {
  width: 300px;
  margin-top: 12px !important;
  border-top: 1px solid #fff !important;
  border-radius: 3px !important;
  min-width: 300px !important;
}

.channel_my {
  padding: 15px !important;
  border-bottom: 1px solid #efefef !important;
  min-width: 100%;
  max-width: 100%;
  white-space: normal;
}

.channel_my:last-child {
  border-bottom: 0 !important;
}

.profile_link {
  display: flex;
}

.profile_link img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.pd_content {
  width: 80%;
  white-space: normal;
}

.pd_content h6 {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #2b2f4c;
  text-align: left;
  position: relative;
}

.pd_content span {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #686f7a;
  text-align: left;
}

.dp_link_12 {
  font-size: 12px;
  font-weight: 400;
  color: #2b2f4c;
  margin-top: 15px;
  display: block;
  text-align: left;
}

.dp_link_12:hover {
  color: #ed2a26 !important;
}

.ui.dropdown .menu > .channel_item {
  /* color: #ffffff !important; */
  padding: 0.78571429rem 1.14285714rem !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.ui.dropdown .menu > .channel_item:hover {
  color: #233d53 !important;
  background: #eaeded !important;
}

.rhte85 {
  display: flex;
}

.mef78 i {
  font-size: 18px;
  line-height: 15px;
  color: #1da1f2;
}

.pd_content p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #91699c;
  text-align: left;
  margin-top: 8px;
  line-height: 20px;
}

.pd_content span.nm_time {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #afafaf;
  text-align: left;
}

.vbm_btn {
  width: 100%;
  text-align: center;
  float: left;
  padding: 15px 0;
  background: #0157a7;
  font-size: 13px;
  color: #fff !important;
  font-weight: 500;
}

.ui.input > input {
  font-family: "Lato", sans-serif !important;
}

.select_location {
  margin-left: 34px;
  float: left;
}

.ui.inline.dropdown.loc-title > .text {
  font-weight: 500 !important;
  color: #2b2f4c;
  font-size: 13px;
}

.menu.dropdown_loc {
  width: 170px;
  margin-top: 20px !important;
  border-top: 1px solid #fff !important;
  border-radius: 3px !important;
  min-width: 170px !important;
}

.offer-link {
  font-size: 13px;
  font-weight: 400;
  margin-right: 18px;
  color: #2b2f4c;
  margin-left: 6px;
}

.offer-link:hover {
  color: #0157a7 !important;
}

.offer-link i {
  font-size: 16px;
  margin-right: 3px;
}

.sub-header-group {
  float: left;
  width: 100%;
  display: block;
  border-top: 1px solid #efefef;
}

.sub-header {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;

  -moz-box-shadow: inset 0px 0px 2px 1px #ddd;
  -webkit-box-shadow: inset 0px 0px 2px 1px #ddd;
  box-shadow: inset 0px 0px 2px 1px #ddd;
}

.navbar-light .main_nav .nav-link {
  color: #000000 !important;
}

.main_nav .nav-link {
  display: block;
  padding: 10px 15px;
  font-size: 0.85rem;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
}

.navbar-light .main_nav .nav-link.active {
  color: #0157a7 !important;
}

.navbar-light .main_nav .nav-link:hover {
  color: #0157a7 !important;
}

.header_cart {
  margin-left: auto;
}

.cart__btn {
  color: #fff !important;
  /*font-size: 13px;*/
  font-weight: 500;
  /* background: #eaeded;*/
  padding: 18px 30px;
  text-align: center;
  position: relative;
}

.cart__btn img {
  /*width: 42px;*/
}

.cart__btn span {
  margin: 0 2px;
}

.hover-btn:hover {
  color: #0157a7 !important;
    background: #fff;
    border: 1px solid #0157a7;
    transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
  /*background: #233d53;*/
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  /*background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));*/
}

.cart__btn ins {
  color: #fff !important;
  margin-left: 5px;
  text-decoration: none !important;
}

.category_drop {
  padding: 17px 30px;
  font-size: 0.85rem;
  color: #0157a7;
  position: relative;
  font-weight: 700;
  border-right: 1px solid #efefef;
  text-transform: uppercase;
}

.ui.dropdown:hover {
  color: #0157a7;
}

.category_drop i {
  margin-right: 5px;
  font-size: 18px;
}

.menu.dropdown_page {
  width: 220px;
  margin-top: 14px !important;
  border-top: 1px solid #fff !important;
  border-radius: 3px !important;
  min-width: 170px !important;
  padding-bottom: 5px !important;
}

.menu_toggle_btn {
  padding: 5px !important;
  line-height: 1;
  border: 0 !important;
  border-radius: 50% !important;
  font-size: 20px !important;
  background: #0157a7 !important;
  color: #fff !important;
  width: 40px;
  height: 40px;
}

.mt1-5 {
  /*margin-top: 124px !important;*/
  text-align: center;
}

.bmt1-5 {
  margin-top: 60px !important;
  text-align: center;
}

.bg-dark1 {
  background-color: transparent !important;
  z-index: 999;
}

/* --- Category Model --- */
.main-gambo-model {
  background-image: -webkit-linear-gradient(
    left,
    rgba(226, 25, 89, 0.9),
    rgba(226, 25, 89, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(239, 34, 108, 0.9),
    rgba(239, 34, 108, 0.9)
  );
}

.category-area-inner .modal-header {
  border-bottom: 0;
}

.category-area-inner .btn-close {
  color: #fff !important;
  opacity: 1 !important;
  padding: 30px 0 15px !important;
  font-size: 30px !important;
  cursor: pointer !important;
}

.category-model-content {
  background: #fff;
  border: 0 !important;
  border-radius: 0 !important;
}

.catey__icon {
  display: none;
}

.search__icon {
  display: none;
}

.sub-header-icons-list {
  display: inline-block;
  font-size: 20px;
}

.cate__btn {
  font-size: 20px;
  color: #0157a7 !important;
  padding: 20px 20px 19px;
}

.cate__btn:hover {
  color: #0157a7 !important;
}

.search__btn {
  font-size: 20px;
  color: #fff !important;
  padding: 10px 10px 10px;
  /*background: #2b2f4c;*/
}

/* --- Category Mode --- */

.cate-header {
  background: #2b2f4c;
  color: #fff;
  padding: 15px 20px;
}

.cate-header h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.category-by-cat {
  width: 100%;
  display: inline-table;
}

.category-by-cat li {
  width: 33.333%;
  vertical-align: middle;
  display: inline-block;
  list-style: none;
  float: left;
}

.single-cat-item {
  text-align: center;
  padding: 20px 10px;
  display: block;
}

.single-cat-item:hover {
  background: #f9f9f9;
}

.single-cat-item .text {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
}

.single-cat-item .icon {
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}

.single-cat-item .icon img {
  width: 50px;
}

.morecate-btn {
  display: block;
  text-align: center;
  border-top: 1px solid #efefef;
  padding: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c;
}

.morecate-btn i {
  margin-right: 5px;
}

.morecate-btn:hover {
  color: #0157a7 !important;
}

.search-ground-area {
  max-width: 400px !important;
}

.search-header {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.search-header input {
  width: 100%;
  border: 0;
  padding: 20px;
  position: relative;
}

.search-header button {
  position: absolute;
  right: 0px;
  background: transparent;
  border: 0;
  padding: 17px;
  font-size: 20px;
}

.search-by-cat {
  width: 100%;
  height: 321px;
  overflow: hidden scroll;
}

.search-by-cat .single-cat {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  padding: 15px 20px;
}

.search-by-cat .single-cat .icon {
  background-color: #f9f9f9;
  border-radius: 5%;
  color: #fff;
  font-size: 22px;
  height: 50px;
  line-height: 47px;
  text-align: center;
  width: 50px;
}

.search-by-cat .single-cat .icon img {
  width: 30px;
}

.search-by-cat .single-cat .text {
  color: #2b2f4c;
  font-weight: 400;
  padding-left: 20px;
  font-size: 16px;
}

.search-by-cat .single-cat:hover .text {
  color: #0157a7;
}

/* --- Cart Offset Overlay --- */

.bg-cart {
  background: #f7f7f7;
}

.bs-canvas-overlay {
  opacity: 0.85;
  z-index: 99;
}

.bs-canvas {
  top: 0;
  z-index: 1110;
  overflow-x: hidden;
  overflow-y: auto;
  width: 400px;
  transition: margin 0.4s ease-out;
  -webkit-transition: margin 0.4s ease-out;
  -moz-transition: margin 0.4s ease-out;
  -ms-transition: margin 0.4s ease-out;
}

.bs-canvas-left {
  right: 0;
  margin-right: -400px;
}

.bs-canvas-close {
  color: #fff !important;
  opacity: 1 !important;
}

.side-cart-header {
  background: #2b2f4c;
  color: #fff;
  position: sticky;
  top: 0;
}

.main-cart-title {
  font-size: 18px;
  font-weight: 300;
}

.main-cart-title span {
  color: #fff;
  margin-left: 5px;
}

.bs-canvas-body {
  background: #f9f9f9;
  position: absolute;
  width: 100%;
  top: 48px;
  bottom: 250px;
  overflow-y: scroll;
}

.bs-canvas-footer {
  background: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #efefef;
}

.cart-top-total {
  background: #fff;
  border-bottom: 1px solid #efefef;
  padding: 20px;
}

.cart-total-dil {
  display: flex;
  width: 100%;
  align-items: center;
}

.cart-total-dil h4 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  color: #2b2f4c;
  line-height: 24px;
}

.cart-total-dil span {
  color: #8f91ac;
  font-size: 13px;
  margin-left: auto;
  font-weight: 400;
  line-height: 24px;
}

.main-total-cart {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.saving-total {
  padding: 20px 20px 0;

  margin-top: 0px;
}

.main-total-cart h2 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2b2f4c;
  line-height: 24px;
  text-transform: uppercase;
}

.main-total-cart span {
  color: #0157a7;
  font-size: 18px;
  margin-left: auto;
  font-weight: 700;
  line-height: 24px;
}

.checkout-cart {
  display: flex;
  padding: 20px;
  align-items: center;
}

.promo-code {
  font-size: 13px;
  font-weight: 600;
  color: #2b2f4c;
  line-height: 24px;
}

.promo-code:hover {
  color: #0157a7 !important;
}

.cart-checkout-btn {
  display: inline-block;
  margin-bottom: 0;
  margin-left: auto;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 13px;
  color: #fff;
  background: #0157a7;
  font-weight: 500;
  text-transform: uppercase;
}

.cart-item {
  display: flex;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #efefef;
}

.cart-item:last-child {
  border-bottom: 0;
}

.side-cart-items {
}

.cart-product-img {
  width: 100px;
  position: relative;
}

.cart-product-img img {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #f7f7f7;
}

.offer-badge {
  position: absolute;
  top: 5px;
  left: 0px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  background: #0157a7;
  padding: 1px 5px 0px;
  border-radius: 15px;
  line-height: 20px;
  font-weight: 500;
}

.cart-text {
  margin-left: 20px;
  width: 100%;
  position: relative;
}

.cart-text h4 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #2b2f4c;
  text-align: left;
  margin-bottom: 10px;
}

.cart-close-btn {
  position: absolute;
  top: 2px;
  right: 0;
  background: transparent;
  border: 0;
  font-size: 18px;
  color: #2b2f4c;
}

.cart-close-btn:hover {
  color: #0157a7;
}

/* --- Cart Radio Btn --- */

.kggrm-now {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.kggrm-now li {
  margin-right: 5px;
  width: 40px;
  height: auto;
  position: relative;
  text-align: center;
  display: inline-block;
}

.kggrm-now label,
.kggrm-now input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.kggrm-now input[type="radio"] {
  display: none;
}

.kggrm-now input[type="radio"]:checked + label,
.Checked + label {
  background: #0157a7;
}

.kggrm-now label {
  padding: 3px;
  cursor: pointer;
  background: #eaeded;
  color: #000;
  border-radius: 3px;
  font-weight: 400;
  font-size: 10px;
}

.kggrm-now label:hover {
  background: #0157a7;
}

.qty-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* -- quantity box -- */

.quantity {
  display: inline-block;
}

.quantity .input-text.qty {
  width: 30px;
  height: 30px;
  padding: 4px 2px;
  text-align: center;
  background-color: transparent;
  border: 0;
  margin-left: 0px;
  margin-right: 0px;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
}

.quantity .input-text.qty.white-bg-input {
  border: 1px solid #efefef;
}

.quantity.buttons_added {
  margin-left: auto;
  position: relative;
  white-space: nowrap;
  vertical-align: top;
}
.num-blk {
  display: none;
}

.quantity.buttons_added input {
  display: inline-block;
  margin: 0;
  vertical-align: top;
  box-shadow: none;
}

.quantity.buttons_added .minus,
.quantity.buttons_added .plus {
  padding: 5px 5px 5px;
  height: 30px;
  background-color: #0157a7;
  border: 0;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  width: 30px;
  font-size: 16px;
}

.quantity.buttons_added .minus-btn {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.quantity.buttons_added .plus-btn {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.quantity.buttons_added .minus {
  border-right: 0;
}

.quantity.buttons_added .plus {
  border-left: 0;
}

.quantity.buttons_added .minus:hover,
.quantity.buttons_added .plus:hover {
  background: #000000;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  background-image: -webkit-linear-gradient(
    left,
    rgba(226, 25, 89, 0.9),
    rgba(226, 40, 71, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(226, 25, 89, 0.9),
    rgba(226, 40, 71, 0.9)
  );
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
  outline: none;
}

.cart-item-price {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #0157a7;
  text-align: right;
  display: inline-flex;
  margin-left: auto;
}

.cart-item-price span {
  color: #c7c7c7;
  text-decoration: line-through;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 400;
  font-size: 12px;
}

.wishlist-item {
  display: flex;
}

.wishlist-product-img {
  width: 70px;
  position: relative;
}

.wishlist-product-img img {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #efefef;
}

/* --- Filters Overlay --- */

.bs-canvas-right {
  left: 0;
  margin-left: -400px;
}

.bs-canvas-close {
  color: #fff !important;
  opacity: 1 !important;
}

.side-cart-header {
  background: #0054a6;
  color: #fff;
  position: sticky;
  top: 0;
}

.main-cart-title {
  font-size: 16px;
  font-weight: 500;
}

.bs-canvas-body {
  background: #f9f9f9;
  position: absolute;
  width: 100%;
  top: 48px;
  bottom: 190px;
  overflow-y: scroll;
}
.col-xl-3.col-lg-3.col-md-4.col-sm-12.col-xs-12.mob-fil-blk .bs-canvas-body {
  overflow: auto;
}

.filter-body {
  background: #fff !important;
  bottom: 0 !important;
  padding-bottom: 60px;
  /*border-right: 1px solid #e7eded;*/
}

.filter-items {
  padding: 15px 15px 0px 0px;
}

.filtr-cate-title h4 {
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c;
  margin-bottom: 0;
}

.filter-item-body {
  max-height: 400px;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
}

.other-item-body {
  height: auto;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
}

.offer-item-body {
  height: auto;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
}

.price-pack-item-body {
  height: auto;
  width: 100%;
  margin-top: 20px;
  overflow-y: auto;
}

/* --- category Radio Btn --- */

.cte-select {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.cte-select li {
  margin-right: 0;
  width: 100%;
  height: 25px;
  position: relative;
  text-align: left;
  display: block;
  margin-bottom: 0px;
  border-bottom: 0;
}

.cte-select label,
.cte-select input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cte-select input[type="radio"] {
  display: none;
}

.cte-select input[type="radio"]:checked + label,
.Checked + label {
  /*background: #0157a7;*/
  color: #0157a7;
}

.cte-select label {
  padding: 0px 0px;
  cursor: pointer;
  /*background: #f9f9f9;*/
  color: #2b2f4c;
  border-radius: 0;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.cte-select label:hover {
  /* background: #0157a7;*/
  color: #0157a7;
}

.brand-list {
  width: 100%;
}

.search-by-catgory {
  margin-bottom: 15px;
  width: 100%;
}

.custom-control-label {
  font-size: 12px;
  font-weight: 400;
  color: #2b2f4c !important;
  vertical-align: middle !important;
  padding-top: 2px;
  padding-left: 5px;
  cursor: pointer;
}

.custom-control-label::before {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.custom-checkbox .custom-control-label::before {
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.custom-control-label::after {
  top: -0.09rem !important;
  left: -1.8rem !important;
  width: 2rem !important;
  height: 2rem !important;
  content: "";
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #0157a7 !important;
  background-color: #0157a7 !important;
}
.custom-control-label::before {
  position: absolute;
  top: 0.1rem !important;
  left: -1.5rem;
}

.pb2 {
  padding-bottom: 10px;
}

.pb2:last-child {
  padding-bottom: 10px;
}

.webproduct {
  color: #8f91ac;
  padding-left: 3px;
  display: inline;
}

/* --- Index Page --- */

.wrapper {
  padding-top: 128px;
  padding-bottom: 0px;
}

.wrapper-blog {
  padding-top: 60px !important;
}

.main-banner-slider {
  margin-top: 10px;
}

.offers-banner .owl-stage-outer {
  overflow: visible !important;
}

.offer-item {
  /* background: #fff;
    padding: 5px;
    border-radius: 5px;*/
  position: relative;
}

.offer-item-img {
  position: relative;
  width: 100%;
}

.offer-item-img img {
  border-radius: 0px;
  width: 100%;
}
.main-banner-slider .col-md-12 {
  padding: 0px !important;
}

.gambo-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2b2f4c;
  border-radius: 5px;
  opacity: 0.05;
}

.offer-top-text-banner {
  position: absolute;
  top: 20px;
  left: 5px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 15px 11px;
  width: 220px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.offer-top-text-banner p {
  font-size: 12px;
  font-weight: 600;
  color: #0157a7;
  margin-bottom: 3px;
  text-align: left;
  text-transform: uppercase;
}

.top-text-1 {
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c;
  margin-bottom: 5px;
  line-height: 24px;
  text-align: left;
}

.offer-top-text-banner span {
  font-weight: 500;
  font-size: 13px;
  color: #8f91ac;
  text-align: left;
  line-height: 24px;
}

.Offer-shop-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: inline-block;
  padding: 10px 30px;
  background: #0157a7;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
}

/* --- Slider Category --- */

.section145 {
  padding: 30px 0 0;
  width: 100%;
  display: block;
}

.main-title-tt {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-end;
}

.main-title-left {
  margin-right: auto;
}

.main-title-left span {
  font-size: 16px;
  text-align: center;
  color: #0157a7;
  font-weight: 400;
}

.main-title-left h1 {
  font-size: 22px;
  font-weight: 600;
  color: #2b2f4c;
  margin-top: 0;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.main-title-left h2 {
  font-size: 22px;
  font-weight: 600;
  color: #2b2f4c;
  margin-top: 0;
  display: block;
  text-transform: uppercase;
}

.see-more-btn {
  padding-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  text-align: right;
}

.see-more-btn:hover {
  color: #0157a7 !important;
}

.category-item {
  width: 100%;
  display: block;
  /*background: #fff;*/
  border-radius: 10px;
  text-align: center;
  padding: 20px 10px;
}

.cate-img {
  width: 100%;
  text-align: center;
  display: inline-block;
}

.cate-img img {
  display: inline-block;
}

.category-item h4 {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  margin-top: 15px;
  text-align: center;
}

.cate-slider .owl-nav {
  margin-top: 0 !important;
}

.cate-slider .owl-nav .owl-prev {
  left: -50px;
  font-size: 30px !important;
  position: absolute;
  top: 35%;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.cate-slider .owl-nav .owl-next {
  font-size: 30px !important;
  position: absolute;
  right: -50px;
  top: 35%;
  padding: 4px 0 !important;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.cate-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.cate-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.brand-slider .owl-nav {
  margin-top: 0 !important;
}

.brand-slider .owl-nav .owl-prev {
  left: -50px;
  font-size: 30px !important;
  position: absolute;
  top: 35%;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.brand-slider .owl-nav .owl-next {
  font-size: 30px !important;
  position: absolute;
  right: -50px;
  top: 35%;
  padding: 4px 0 !important;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.brand-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.brand-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

span.like-icon {
  color: #0157a7;
  font-size: 18px;
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  cursor: pointer;
  background-color: #eaeded;
  display: block;
  height: 35px;
  width: 35px;
  line-height: 37px;
  border-radius: 5px;
  transition: all 0.4s;
}

.like-icon:before,
.like-icon:after {
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0, -0.41, 0.19, 2.5);
  font-family: "Font Awesome 5 Free";
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  top: 0px;
}

.like-icon:before {
  content: "\f004";
  transform: scale(0.95);
}

.like-icon:after {
  content: "\f004";
  opacity: 0;
  transform: scale(0.3);
  font-weight: 900;
}

.like-icon.liked:after {
  opacity: 1;
  transform: scale(0.95);
}

.like-icon:hover,
.like-icon.liked {
  background-color: #0157a7;
  color: #fff;
}

.like-icon.liked:before {
  opacity: 0;
}

.product-item {
  /*width: 100%;*/
  display: block;
  background: #fff;
  border-radius: 3px;
  text-align: center;
  padding: 15px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  border-radius: 5px;
  border: 1px solid #eaeded;
}

.product-img {
  width: 100%;
  position: relative;
  padding: 10px 0px;
  display: block;
}

.product-img img {
  width: 100%;
  transition: transform 0.5s ease;
}

.product-item:hover img {
  transform: scale(1.1);
}

.offer-badge-1 {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  /* background: #0157a7; */
  background: #fa1a0f;
  padding: 3px 10px;
  border-radius: 5px;
  line-height: 20px;
  text-transform: uppercase;
}

.product-text-dt p {
  font-size: 10px;
  font-weight: 300;
  color: #064b73;
  margin-bottom: 0;
  line-height: 24px;
}

.product-text-dt p span {
  margin-left: 5px;
}

.product-text-dt .title-blk {
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
  height: 50px;

  color: #000000;
}
.product-text-dt .title-blk a {
  color: #000000;
}

.product-price {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #0157a7;
  text-align: center;
  margin-bottom: 15px;
}

.product-price span {
  color: #c7c7c7;
  text-decoration: line-through;
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
}

.qty-cart {
  display: flex;
  align-items: center;
  margin-top: 17px;
}

.cart-icon {
  margin-left: auto;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background: #0157a7;
  border-radius: 30px;
  padding: 5px 15px;
  border-bottom-right-radius: 0px;
}

.cart-icon:hover {
  color: #ffffff;
}

.offers-slider .owl-nav .owl-prev {
  left: -22px;
  font-size: 30px !important;
  position: absolute;
  top: 42%;
  text-align: center;
  height: 42px !important;
  width: 42px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.offers-slider .owl-nav .owl-next {
  font-size: 30px !important;
  position: absolute;
  right: -22px;
  top: 42%;
  padding: 4px 0 !important;
  text-align: center;
  height: 42px !important;
  width: 42px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.offers-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.offers-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-prev {
  left: -20px;
  font-size: 30px !important;
  position: absolute;
  top: 42%;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-next {
  font-size: 30px !important;
  position: absolute;
  right: -20px;
  top: 42%;
  padding: 4px 0 !important;
  text-align: center;
  height: 40px !important;
  width: 40px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.featured-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.best-offer-item {
  display: block;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

.best-offer-item img {
  width: 100%;
  border-radius: 5px;
}

.offer-counter-text {
  font-size: 30px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0px;
  text-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}

.cmtk_dt {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.code-offer-item img {
  width: 100%;
  border-radius: 5px;
}

/* --- Footer --- */

.footer {
  /*background: #fff;*/
  padding-top: 10px;
}

.footer-first-row {
  padding: 20px 0;
  border-bottom: 1px solid #03396e;
  background: #0054a6;
  border-top: 1px solid #3772ac;
}

.col-md-4.col-sm-4.col-xs-12.cent-blk {
  margin: 0 auto;
  text-align: center;
}

.call-email-alt {
  display: inline;
  width: 100%;
}
.footer-first-row span {
  color: #333333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  padding-right: 15px;
}

.call-email-alt li {
  display: inline-block;
  margin-right: 20px;
}

.call-email-alt li:last-child {
  margin-right: 0;
}

.callemail {
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
}

.callemail i {
  margin-right: 5px;
  color: #ffffff;

  border-radius: 15px;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 24px;
}

.social-links-footer {
  text-align: left;
  float: none;
  width: 100%;
  margin-top: 15px;
}
.social-links-footer span {
  color: #333333;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  padding-right: 15px;
}

.social-links-footer h4 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 17px;
}

.social-links-footer ul {
  display: inline;
  margin-top: 5px;
  width: 100%;
}

.social-links-footer ul li {
  display: inline-block;

  border: 1px solid #ffffff;
  border-radius: 15px;
  text-align: center;
  width: 40px;
  height: 30px;
  line-height: 30px;
  background: #ffffff;
}

.social-links-footer ul li:last-child {
  padding-right: 0;
}

.social-links-footer ul li a {
  color: #0054a6 !important;
  font-size: 16px;
  text-align: center;
  position: relative;
  display: inline-block;
  bottom: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.social-links-footer ul li:hover a {
  bottom: 0px;
  display: block;
  color: #0054a6 !important;
}
.social-links-footer ul li:hover {
  bottom: 0px;
  color: #0054a6 !important;
  background: #ffffff;
}

.footer-second-row {
  padding-bottom: 50px;

  background: #0054a6;
}

.second-row-item {
  margin-top: 47px;
}

.second-row-item h4 {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 17px;
  text-transform: uppercase;
}

.second-row-item ul li {
  display: block;
  padding-bottom: 9px;
}

.second-row-item ul li:last-child {
  padding-bottom: 0;
}

.second-row-item ul li a {
  color: #ccc;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  position: relative;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
}

.second-row-item ul li a:hover {
  color: #000000 !important;
  left: 5px;
}

.second-row-item-2 {
  margin-top: 86px;
}

.second-row-item-app {
  margin-top: 47px;
}

.second-row-item-app h4 {
  color: #2b2f4c;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 17px;
}
.read-more-content {
  display: none;
  padding-top: 0px;
}
.read-more {
  display: inline-block;
  margin-top: 8px;
  font-weight: 700;
}
.timeline-info {
  position: relative;
  background: #f3f1f1;
  padding: 24px 20px 18px;
  margin-bottom: 12px;
  border: 1px solid #f3f1f1;
  border-radius: 8px;
  margin-left: 54px;
}

.second-row-item-app ul li {
  display: inline-block;
  padding-right: 0;
}

.second-row-item-app ul li:last-child {
  padding-right: 0;
}

img.download-btn {
  width: 120px;
}

.footer-last-row {
  padding: 15px 0px 15px 0;
  background: #01488e;
  text-align: center;
}

.footer-bottom-links ul li {
  display: inline-block;
  padding: 0 10px;
}

.footer-bottom-links li a {
  color: #ccc;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  position: relative;
  bottom: 0;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.footer-bottom-links ul li:hover a {
  bottom: 2px;
  color: #0157a7 !important;
}

.copyright-text {
  color: #ccc;
  font-weight: 500;
  font-size: 12px;
  padding: 0px 0 0;
  letter-spacing: 0.3px;
  display: inline;
  float: left;
}

.financial-institutes {
  display: inline-block;
  list-style: none;
  line-height: 0;
  float: right;
}

.financial-institutes__logo {
  display: inline-block;
  margin: 0 1px 5px 0;
}

.financial-institutes__logo img {
  width: 35px;
  height: auto;
}

.second-row-item-payment {
  margin-top: 0px;
}

.second-row-item-payment h4 {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}

/* --- Product Grid Page --- */

.gambo-Breadcrumb {
  border-top: 1px solid #efefef;
  padding: 5px 0px 0px 0px;
}

.gambo-Breadcrumb .breadcrumb {
  background-color: transparent;
  border-radius: 0;
  font-size: 11px;
  font-weight: 500;
  color: #8f91ac;
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item a {
  color: #8f91ac !important;
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item a:hover {
  color: #2b2f4c !important;
}

.gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
  color: #2b2f4c;
}
.breadcrumb {
  padding: 0.75rem 1rem 0px 1rem !important;
}

.all-product-grid {
  margin-top: 30px;
}

.product-top-dt {
  float: left;
  width: 100%;
  display: block;
}

.product-left-title {
  float: left;
}
.product-left-title h4 a {
  color: #fff;
}
.product-left-title h2 {
  font-size: 24px;
  font-weight: 600;
  color: #2b2f4c;
  text-align: left;
  padding-top: 3px;
  text-transform: capitalize;
}
.product-left-title h1 {
  font-size: 24px;
  font-weight: 600;
  color: #2b2f4c;
  text-align: left;
  padding-top: 3px;
  text-transform: capitalize;
  letter-spacing: 0.1em;
}

.filter-btn {
  font-size: 13px;
  font-weight: 500;
  float: right;
  display: block;
  height: 40px;
  width: 110px;
  padding: 10px 0;
  text-align: center;
  /*color: #2b2f4c;*/
  background: #0157a7;
  border-radius: 30px;
  border: 1px solid #efefef;
  text-transform: uppercase;
  color: #fff;
}

.filter-btn:hover {
  background: #233d53;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  /*background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));*/
}

.product-sort {
  float: right;
  margin-right: 10px;
  margin-top: 1px;
}

.ui.selection.dropdown.vchrt-dropdown {
  border: 1px solid #efefef;
  font-weight: 400;
  color: #2b2f4c;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-background-clip: padding-box;
}

.ui.selection.dropdown.vchrt-dropdown:hover {
  border-color: #efefef;
}

.ui.selection.visible.dropdown.vchrt-dropdown > .text:not(.default) {
  font-weight: 400;
  color: #2b2f4c;
}

.ui.dropdown.vchrt-dropdown .menu > .item {
  color: #2b2f4c !important;
  font-weight: 400 !important;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.product-list-view {
  margin-top: 30px;
}

.more-product-btn {
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

.show-more-btn {
  height: 30px;
  padding: 0 20px;
  border: 0;
  border-radius: 15px;
  color: #0157a7;
  background: transparent;
  text-align: center;
  font-weight: 600;
  font-family: "Lato", sans-serif !important;
  border-bottom-right-radius: 0px;
  text-decoration: underline;
}

/* --- Single Product View --- */

.product-dt-view {
  background: #fff;
  padding: 20px 30px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

#sync1 .item {
  margin: 0px;
  text-align: center;
  cursor: pointer;
}

#sync2 .item {
  padding: 6px;
  margin: 0;
  cursor: pointer;
  background: #fff;
}

#sync1 .owl-nav .owl-prev {
  left: -20px;
  font-size: 20px !important;
  position: absolute;
  top: 45%;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

#sync1 .owl-nav .owl-next {
  font-size: 20px !important;
  position: absolute;
  right: -20px;
  top: 45%;
  padding: 4px 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

#sync1 .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

#sync1 .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.product-dt-right {
  margin-top: 15px;
  margin-left: 20px;
}

.product-dt-right h2 {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #2b2f4c;
  margin-bottom: 16px;
}

.no-stock {
  display: block;
}

.no-stock p {
  display: inline-block;
  margin-right: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
}

.no-stock p:last-child {
  margin-right: 0;
}

.no-stock p span {
  margin-left: 5px;
  color: #8f91ac;
  font-weight: 300;
}

/* --- Single Product Radio Btn --- */

.product-radio {
  margin-top: 7px;
}

.product-now {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.product-now li {
  margin-right: 5px;
  width: 36px;
  height: 36px;
  position: relative;
  text-align: center;
  display: inline-block;
  margin-top: 5px;
}

.product-now label,
.product-now input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.product-now input[type="radio"] {
  display: none;
}

.product-now input[type="radio"]:checked + label,
.Checked + label {
  background: #0157a7;
  color: #fff;
}

.product-now label {
  padding: 2px;
  cursor: pointer;
  background: #eaeded;
  color: #333;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  /*overflow:hidden;*/
}

.product-now label:hover {
  background: #0157a7;
  color: #fff;
}

.pp-descp {
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #3e3f5e;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
}

.product-group-dt {
  padding: 15px 0px;
  border-radius: 5px;
}

.product-group-dt ul li {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
}

.product-group-dt ul li:last-child {
  margin-right: 0;
}

.main-price {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #2b2f4c;
}

.main-price span {
  margin-left: 0px;
  color: #0157a7;
  font-weight: 700;
  font-size: 20px;
}
.color-discount {
  color: #0157a7;
  font-size: 12px;
  display: inline-flex;
  background: #eaeded;
  text-align: center;

  padding: 0px 5px;
  border-radius: 3px;
}
.color-delivered {
  color: #0bc140;
  font-size: 12px;
  display: inline-flex;
  background: #eaeded;
  text-align: center;

  padding: 0px 5px;
  border-radius: 3px;
}

.mrp-price {
  text-decoration: line-through;
  font-weight: 500;
  color: #ccc;
}

.color-discount:after {
  content: "";
  margin-left: 0px;
  color: #2b2f4c;
}

.gty-wish-share {
  margin-top: 22px;
}

.gty-wish-share li {
  margin-right: 10px !important;
}

.ordr-crt-share {
  margin-top: 22px;
}

.ordr-crt-share li {
  margin-right: 10px !important;
}

span.save-icon {
  color: #0157a7;
  margin-left: 10px;
  font-size: 18px;
  position: relative !important;
  cursor: pointer;
  background-color: rgba(234, 237, 237, 1);
  height: 35px;
  width: 35px;
  border-radius: 30px;
  transition: all 0.4s;
}

.icon-bar {
  position: fixed;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background: #0157a7;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  background-image: -webkit-linear-gradient(
    left,
    rgba(1, 87, 167, 0.9),
    rgba(1, 87, 167, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(1, 87, 167, 0.9),
    rgba(1, 87, 167, 0.9)
  );
}

.facebook {
  background: #3b5998;
  color: #fff;
  border-radius: 0 5px 0 0;
}

.twitter {
  background: #55acee;
  color: #fff;
}

.google {
  background: #dd4b39;
  color: #fff;
}

.linkedin {
  background: #007bb5;
  color: #fff;
}

.whatsapp {
  background: #25d366;
  color: #fff;
  border-radius: 0 0 5px 0;
}

.content {
  margin-left: 75px;
  font-size: 30px;
}

.add-cart-btn {
  background: #0157a7;
  border: 0;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  font-family: "Lato", sans-serif !important;
  font-weight: 500;
  border-radius: 5px;
}

.order-btn {
  background: #064b73;
  border: 1px solid #064b73;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  font-family: "Lato", sans-serif !important;
  font-weight: 500;
  border-radius: 30px;
}
.ordr-crt-share .order-btn {
  font-weight: 700;
  font-size: 16px;
  width: 170px;
}
.ordr-crt-share .add-cart-btn {
  font-weight: 700;
  font-size: 16px;
  width: 170px;
}

.add-cart-btn i {
  margin-right: 5px;
  font-size: 16px;
}
.ordr-crt-share i {
  margin-right: 5px;
  font-size: 16px;
}

.pdp-details {
  background: #f9f9f9;
  margin-top: 0px;
  border-radius: 5px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.pdp-details ul li {
  padding: 10px;
  border-bottom: 1px solid #efefef;
  width: 100%;
  display: inline-block;
  float: left;
}

.pdp-details ul li:last-child {
  border-right: 0;
}

.pdp-group-dt {
  display: flex;
  align-items: center;
  width: 100%;
}

.pdp-icon i {
  font-size: 24px;
  color: #0157a7;
  font-weight: 400;
}

.pdp-text-dt {
  margin-left: 10px;
}

.pdp-text-dt span {
  font-size: 12px;
  font-weight: 500;
  color: #2b2f4c;
  margin-bottom: 5px;
  display: block;
}

.pdp-text-dt p {
  font-size: 11px;
  font-weight: 300;
  color: #3e3f5e;
  margin-bottom: 0;
  line-height: normal;
}

.pay__logo {
  display: inline-block;
  margin: 0 1px 5px 0;
}

.pay__logo img {
  width: 20px;
}

.pdpt-bg {
  margin-top: 30px;
  background: #fff;
  border-radius: 10px;
}

.pdpt-bg {
  margin-top: 30px;
  background: #eaeded;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}
.pdpt-title {
  background: #0157a7;
}

.pdpt-title h4 {
  border-bottom: 1px solid #efefef;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}

.border_radius:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pdpt-body {
  height: 600px;
  overflow-y: auto;
}

.pdct-dts-1 {
  padding: 0 30px 30px;
}

.pdct-dt-step h4 {
  margin-top: 27px !important;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: left;
}

.pdct-dt-step p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  text-align: left;
}

.product_attr {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  text-align: left;
}

/* --- Checkout Page --- */

.checkout-step-body p {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #3e3f5e;
  text-align: left;
}

.phn145 {
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: #3e3f5e;
}

.phn145 span {
  font-weight: 600;
  color: #0157a7;
}

.phn145 .edit-no-btn {
  font-size: 13px;
  font-weight: 500;
  margin-left: 15px;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  background: #0157a7;
  border-radius: 15px;
}

.code-alrt-inputs li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.code-alrt-inputs input {
  text-align: center;
}

ul.code-alrt-inputs input {
  width: 40px;
  border: 1px solid #efefef;
  text-align: center;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

ul.code-alrt-inputs input:focus {
  box-shadow: none;
  border: 1px solid #0157a7;
}

label.control-label {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
}

.chck-btn {
  height: 38px;
  display: inline-block;
  background: #0157a7;
  color: #fff !important;
  padding: 10px 15px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}
.relative {
  position: relative;
}

.resend-link {
  margin-top: 10px;
  display: block;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.resend-link a {
  color: #ffffff;
  text-decoration: underline;
}
.resend-link:hover {
  color: #0157a7;
}

.checout-address-step .form-control {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #2b2f4c;
  border: 1px solid #efefef;
  position: relative;
  padding: 0 1rem 0 1rem;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #0157a7 !important;
}

.address-btns {
  display: flex;
  align-items: end;
}

.save-btn14 {
  height: 40px;
  padding: 0 20px;
  border: 1px solid;
  background: transparent;
  color: #0157a7;
  border-radius: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.next-btn16 {
  height: 36px;
  padding: 8px 20px;
  border: 1px solid #0157a7;
  background: #0157a7;
  color: #fff !important;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  display: inline-block;
  float: right;
}

.time-radio {
  margin-top: 10px;
}

.date-slider-group {
  margin-top: 10px;
}

.date-slider-group {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.date-slider-group .date-now {
  margin-right: 5px;
  width: 100%;
  height: 36px;
  position: relative;
  text-align: center;
  display: inline-block;
}

.date-slider-group label,
.date-slider-group input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.date-slider-group input[type="radio"] {
  display: none;
}

.date-slider-group input[type="radio"]:checked + label,
.Checked + label {
  background: #0157a7;
}

.date-slider-group label {
  padding: 5px;
  cursor: pointer;
  background: #2b2f4c;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
}

.date-slider-group label:hover {
  background: #0157a7;
}

.ui.checkbox.chck-rdio label {
  color: #2b2f4c !important;
  font-weight: 500 !important;
  font-family: "Lato", sans-serif !important;
}

.ui.radio.checkbox.chck-rdio .box:after,
.ui.radio.checkbox.chck-rdio label:after {
  background-color: #0157a7 !important;
}

.total-checkout-group {
  padding: 10px 20px 0;
  border-top: 1px solid #cccccc;
  margin-top: 0px;
}

.payment-secure {
  text-align: center;
  padding: 20px;
  font-weight: 500;
  font-size: 13px;
  color: #2b2f4c;
}

.checkout-safety-alerts {
  background: #fff;
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

.checkout-safety-alerts p {
  margin-bottom: 0;
  padding: 15px;
  border-bottom: 1px solid #efefef;
  font-size: 13px;
  font-weight: 500;
  color: #3e3f5e;
  text-align: left;
}

.checkout-safety-alerts p:last-child {
  border-bottom: 0;
}

.checkout-safety-alerts i {
  margin-right: 5px;
}

.return-departure-dts {
  color: #727272;
  font-weight: 400;
  font-size: 13px;
  position: relative;
  margin-top: 0;
  display: none;
  margin-bottom: 25px !important;
}
.checout-address-step2 {
  display: none;
  margin-top: 25px !important;
}

.single-payment-method:first-child .payment-method-details {
  display: block;
}

.radio--group-inline-container_1 {
  width: 100%;
  display: block;
  margin-bottom: 22px !important;
}

.radio--group-inline-container_1 li {
  display: inline-block;
  width: 33.333%;
  margin-right: 10px;
}

.radio--group-inline-container_1 li:last-child {
  margin-right: 0;
}

.radio--group-inline-container_1 .radio-item_1 {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
}

.radio--group-inline-container_1 .radio-item_1 input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio--group-inline-container_1
  .radio-item_1
  input[type="radio"]
  + .radio-label_1:before {
  content: "";
  background: #f7f7f7;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1em;
  height: 1em;
  top: 0px;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.radio--group-inline-container_1
  .radio-item_1
  input[type="radio"]:checked
  + .radio-label_1:before {
  background-color: #0157a7;
  box-shadow: inset 0 0 0 2px #f4f4f4;
}

.radio--group-inline-container_1
  .radio-item_1
  input[type="radio"]:focus
  + .radio-label_1:before {
  outline: none;
  border-color: #0e8a86;
}

.radio--group-inline-container_1
  .radio-item_1
  input[type="radio"]:disabled
  + .radio-label_1:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio--group-inline-container_1
  .radio-item_1
  input[type="radio"]
  + .radio-label:empty:before {
  margin-right: 0;
}

.radio-label_1 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
  padding: 15px 20px;
  background: #0157a7;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #efefef;
}

.radio-label_1:hover {
  background: #01488e;
  transition: all 0.4s ease-in-out;
}

.radio--group-inline-container_2 {
  width: 100%;
  display: block;
  margin-bottom: 22px !important;
}

.radio--group-inline-container_2 li {
  display: inline-block;
  width: 100%;
  margin-right: 10px;
}

.radio--group-inline-container_2 li:last-child {
  margin-right: 0;
}

.radio--group-inline-container_2 .radio-item_2 {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
}

.radio--group-inline-container_2 .radio-item_2 input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio--group-inline-container_2
  .radio-item_2
  input[type="radio"]
  + .radio-label_2:before {
  content: "";
  background: #f7f7f7;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1em;
  height: 1em;
  top: 0px;
  position: relative;
  margin-right: 10px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.radio--group-inline-container_2
  .radio-item_2
  input[type="radio"]:checked
  + .radio-label_2:before {
  background-color: #0157a7;
  box-shadow: inset 0 0 0 2px #f4f4f4;
}

.radio--group-inline-container_2
  .radio-item_2
  input[type="radio"]:focus
  + .radio-label_2:before {
  outline: none;
  border-color: #0e8a86;
}

.radio--group-inline-container_2
  .radio-item_2
  input[type="radio"]:disabled
  + .radio-label_2:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio--group-inline-container_2
  .radio-item_2
  input[type="radio"]
  + .radio-label:empty:before {
  margin-right: 0;
}

.radio-label_2 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  line-height: 16px;
  color: #2b2f4c;
  cursor: pointer;
  padding: 15px 20px;
  background: #eaeded;
  width: 100%;
  border-radius: 30px;
  border: 1px solid #efefef;
}

.radio-label_2:hover {
  background: #f5f5f5;
  transition: all 0.4s ease-in-out;
}
.full-width {
  width: 100%;
}

.pymnt_title h4 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 15px;
  color: #2b2f4c;
}

.swdh19 {
  width: 100%;
}

.ui[class*="left icon"].swdh19.input > input {
  padding-left: 1em !important;
  padding-right: 1em !important;
  height: 34px;
}

.ui.input > input {
  font-weight: 500 !important;
  background: #fff;
  border: 1px solid #efefef !important;
  color: #2b2f4c !important;
  border-radius: 5px !important;
}

.ui.input > input:focus {
  border: 1px solid #0157a7 !important;
}

.ui.selection.active.dropdown.form-dropdown {
  border-color: #0157a7 !important;
}

.ui.selection.active.dropdown.form-dropdown .menu {
  border-color: #0157a7 !important;
}

.ui.dropdown.form-dropdown .menu > .item {
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.ui.selection.dropdown.form-dropdown:hover {
  border-color: #efefef !important;
}

.ui.selection.dropdown.form-dropdown {
  color: #2b2f4c !important;
  font-weight: 500 !important;
  border: 1px solid #efefef !important;
}

.ui.selection.visible.dropdown.form-dropdown > .text:not(.default) {
  font-weight: 500 !important;
  color: #2b2f4c !important;
}

.promo-link45 {
  margin-top: 0px;
  background: #fff;
  border-radius: 3px;
  padding: 12px 20px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  line-height: 24px;
  display: block;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  padding-bottom: 15px;
}

.promo-link45:hover {
  color: #0157a7 !important;
}

/* --- Order Placed --- */

.order-placed-dt {
  text-align: center;
  padding: 20px 0 14px;
  float: left;
  width: 100%;
}

.order-placed-dt .icon-circle {
  font-size: 56px;
  color: #0157a7;
}
.order-placed-dt h2 {
  font-size: 30px;
  font-weight: 500;
  color: #2ebf76;
  text-align: center;
  margin-bottom: 25px;
}

.order-placed-dt p {
  font-size: 16px;
  font-weight: 500;
  color: #3e3f5e;
  margin-bottom: 0;
  text-align: center;
  line-height: 24px;
}

.delivery-address-bg {
  margin-top: 40px;
  background: #fff;
  border-radius: 5px;
  float: left;
  width: 100%;
  text-align: center;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  padding: 20px;
}

.title585 {
  display: inline-block;
  float: left;
  width: 100%;
  border-bottom: 1px solid #efefef;
  background: #064b73;
  border-radius: 5px;
  margin-bottom: 15px;
}

.pln-icon {
  display: inline;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-right: 10px;
}

.title585 h4 {
  display: inline;
  margin-top: 0;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 50px;
}

.pln-icon i {
  font-size: 20px;
  color: #fff;
}

.address-placed-dt1 {
  display: inline;
  padding: 20px;
  width: 100%;
}

.address-placed-dt1 li {
  margin-bottom: 10px;
}

.address-placed-dt1 li:last-child {
  margin-bottom: 0;
}

.address-placed-dt1 i {
  margin-right: 5px;
  font-size: 24px;
}

.address-placed-dt1 p {
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: #2b2f4c;
}

.address-placed-dt1 p span {
  margin-left: 10px;
  font-weight: 700;
  color: #3e3f5e;
  font-size: 16px;
}

.stay-invoice {
  float: left;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.invc-link {
  margin-left: auto;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background: #0157a7;
  padding: 5px 15px;
  border-radius: 15px;
}

.st-hm {
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c;
}

.st-hm i {
  margin-left: 5px;
}

.placed-bottom-dt {
  padding: 20px;
  float: left;
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 0;
  text-align: center;
  line-height: 24px;
  width: 100%;
  background: #eaeded;
  margin-top: 15px;
  border-radius: 5px;
}

.placed-bottom-dt span {
  font-weight: 600;
  color: #0157a7;
}
.placed-bottom-dt a {
  color: #0157a7;
}

/* --- User Dashboard Page --- */

.dashboard-group {
  background: rgba(245, 93, 44, 0.3);
  position: relative;
  padding: 50px 0;
}

.dashboard-group:before {
  content: "";
  /* background: url(../images/banner.svg) no-repeat bottom;*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  background-size: cover;
  opacity: 0.02;
}

.user-dt {
  position: relative;
  width: 100%;
  text-align: center;
}

.user-img {
  position: relative;
  display: inline-block;
}

.user-img img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.user-img .change-btn {
  position: absolute;
  bottom: 5px;
  right: -5px;
  background: #0157a7;
  color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid #ffff;
  font-size: 18px;
  padding: 8px 5px;
}

.img-add {
  display: block;
}

.img-add [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
  float: left;
}

.img-add [type="file"] + label {
  cursor: pointer;
  display: inline-block;
  outline: none;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: -5px;
  background: #0157a7;
  color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  border: 1px solid #ffff;
  font-size: 18px;
  padding: 8px 5px;
}

.img-add [type="file"] + label:hover {
  background: #0157a7;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  background-image: -webkit-linear-gradient(
    left,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
}

.user-dt h4 {
  font-size: 24px;
  color: #2b2f4c;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 18px;
}

.user-dt p {
  font-size: 13px;
  color: #3e3f5e;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 500;
  display: block;
}

.user-dt p a {
  color: #3e3f5e !important;
  font-size: 16px;
  margin-left: 5px;
}

.user-dt p a:hover {
  color: #0157a7 !important;
}

.earn-points img {
  width: 24px;
  margin-right: 7px;
}

.earn-points {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c;
  background: rgba(255, 255, 255, 0.3);
  padding: 5px 11px 5px 10px;
  border-radius: 5px;
}
.contact-side-tabs {
  position: sticky;
  top: auto;
}

.contact-side-tabs {
  margin-top: 20px;
  border-radius: 5px;
  /*box-shadow: 0 1px 2px 0 #e9e9e9;*/
}

.left-side-tabs {
  position: sticky;
  top: 167px;
}

.left-side-tabs {
  background: #fff;
  margin-top: 20px;
  border-radius: 5px;
  /*box-shadow: 0 1px 2px 0 #e9e9e9;*/
}
.right-side-tabs {
  position: sticky;
  top: 167px;
}

.right-side-tabs {
  background: #fff;
  /*margin-top: 50px;
    border-radius: 5px;
box-shadow: 0 1px 2px 0 #e9e9e9;*/
}
.user-item {
  display: flex;
  padding: 12px 20px;
  border-bottom: 1px solid #efefef;
  font-size: 13px;
  font-weight: 400;
  color: #2b2f4c;
  text-align: left;
  align-items: center;
}

.user-item:last-child {
  border-bottom: 0;
}

.user-item i {
  margin-right: 10px;
  font-size: 18px;
}

.user-item:hover {
  color: #2b2f4c;
  background: #eaeded;
}

.user-item:first-child:hover {
  border-radius: 5px 5px 0 0;
}

.user-item:last-child:hover {
  border-radius: 0 0 5px 5px;
}

.user-item:hover i {
  color: #0157a7 !important;
}

.user-item.active {
  background: #0157a7;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  /*background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));*/
  pointer-events: none;
}

.user-item:first-child.active {
  border-radius: 5px 5px 0 0;
}

.user-item:last-child.active {
  border-radius: 0 0 5px 5px;
}

.dashboard-right {
  margin-top: 20px;
  min-height: 500px;
}

.main-title-tab h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2b2f4c;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0;
}

.main-title-tab i {
  margin-right: 5px;
}

.welcome-text h2 {
  font-size: 24px;
  font-weight: 500;
  color: #2b2f4c;
  text-align: left;
  margin-bottom: 3px !important;
  margin-top: 30px !important;
}

.ddsh-body {
  padding: 20px;
}

.ddsh-body h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  color: #2b2f4c;
  font-weight: 500;
}

.more-link14 {
  text-align: left;
  padding: 20px;
  font-weight: 500;
  font-size: 13px;
  color: #2b2f4c;
  display: block;
  border-top: 1px solid #efefef;
}

.more-link14:hover {
  color: #0157a7 !important;
}

.ddsh-body ul li {
  display: inline-block;
  margin-right: 10px;
}

.ddsh-body ul li:last-child {
  margin-right: 0;
}

.small-reward-dt {
  color: #fff;
  background: #0157a7;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  display: block;
}

.rewards-link5 {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
}

.rewards-link5:hover {
  color: #0157a7 !important;
}

.smll-history {
  display: flex;
  width: 100%;
  padding: 4px 0;
}

.order-title {
  font-size: 13px;
  font-weight: 500;
  color: #0157a7;
}

.order-title span {
  font-size: 12px;
  display: inline-block;
  background: #2b2f4c;
  color: #fff;
  height: 18px;
  border-radius: 100%;
  width: 18px;
  text-align: center;
  font-weight: 700;
  margin-left: 5px;
  cursor: pointer;
}

.order-title span:hover {
  background: #0157a7;
}

.smll-history p {
  margin-left: auto;
  font-size: 13px;
  font-weight: 600;
  color: #3e3f5e;
}

.order-list-145 li {
  display: block !important;
  margin-right: 0 !important;
}

.wllt-body h2 {
  font-weight: 500;
}

.order-status {
  margin-left: auto;
  font-size: 13px;
  font-weight: 500;
  color: #0157a7;
}

[data-inverted][data-position~="top"][data-tooltip]:before {
  background: #2b2f4c !important;
}

[data-position="top right"][data-tooltip]:before {
  right: 0.1em !important;
}

[data-tooltip][data-inverted]:after {
  background: #2b2f4c !important;
  color: #fff;
}

[data-tooltip]:after {
  border: 1px solid #d4d4d5;
  font-size: 13px !important;
  font-weight: 600 !important;
  font-family: "Lato", sans-serif;
}

.wllt-body {
  padding: 20px 20px 0;
}

.wallet-list {
  padding-top: 10px;
}

.wallet-list li {
  width: 50% !important;
  display: inline-block;
  margin-right: 0 !important;
  margin-bottom: 20px;
  margin-left: -2px;
}

.wallet-links14 {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  display: flex;
  align-items: center;
}

.wallet-links14 i {
  font-size: 18px;
  margin-right: 5px;
}

.newsletter-input {
  position: relative;
}

.newsletter-btn {
  background: #0157a7;
  position: absolute;
  right: 2px;
  top: 3px;
  height: 34px;
  border: 0;
  width: 35px;
  border-radius: 3px;
  font-size: 18px;
  color: #fff;
}

.newsletter-input .form-control {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #2b2f4c;
  border: 1px solid #efefef;
  position: relative;
  padding: 0 3rem 0 1rem;
  height: 40px;
}

.coupon-input {
  position: relative;
}

.coupon-btn {
  background: #0157a7;
  position: absolute;
  right: 2px;
  top: 3px;
  height: 35px;
  border: 0;
  width: auto;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  padding: 10px;
}

.coupon-input .form-control {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #2b2f4c;
  border: 1px solid #efefef;
  position: relative;
  padding: 0 3rem 0 1rem;
  height: 40px;
}
/* --- Dashboard My Orders --- */

.pdpt-title h6 {
  border-bottom: 1px solid #efefef;
  padding: 15px 20px;
  font-size: 13px;
  font-weight: 400;
  color: #2b2f4c;
  margin-bottom: 0;
}

.order-body10 {
}

.order-dtsll {
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.order-dtsll li {
  display: inline-block;
  vertical-align: top;
}

.order-dt-img {
  /*background: #f9f9f9;
    padding: 10px;
    border: 1px solid #efefef;*/
  border-radius: 5px;
  text-align: center;
}

.order-dt-img img {
  width: 70px;
  border: 1px solid #efefef;
  margin-right: 10px;
}

.order-dt47 {
  margin-left: 15px;
}

.order-dt47 h4 {
  font-size: 12px;
  color: #2b2f4c;
  margin-bottom: 5px;
  line-height: 24px;
  text-align: left;
  font-weight: 500;
}

.order-dt47 p {
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: #3e3f5e;
  margin-bottom: 7px;
}

.track-order {
  padding: 20px 0 20px;
}

.track-order h4 {
  margin-left: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #2b2f4c;
  text-align: left;
}

.bs-wizard {
  margin-top: 0;
  display: flex;
  width: 100%;
}

/*Form Wizard*/
.bs-wizard {
  /*border-bottom: solid 1px #e0e0e0;*/
  padding: 0;
}

.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
  width: 100%;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #2b2f4c;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 400;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 13px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #233d53;
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  content: " ";
  width: 14px;
  height: 14px;
  background: #0157a7;
  border-radius: 50px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 22px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #37ae02;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #efefef;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
  opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
  left: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.progress {
  background-color: #efefef !important;
}
/*END Form Wizard*/

.alert-offer img {
  width: 30px;
  margin-right: 10px;
}

.alert-offer {
  padding: 20px;
  border-top: 1px solid #efefef;
  font-weight: 400;
  color: #3e3f5e;
  text-align: left;
  line-height: 24px;
}

.call-bill {
  padding: 15px 20px;
  display: flex;
  border-top: 1px solid #efefef;
  align-items: center;
}

.delivery-man {
  font-size: 13px;
  color: #2b2f4c;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.delivery-man a {
  margin-right: 30px;
  color: #0157a7;
}

.delivery-man a:hover {
  color: #0157a7 !important;
  text-decoration: underline !important;
}

.order-bill-slip {
  margin-left: auto;
}

.bill-btn5 {
  display: block;
  background: #0157a7;
  color: #fff;
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 30px;
  font-size: 12px;
}

/* --- My Rewards --- */

.reward-body-dtt {
  padding: 30px;
  text-align: center;
}

.reward-img-icon {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: #f9f9f9;
  border-radius: 100%;
  border: 2px solid #efefef;
  padding: 18px 0;
}

.reward-img-icon img {
  width: 40px;
  text-align: center;
}

.rewrd-title {
  display: block;
  margin-top: 20px;
  font-weight: 500;
  color: #3e3f5e;
  font-size: 13px;
  text-align: center;
}

.cashbk-price {
  color: #2b2f4c;
  margin-top: 12px;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
}

.reward-body-all {
  display: flex;
  text-align: center;
}

.reward-body-all li {
  display: inline-block;
  width: 33.333%;
  padding: 30px 10px;
  border-right: 1px solid #efefef;
}

.reward-body-all li:last-child {
  border-right: 0;
}

.tt-icon {
  width: 45px;
  height: 45px;
  display: inline-block;
  background: #0157a7;
  border-radius: 100%;
  padding: 11px 0;
}

.tt-icon i {
  color: #fff;
  font-size: 24px;
}

.total-rewards span {
  display: block;
  margin-top: 20px;
  font-weight: 600;
  color: #3e3f5e;
  font-size: 13px;
  text-align: center;
}

.total-rewards h4 {
  color: #2b2f4c;
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
}

.date-reward {
  display: inline-block;
  margin-top: 20px;
  font-weight: 500;
  background: #f9f9f9;
  border: 1px dashed #efefef;
  padding: 2px 15px;
  border-radius: 5px;
  font-size: 12px;
  color: #2b2f4c;
}

.rewards-coupns {
  position: relative;
}

.top-coup-code {
  position: absolute;
  top: 0;
  right: 0;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  background: #0157a7;
  border-radius: 0 5px 0 3px;
  cursor: pointer;
  border: 1px dashed #fff;
}

.gambo-body-cash {
  padding: 30px 20px;
  text-align: center;
}

.gambo-body-cash p {
  font-size: 13px;
  font-weight: 500;
  color: #3e3f5e;
  margin-top: 20px;
  line-height: 24px;
}

.rotate-img {
  transform: rotate(260deg);
}

.table-responsive {
  border: 0;
  border-radius: 0 0 5px 5px;
  margin-bottom: 0;
  overflow-x: inherit;
}

table.table.ucp-table {
  margin-bottom: 0px;
}

.ucp-table {
  height: auto;
  overflow: hidden;
  border-radius: 0;
}

.ucp-table thead {
  font-weight: 500;
  padding: 14px !important;
  border-radius: 0;
  color: #2b2f4c !important;
}

.ucp-table thead tr th:first-child {
  border-bottom-left-radius: 0;
}

.ucp-table thead tr th {
  color: #2b2f4c;
  font-size: 13px;
  background-color: #ffecec;
  font-weight: 500;
}

.ucp-table tfoot {
  font-weight: 400;
  padding: 14px !important;
  border-radius: 4px;
  font-family: "Lato", sans-serif;
  color: #2b2f4c !important;
}

.ucp-table tfoot td {
  color: #fff;
  font-size: 13px;
  background-color: #2b2f4c;
  padding: 0.75rem !important;
}

.ucp-table.earning__table td {
  padding: 1rem !important;
}

table {
  margin: 0px auto 0px;
  font-size: 13px;
  width: 100%;
}

.ucp-table tbody {
  background: #fff;
}

.ucp-table td,
.ucp-table th {
  border-top: 1px solid #efefef !important;
}

.ucp-table td {
  padding: 1.5rem 0.75rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.ucp-table th:first-child {
  border-top: 0 !important;
}

.ucp-table thead th {
  vertical-align: bottom;
  border-bottom: 0 !important;
}

.course_active {
  color: #ed2a26;
}

.ucp-table tbody tr td {
  font-size: 13px;
  vertical-align: middle;
  font-weight: 400;
  color: #3e3f5e;
}

.offer_active {
  color: #0157a7;
  font-weight: 700;
}

.add-cash-body {
  padding: 20px;
}

.history-body {
  height: 360px;
  overflow-y: auto;
}

.history-list li {
  display: block;
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.history-list li:last-child {
  border-bottom: 0;
}

.purchase-history {
  display: flex;
  align-items: center;
}

.purchase-history-right {
  margin-left: auto;
}

.purchase-history-left h4 {
  font-size: 16px;
  color: #2b2f4c;
  margin-bottom: 8px;
  text-align: left;
  font-weight: 500;
}

.purchase-history-left p {
  font-size: 13px;
  font-weight: 500;
  color: #3e3f5e;
  text-align: left;
  margin-bottom: 8px;
  line-height: 24px;
}

.purchase-history-left p ins {
  text-decoration: none;
  text-transform: uppercase;
  color: #0157a7;
}

.purchase-history-left span {
  font-weight: 400;
  font-size: 13px;
  color: #3e3f5e;
  text-align: left;
  display: block;
}

.purchase-history-right span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #0157a7;
  text-align: center;
}

.purchase-history-right {
  text-align: center;
}

.purchase-history-right a {
  font-size: 13px;
  font-weight: 500;
  margin-top: 9px;
  display: block;
  color: #2b2f4c;
}

.purchase-history-right a:hover {
  color: #0157a7 !important;
}

/* --- My Addresses --- */

.add-address {
  display: inline-block;
  padding: 4px 10px;
  margin-top: 20px;
  /*margin-left: 20px;*/
  color: #fff;
  background: #0157a7;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 12px;
}

.address-item {
  padding: 20px;
  /*border-bottom: 1px solid #efefef;*/
}

.address-item:last-child {
  /* border-bottom: 0;*/
}

.address-item {
  display: flex;
  align-items: self-start;
  border: 1px dashed #0157a7;
  border-radius: 5px;
  margin-bottom: 10px;
}

.address-icon1 {
  margin-right: 10px;
  background: #0157a7;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.mt-30 {
  margin-top: 30px;
}

.address-icon1 i {
  font-size: 16px;
}

.address-dt-all h4 {
  color: #2b2f4c;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-top: 0;
  margin-bottom: 8px;
}

.address-dt-all p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 0;
  line-height: 24px;
}

.action-btns {
  margin-top: 10px;
}

.action-btns li {
  display: inline-block;
  margin-right: 5px;
}

.action-btns li:last-child {
  margin-right: 0;
}

.action-btn {
  font-size: 16px;
  color: #3e3f5e;
}

.action-btn:hover {
  color: #0157a7 !important;
}

.add-address-form {
  padding: 20px 30px 30px;
}

/* --- Career Page --- */

.default-dt {
  padding: 50px;
  background: #2b2f4c;
  position: relative;
}

.default-dt:before {
  content: "";
  /* background: url(../images/career/default.jpg) no-repeat center;*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  background-size: cover;
  opacity: 0.1;
}

.default_tabs {
  margin-top: 0;
}

.default_tabs .nav-item {
  margin-bottom: 0 !important;
}

.default_tabs .nav-tabs .nav-item.show .nav-link,
.default_tabs .nav-tabs .nav-link.active {
  background: #0157a7;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  background-image: -webkit-linear-gradient(
    left,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
  border-color: inherit !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 5px !important;
}

.default_tabs .nav-tabs {
  border-bottom: 0 !important;
}

.tab_default .nav-link {
  border-top: 0 !important;
  border-bottom: 2px solid transparent !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 500 !important;
  font-family: "Lato", sans-serif !important;
}

.tab_default .nav-link {
  padding: 0.5rem 1.5rem !important;
}

.title129 {
  text-align: center;
}

.title129 h2 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 58px !important;
  color: #fff;
  text-align: center;
  line-height: 30px;
}

.position-link-btn {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-top: 21px;
  font-weight: 500;
  font-size: 13px;
}

.life-gambo {
  padding: 59px 0;
}

.default-title {
  text-align: center;
  padding-bottom: 8px;
}

.default-title h2 {
  font-size: 24px;
  color: #2b2f4c;
  font-weight: 700;
  margin-bottom: 13px;
}

.default-title p {
  line-height: 24px;
  font-weight: 400;
  color: #3e3f5e;
  font-size: 13px;
  margin-bottom: 15px;
}

.dd-content {
  margin-top: 30px;
  width: 100%;
}

.life-slider .owl-nav .owl-prev {
  left: -20px;
  font-size: 20px !important;
  position: absolute;
  top: 42%;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.life-slider .owl-nav .owl-next {
  font-size: 20px !important;
  position: absolute;
  right: -20px;
  top: 42%;
  padding: 4px 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.life-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.life-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.white-bg {
  background: #fff;
}

.benefits-step {
  text-align: center;
  margin-bottom: 56px;
}

.benefit-icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
  background: #0157a7;
  border-radius: 100%;
}

.benefit-link {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #2b2f4c;
  line-height: 24px;
  text-align: center;
}

.benefit-link:hover {
  color: #2b2f4c !important;
}

.benefit-icon i {
  font-size: 30px;
  color: #fff;
  padding: 20px 0;
}

.know-more-link {
  text-align: center;
}

.kmore-btn {
  background: #0157a7;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.testi-item {
  background: #fff;
  padding: 30px 20px;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.qoute-icon {
  height: 50px;
  text-align: center;
  font-size: 24px;
  color: #0157a7;
}

.testo-text {
  text-align: center;
  line-height: 26px;
  font-weight: 400;
  color: #3e3f5e;
}

.team-dt {
  margin-top: 35px;
  text-align: center;
}

.team-avatar img {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.team-avatar {
  display: inline-block;
}

.team-emp-dt h4 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 18px !important;
  text-align: center;
  margin-bottom: 10px;
  color: #2b2f4c;
}

.team-emp-dt p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 0;
  text-align: center;
  line-height: 26px;
}

.testimonial-slider .owl-nav .owl-prev {
  left: -20px;
  font-size: 20px !important;
  position: absolute;
  top: 45%;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  padding: 4px 0 !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-slider .owl-nav .owl-next {
  font-size: 20px !important;
  position: absolute;
  right: -20px;
  top: 45%;
  padding: 4px 0 !important;
  text-align: center;
  height: 30px !important;
  width: 30px !important;
  color: #2b2f4c !important;
  background: #fff !important;
  border-radius: 100% !important;
  border: 1px solid #e5e5e5 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-slider .owl-nav .owl-prev:hover {
  color: #fff !important;
  opacity: 1;
  background: #0157a7 !important;
  border: 1px solid #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.testimonial-slider .owl-nav .owl-next:hover {
  color: #fff !important;
  opacity: 1;
  border: 1px solid #0157a7 !important;
  background: #0157a7 !important;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.07);
}

.jobs-gambo {
  padding: 0;
}

.pd-20 {
  padding: 20px;
}

.job-title-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.job-title-group h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 0;
  color: #2b2f4c;
}

.flter-clear-link {
  margin-left: auto;
  font-weight: 600;
  font-size: 13px;
  color: #3e3f5e;
}

.flter-clear-link:hover {
  color: #0157a7 !important;
}

.all-departments {
  margin-top: 21px;
}

.job-bg1 {
  margin-top: 20px;
  background: #fff;
  border-radius: 3px;
  padding: 25px 30px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  display: flex;
  width: 100%;
  align-items: center;
}

.job-bg1:first-child {
  margin-top: 30px;
}

.job-item h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  color: #2b2f4c;
}

.job-item span {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 24px;
  text-align: left;
}

.job-item span i {
  margin-right: 3px;
}

.arrow-icon {
  font-size: 24px;
  color: #2b2f4c;
  margin-left: auto;
}

.job-main-dt {
  padding-bottom: 25px;
  border-bottom: 1px solid #efefef;
}

.job-main-dt h2 {
  color: #2b2f4c;
  font-size: 24px;
  text-align: left;
  margin-top: 18px !important;
  margin-bottom: 12px;
}

.job-main-dt span {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: #3e3f5e;
}

.job-des-dt142 h4 {
  color: #2b2f4c;
  font-size: 16px;
  text-align: left;
  margin-bottom: 12px;
  margin-top: 21px !important;
}

.job-des-dt142 p {
  color: #3e3f5e;
  font-size: 13px;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 0;
  line-height: 26px;
}

.joby-list-dt {
  list-style: disc;
}

.joby-list-dt li {
  margin-left: 30px;
  margin-bottom: 4px;
}

.joby-list-dt li:last-child {
  margin-bottom: 0;
}

.job-req-dt {
  padding-bottom: 25px;
  border-bottom: 1px solid #efefef;
  margin-top: 30px;
  padding-top: 25px;
  border-top: 1px solid #efefef;
}

.job-req-dt h3 {
  color: #2b2f4c;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.apply-btn {
  background: #0157a7;
  color: #fff;
  padding: 0 20px;
  height: 40px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  border: 0;
  margin-top: 34px;
  margin-bottom: 14px;
}

textarea {
  overflow: auto;
  padding: 10px 15px !important;
}

.ques-142 {
  margin-top: 10px !important;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px !important;
  padding-bottom: 15px;
  border-bottom: 1px solid #efefef;
  color: #2b2f4c;
}

.ui.checkbox label {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #3e3f5e !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #0157a7 !important;
}

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  border-color: #0157a7 !important;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  font-size: 12px !important;
}

.control-label {
  font-size: 13px;
  font-weight: 600;
  color: #2b2f4c;
  margin-bottom: 0.8rem;
}

.custom-file-label {
  padding: 0.375rem 0.75rem;
  font-weight: 500 !important;
  color: #afafaf !important;
  background-color: #fff !important;
  border: 1px solid #efefef !important;
  border-radius: 5px !important;
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Upload Files" !important;
}

.custom-file-label::after {
  height: calc(1.5em + 0.75rem + 1px) !important;
  padding: 0.4rem 0.75rem !important;
  color: #fff !important;
  content: "Upload Files" !important;
  background-color: #0157a7 !important;
  border-radius: 0 5px 5px 0 !important;
}

/* --- About Us --- */

.left-text {
  text-align: left;
}

.about-content p {
  margin-top: 24px !important;
  line-height: 26px;
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  text-align: left;
}

.about-img {
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

.about-img img {
  width: 60%;
}

.about-steps-group {
  padding: 29px 0 59px;
}

.about-step {
  text-align: center;
  padding: 0 20px;
  margin-top: 30px;
}

.about-step-img img {
  height: 60px;
}

.about-step h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2b2f4c;
  margin-top: 20px;
  margin-bottom: 12px;
  text-align: center;
}

.about-step p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  line-height: 26px;
}

.team-item {
  width: 100%;
  border-radius: 5px;
  background: #fff;
  padding: 5px;
  text-align: center;
}

.team-img img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.team-item h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2b2f4c;
  margin-top: 21px;
  margin-bottom: 7px;
}

.team-item span {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 15px;
  display: block;
}

.team-social li {
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 5px;
}

.team-social li:last-child {
  margin-right: 0;
}

.scl-btn {
  font-size: 13px;
  background: #2b2f4c;
  color: #fff;
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  padding: 12px 0;
  text-align: center;
}

.how-order-gambo {
  padding: 0 0 14px;
}

.how-order-steps {
  text-align: center;
  margin-top: 30px;
  padding: 30px;
  background: #fff;
  height: 203px;
  border-radius: 5px;
}

.how-order-steps h4 {
  color: #2b2f4c;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.how-order-icon i {
  width: 70px;
  height: 70px;
  display: inline-block;
  background: #0157a7;
  color: #fff;
  font-size: 30px;
  border-radius: 100%;
  padding: 21px 0;
  text-align: center;
}

/* --- Our Blog --- */

.blog-breadcrumb {
  text-align: center !important;
  width: 100%;
  background: transparent !important;
  align-items: center;
  justify-content: center;
}

.blog-breadcrumb .breadcrumb-item.active {
  color: #fff !important;
}

.blog-breadcrumb .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
}

.blog-breadcrumb .breadcrumb-item a:hover {
  color: rgba(255, 255, 255) !important;
}

.title129 .blog-titlebar-heading {
  font-size: 24px;
  font-weight: 700;
  margin-top: 36px !important;
  color: #fff;
  text-align: center;
  line-height: 30px;
}

.top-post-link {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  line-height: 22px;
  margin-bottom: 7px;
}

.top-post-link:hover {
  color: #0157a7 !important;
}

.top-posts li {
  padding: 12px 20px;
  border-bottom: 1px solid #efefef;
}

.top-posts li:last-child {
  border-bottom: 0;
}

.cntct-social {
  padding: 20px 20px 0;
}

.blog-gambo {
  padding: 65px 0 14px;
}

.blog-item {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

.blog-img {
  width: 100%;
  padding: 5px 5px 0;
  position: relative;
  display: block;
}

.blog-img img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.blog-cate-badge {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 15px;
  left: 15px;
  color: #fff;
  background: #0157a7;
  padding: 5px 10px;
  border-radius: 3px;
}

.date-icons-group {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #efefef;
}

.like-share-icons li {
  display: inline-block;
  margin-right: 15px;
}

.like-share-icons li:last-child {
  margin-right: 0;
}

.like-share-icons {
  margin-left: auto;
}

.blog-time {
  font-size: 12px;
  font-weight: 500;
  color: #b2b2b2;
  text-align: left;
}

.sz-14 {
  font-size: 13px !important;
}

.like-share {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
}

.like-share span {
  margin-left: 5px;
  color: #2b2f4c;
}

.like-share:hover {
  color: #0157a7 !important;
}

.blog-detail h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 14px;
  text-align: left;
  color: #2b2f4c;
}

.blog-detail {
  padding: 20px;
}

.blog-detail p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  margin-bottom: 12px;
  text-align: left;
}

.blog-detail a {
  font-size: 16px;
  font-weight: 500;
  color: #0157a7;
  text-align: left;
}

.blog-detail a:hover {
  color: #0157a7 !important;
  text-decoration: underline !important;
}

.blog-btn {
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  height: 40px;
  padding: 11px 20px;
  border-radius: 5px;
  color: #fff;
  background: #0157a7;
}

.blog-more-btn {
  text-align: center;
  padding-top: 10px;
}

/* --- Blog sidebar --- */

.pdpt-bg-left {
  margin-top: 30px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  float: left;
  width: 100%;
}

.blog-search-widget {
  padding: 20px;
  float: left;
  width: 100%;
}

.blog-from {
  position: relative;
}

.blog-search-widget .form-control {
  font-size: 13px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  color: #2b2f4c;
  border: 1px solid #efefef;
  position: relative;
  padding: 0 3rem 0 1rem;
  height: 40px;
}

.blog-search-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 120%;
  width: 40px;
  height: 40px;
  border: 0;
  background: transparent;
}

.top-posts {
  float: left;
  width: 100%;
}

.top-posts li {
  float: left;
  width: 100%;
}

.blog-top-item {
  float: left;
  width: 100%;
}

.post-cate-link {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #2b2f4c;
  line-height: 26px;
  margin-bottom: 5px;
  float: left;
}

.cate-count {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #8f91ac;
  line-height: 26px;
  margin-bottom: 0px;
  float: right;
}

.top-posts .media-list {
  padding: 20px !important;
}

.top-posts .media-list .media {
  padding: 0;
  border: none;
  border-bottom: 0;
  float: left;
  width: 100%;
}

.media-list .media .media-left {
  padding-right: 1rem;
  float: left;
}

.icon-bg-circle {
  color: #fff;
  padding: 0;
  border-radius: 3px;
  width: 70px;
  height: 70px;
  border: 0;
  object-fit: cover;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.tagcloud {
  padding-bottom: 15px;
}

.tagcloud a,
.tagcloud .tag-cloud-link {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.375rem 0.5625rem;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
  border: 1px solid #efefef;
  border-radius: 0.1875rem;
  color: #2b2f4c;
  font-size: 13px !important;
  white-space: nowrap;
  line-height: 1.5;
}

.pagination-area .page-numbers {
  width: 33px;
  height: 33px;
  background-color: #fff;
  border: 1px solid #efefef;
  color: #606060;
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  line-height: 34px;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 13px;
  font-weight: 500;
}

.pagination-area .page-numbers.current,
.pagination-area .page-numbers:hover {
  background: #0157a7;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  background-image: -webkit-linear-gradient(
    left,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
  background-image: linear-gradient(
    to right,
    rgba(230, 92, 91, 0.9),
    rgba(245, 93, 44, 0.9)
  );
}

/* --- Blog Detail View --- */

.blog-dt-vw {
  height: 80vh;
  display: table;
  text-align: center;
  width: 100%;
  background-position: 40% 0px;
  transition: 0s linear;
  transition-property: background-position;
}

.banner-blog {
  /*background: url(../images/blog/img-1.jpg) 40% 0px / cover no-repeat;*/
}

.banner.visible {
  opacity: 1;
}

.parallax {
  background-position: 40% 0px;
}

.blog-dt-vw .blog-inner {
  display: table-cell;
  color: #fff;
  font-weight: 500;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.6);
}

.blog-dt-vw .blog-inner h1 {
  font-size: 40px;
  font-weight: bold;
  margin: 0 auto 10px;
  max-width: 750px;
  line-height: 1.3em;
  padding: 0 15px;
}

.blog-inner .extra-info {
  font-size: 13px;
}

.entry-date {
  font-weight: 500;
}

.single-post-cat {
  margin-top: 20px;
}

.single-post-cat a {
  color: #fff;
  background: #0157a7;
  padding: 5px 10px 4px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  margin: 0 2px;
  line-height: 24px;
  display: inline-block;
}

.blog-des-dt142 p {
  color: #3e3f5e;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
  line-height: 26px;
}

.blog-des-dt142 {
  margin-top: 42px;
}

.mt-21 {
  margin-top: 21px;
}

.mb-21 {
  margin-bottom: 21px;
}

.ss18 {
  font-size: 18px !important;
}

.leave-comment {
  margin-top: 35px;
}

.leave-comment h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  margin-bottom: 13px;
  color: #2b2f4c;
}

.leave-comment span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 28px;
  text-align: left;
  line-height: 26px;
}

.ptt {
  padding: 30px;
}

.all-comment {
  margin-top: 35px;
  padding-bottom: 41px;
  border-bottom: 1px solid #efefef;
}

.all-comment h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  width: 100%;
  margin-bottom: 13px;
  color: #2b2f4c;
}

.cmmnt_item {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  margin-top: 30px;
}

.cmmnt_item:last-child {
  border-bottom: 0;
}

.cmmnt_usr_dt {
  display: flex;
  position: relative;
}

.cmmnt_usr_dt img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.tutor_name1 {
  font-size: 16px;
  font-weight: 700;
  color: #2b2f4c !important;
  text-align: left;
  margin-bottom: 0;
}

.time_145 {
  font-size: 12px;
  font-weight: 500;
  color: #b1b1b1;
  display: block;
  margin-top: 5px;
}

.rvds10 {
  font-size: 13px;
  font-weight: 400;
  margin-top: 17px;
  line-height: 26px;
  color: #3e3f5e;
  text-align: left;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "roboto", sans-serif !important;
}

textarea {
  border: 1px solid #efefef !important;
}

.form-control {
  color: #2b2f4c !important;
  font-weight: 400 !important;
}

.post-btn {
  height: 40px;
  border: 0;
  padding: 0 20px;
  color: #fff;
  background: #0157a7;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 13px;
}

.fcrse_3 {
  background: #fff;
  width: 100%;
  padding: 0;
  border-radius: 5px;
  margin-bottom: 30px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

.cater_ttle {
  float: left;
  width: 100%;
  padding: 15px 20px;
}

.fcrse_3 h4 {
  font-size: 16px;
  color: #333;
  float: left;
  font-weight: 500;
  margin-bottom: 0;
}

.allcate15 {
  width: 100%;
}

.allcate15 li {
  display: block;
  width: 100%;
}

.blogleft12 li {
  display: block;
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.blogleft12 li:last-child {
  border-bottom: 0;
}

/* --- Press Page --- */

.ct_item {
  width: 100%;
  padding: 10px 20px;
  font-size: 13px;
  color: #686f7a;
  text-align: left;
}

.ct_item.active {
  font-weight: 600;
  color: #2b2f4c;
}

.ct_item i {
  margin-right: 10px;
  font-size: 120%;
}

.category-topics {
  border-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0 !important;
  text-align: left;
  color: #2b2f4c;
  display: block;
  padding: 15px 20px;
}

.category-topics:after {
  content: "\f0d7";
  font-size: 13px;
  font-family: "FontAwesome";
  float: right;
  margin-left: 30px;
  color: #a7a8aa;
}

.category-item1 {
  border-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0 !important;
  text-align: left;
  color: #3e3f5e;
  display: block;
  padding: 10px 20px;
}

.socl148 {
  padding: 20px;
  display: flex;
}

.twiter158 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #fff;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 15px;
  width: 50%;
  margin-right: 10px;
  background: #1da1f2;
}

.facebook158 {
  font-size: 13px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #fff;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 15px;
  width: 50%;
  background: #3b5998;
}

.ic45 {
  margin-right: 7px;
}

.help_link {
  padding: 15px 20px;
  text-align: center;
}

.help_link a {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  text-align: center;
}

.help_link a:hover {
  color: #0157a7 !important;
}

.press-title h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px !important;
  text-align: left;
  color: #2b2f4c;
  line-height: 24px;
}

.press-title p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0 !important;
  text-align: left;
  color: #3e3f5e;
  line-height: 24px;
}

.press-title p a {
  color: #0157a7;
}

.press-title p a:hover {
  color: #0157a7 !important;
  text-decoration: underline !important;
}

.press-item {
  display: block;
  background: #fff;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

.press-item span {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0 !important;
  text-align: left;
  color: #b1b1b1;
}

.press-item h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  text-align: left;
  color: #2b2f4c;
  line-height: 24px;
}

.press-item p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0 !important;
  text-align: left;
  color: #3e3f5e;
  line-height: 26px;
}

.policy-des-dt {
  margin-bottom: 30px;
  padding-bottom: 30px;
  /*border-bottom: 1px solid #e1e1e1;*/
}

.policy-des-dt:last-child {
  margin-bottom: 7px;
  padding-bottom: 0;
  border-bottom: 0;
}

/* --- Sign In --- */

.sign-inup {
  padding: 80px 0;
}

.sign-form {
  display: block;
  width: 100%;
}

.sign-img {
  background: #4576b4;
  padding: 50px;
  border-radius: 5px 0 0 5px;
}

.sign-img img {
  width: 100%;
}

.sign-inner {
  padding: 0;
}

.sign-logo {
  width: 100%;
  text-align: center;
}

.sign-logo img {
  width: 130px;
}

.form-dt {
  margin-top: 15px;
  border: 1px solid #efefef;
  position: relative;
  border-radius: 0px;
  background: #0157a7;
}
.form-dt small {
  line-height: normal;
  color: #ffffff;
}
.form-dt p {
  color: #ffffff;
}
.resend-link a {
  color: #ffffff;
}
.form-dt label.control-label {
  color: #ffffff;
}
.form-inpts {
  padding: 38px 30px 0;
}

.form-title {
  text-align: center;
  margin-bottom: 15px;
}

.form-title h6 {
  font-weight: 700;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.lgn_input {
  padding: 0 1rem 0 3rem !important;
}

.pos_rel {
  position: relative;
}

.lgn_icon {
  position: absolute;
  top: 10px;
  left: 2%;
  font-size: 18px;
  color: #2b2f4c;
}

.form-control:focus + .lgn_icon {
  color: #0157a7;
}
.login-phone .form-control {
  font-size: 13px;
}

.login-btn {
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  height: 40px;
  border: 0;
  color: #0157a7;
  background: #ffffff;
  border-radius: 5px;
  text-align: center;
  width: 150px;
  margin: 0 auto;
  display: table;
  text-transform: uppercase;
}

.signup-link {
  margin-top: 15px;
  border-radius: 0 0 5px 5px;
  text-align: center;
  padding: 15px 10px;
  background: #01488e;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  /*background-image: -webkit-linear-gradient(left, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));
    background-image: linear-gradient(to right, rgba(230, 92, 91, 0.9), rgba(245, 93, 44, 0.9));*/
}
.copyright-text.text-center.mt-3 {
  text-align: center !important;
  margin: 0 auto !important;
  display: table;
}
.form-dt .form-check.mt-1 {
  margin: 0 auto;
  display: table;
  text-align: center;
  float: none;
}

.signup-link p {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}

.signup-link p a {
  color: #0157a7;
  font-size: 12px;
  background: #fff;
  padding: 7px 10px;
  border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  text-transform: uppercase;
}

.signup-link p a:hover {
  color: #0157a7 !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.password-forgor {
  text-align: center;
}

.password-forgor a {
  display: block;
  margin-top: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
}

.password-forgor a:hover {
  color: #ffffff !important;
  text-decoration: underline;
}

/* --- Contact Us --- */

.panel-group {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 14px;
}

.panel-title {
  vertical-align: middle;
}

.panel-default {
  width: 100%;
  border-top: 0;
}

.panel-default:first-child {
  border-top: 0;
}

.panel-body {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  padding-bottom: 20px;
  text-align: left;
  padding: 20px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #efefef;
}

.panel-default {
  width: 100%;
  border-top: 0;
}

.panel-title {
  vertical-align: middle;
}

.panel-title a {
  font-size: 16px;
  font-weight: 500;
  color: #2b2f4c !important;
  padding: 15px 20px 18px;
  display: block;
  line-height: 26px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}

.panel-title a.collapsed {
  border-radius: 5px;
}

.chck_icon {
  color: #0157a7;
  margin-right: 10px;
}

.panel-title > a:before {
  float: right !important;
  /*font-family: "Poppins", sans-serif;*/
  content: "\f058";
  padding-right: 0px;
  font-size: 16px;
  padding-top: 2px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.panel-title > a.collapsed:before {
  float: right !important;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

.color-pink {
  color: #0157a7;
}

.contact-title h2 {
  font-size: 24px;
  font-weight: 700;
  margin-top: 17px !important;
  text-align: left;
  margin-bottom: 12px;
  color: #2b2f4c;
}

.contact-title p {
  font-size: 13px;
  font-weight: 400;
  margin-top: 0 !important;
  text-align: left;
  margin-bottom: 0;
  color: #3e3f5e;
  line-height: 26px;
}

.contact-form {
  background: #fff;
  border-radius: 5px;
  padding: 20px 30px 30px;
  margin-top: 34px;
  margin-bottom: 14px;
}

/* --- Product Request --- */

.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #fff;
  background: #0157a7;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 3px;
  line-height: 26px;
}

.bootstrap-tagsinput {
  border: 1px solid #efefef !important;
  box-shadow: none !important;
  width: 100%;
  height: auto;
  text-align: left;
}

.bootstrap-tagsinput input {
  padding: 7px 10px !important;
  font-weight: 500;
}

.request-products {
  margin-top: 30px;
}

.rqst-btn {
  width: 100%;
}

.request-grid {
  margin-top: 64px;
}

/* --- Faq Page --- */

.faq-dt {
  padding: 50px;
  background: #2b2f4c;
  position: relative;
}

.faq-dt:before {
  content: "";
  /*background: url(../images/career/default.jpg) no-repeat center;*/
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  background-size: cover;
  opacity: 0.1;
}

.panel-body p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  margin-bottom: 0;
}

/* --- Offers Page --- */

.offers-item {
  display: block;
  margin-top: 30px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 1px 2px 0 #e9e9e9;
}

.offer-img img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.offers-text {
  padding: 20px 15px;
}

.offers-text h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 13px;
  text-align: left;
  color: #2b2f4c;
}

.offers-text p {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  line-height: 26px;
  text-align: left;
}

.mb-14 {
  margin-bottom: 14px;
}

/* --- Bill Page --- */

.report-btn {
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  background: #0157a7;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
}

.bill-dt-bg {
  padding-top: 60px;
}

.bill-detail {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 #e9e9e9;
  margin: 65px 0;
}

.bill-dt-step {
  padding: 30px;
  border-bottom: 1px solid #efefef;
}

.bill-dt-step:last-child {
  border-bottom: 0;
}

.bill-title h4 {
  margin-bottom: 20px !important;
  font-size: 18px;
  font-weight: 700;
  color: #2b2f4c;
}

.itm-ttl {
  display: block;
  font-weight: 500;
  font-size: 16px;
  text-align: left;
  margin-bottom: 10px;
  color: #2b2f4c;
}

.item-prdct {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  text-align: left;
  line-height: 24px;
  margin-right: 10px;
}

.item-prdct:last-child {
  margin-right: 0;
}

.item-prdct:after {
  content: ",";
  padding-left: 3px;
}

.item-prdct:last-child:after {
  content: "";
}

.bill-address {
  font-size: 13px;
  font-weight: 400;
  color: #3e3f5e;
  margin-bottom: 0;
  line-height: 26px;
}

.bill-dt-sl {
  font-size: 13px;
  font-weight: 400;
  color: #2b2f4c;
  margin-bottom: 0;
  line-height: 26px;
}

.descp-bll-dt {
  color: #8f91ac;
}

.dly-loc {
  color: #3e3f5e;
}

.dlr-ttl25 {
  color: #0157a7;
}

.bill-bottom {
  display: flex;
  align-items: center;
}

.thnk-ordr {
  font-size: 13px;
  font-weight: 500;
  color: #2b2f4c;
  margin-bottom: 0;
  line-height: 26px;
}

.print-btn {
  margin-left: auto;
  padding: 9px 20px;
  border: 1px solid #0157a7;
  background: #0157a7;
  color: #fff;
  border-radius: 5px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  display: inline-block;
}

/**** New CSS ****/

.bx-blk img {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.bx-blk {
  background: #0157a7 !important;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  z-index: 9;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  top: 0px;
}

.bx-blk i {
  font-size: 30px;
  float: left;
}

.banner ul {
  margin-top: 20px;
  padding-left: 0px;
}

.banner li {
  display: inline-block;
  font-size: 0.7em;
  list-style-type: none;
  padding: 0.3em;
  text-transform: uppercase;
  color: #064b73;
  width: 50px;
  text-align: center;
}

.banner li span {
  display: block;
  font-size: 1rem;
  color: #fff;
  background: #064b73;
  border-radius: 10px;
  line-height: 36px;
  padding: 0px 10px;
}
.offer-blk i {
  font-size: 1.4rem;
}
.offer-blk {
  display: inline-block;
  /*background: #0157a7;*/
  padding: 2px 20px;
  margin-left: 15px;
  border-radius: 5px;
  line-height: 36px;
  border: 1px solid #0157a7;
}
.buy-blk {
  width: 100%;
  margin-bottom: 15px;
}

.rate {
  height: 25px;
  text-align: center;
  margin: 0 auto;
  display: none;
}
.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 15px;
  color: #ffc700;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.rate-left {
  height: auto;
  text-align: left;
  display: inline-flex;
}
.rate-left:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate-left:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 15px;
  color: #ffc700;
}
.rate-left:not(:checked) > label:before {
  content: "\2605";
}
.rate-left > input:checked ~ label {
  color: #ffc700;
}
.rate-left:not(:checked) > label:hover,
.rate-left:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate-left > input:checked + label:hover,
.rate-left > input:checked + label:hover ~ label,
.rate-left > input:checked ~ label:hover,
.rate-left > input:checked ~ label:hover ~ label,
.rate-left > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.rate-block {
  float: left;
  height: 50px;
  padding: 0 10px;
}
.rate-block:not(:checked) > input {
  position: absolute;
  top: -9999px;
}
.rate-block:not(:checked) > label {
  float: right;
  width: 1.2em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 24px;
  color: #ccc;
}
.rate-block:not(:checked) > label:before {
  content: "★ ";
}
.rate-block > input:checked ~ label {
  color: #ffc700;
}
.rate-block:not(:checked) > label:hover,
.rate-block:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate-block > input:checked + label:hover,
.rate-block > input:checked + label:hover ~ label,
.rate-block > input:checked ~ label:hover,
.rate-block > input:checked ~ label:hover ~ label,
.rate-block > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.section145 .cat-blk {
  /*background: #fff;*/
  border-radius: 15px;
  /*padding: 30px 15px;*/
}

.text-left {
  text-align: left;
}
.bg-white {
  background: #eaeded;
  border-radius: 5px;
  border: 1px solid #eaeded;
}
.bg-light {
  background: #eaeded;
}
.border-radius-15 {
  border-radius: 5px;
}
.small-prd-wrap {
  width: 100%;
  border: 1px solid #cccccc;
}
.small-prd-wrap img {
  width: 100%;
  border: 1px solid #eaeded;
}
.padding-15 {
  padding: 15px;
}
.padding-30 {
  padding: 30px;
}
.bg-white h3 {
  font-size: 15px;
  padding-left: 15px;
  font-weight: 600;
  width: 100%;
  height: 30px;
}
.bg-white h4 {
  font-size: 15px;
  padding-left: 15px;
  font-weight: 600;
  width: 100%;
  height: 30px;
}
.bg-white span {
  font-size: 11px;
  text-align: center;
  font-weight: 400;
  margin-top: 5px;
  height: 30px;
  line-height: normal;
}
.owl-theme .owl-nav {
  margin-top: 0px !important;
}
.mb-15 {
  margin-bottom: 30px;
}
.mt-15 {
  margin-top: 15px;
}
.section145.padd .col-lg-3.col-md-6 {
  padding-left: 12px;
  padding-right: 12px;
}
.bg-white .col-lg-6.col-md-6 {
  padding-left: 7px;
  padding-right: 7px;
}
.foot-logo {
  padding-top: 50px;
  vertical-align: middle;
}
.foot-logo img {
  filter: grayscale(1);
  width: 150px;
  /*margin: 0 auto;*/
  text-align: center;
  display: table;
  margin-bottom: 15px;
}

.blk-2 {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  column-gap: 10px;
}
.footer-bottom-links {
  display: inline-block;
}

.product-list-view .col-lg-2.col-md-2.col-sm-3.col-xs-6.mb-15 {
  padding-left: 10px;
  padding-right: 10px;
}

.card {
  background-color: #fff;
  padding: 10px;
  border: none;
  height: 400px;
  position: relative;
  cursor: pointer;
}

.product-image img {
  position: absolute;
  top: -50px;
  width: 400px;
  transition: all 0.5s;
}

.card:hover .product-image img {
  transform: rotate(10deg);
}

label.radio {
  cursor: pointer;
}

label.radio input {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  pointer-events: none;
}

label:nth-child(1) span {
  padding: 12px;
  border-radius: 50%;
  border: 2px solid gray;
  display: inline-block;
  background-color: gray;
  text-transform: uppercase;
  position: relative;
  transition: all 2s;
}

label:nth-child(2) span {
  padding: 12px;
  border-radius: 50%;
  border: 2px solid #000;
  background-color: #000;
  display: inline-block;
  color: #8f37aa;
  text-transform: uppercase;
  position: relative;
  transition: all 2s;
}

label:nth-child(3) span {
  padding: 12px;
  border-radius: 50%;
  border: 2px solid pink;
  background-color: pink;
  display: inline-block;
  color: #8f37aa;
  text-transform: uppercase;
  position: relative;
  transition: all 2s;
}

label.radio input:checked + span::after {
  content: "\e8d9";

  position: absolute;
  left: 2px;
  top: 2px;
  font-size: 20px;
  color: #fff;
  font-family: "unicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
}

.button {
  width: 200px;
  height: 48px;
  border: none;
  border-radius: 30px;
  position: absolute;
  bottom: 20px;
  background-color: #126ef9;
  color: #fff;
}

.button i {
  margin-top: 1px;
}

@media (max-width: 770px) {
  .product-image img {
    position: absolute;
    top: 130px;
    right: 10px;
    width: 250px;
  }
}

@media (max-width: 470px) {
  .product-image img {
    position: absolute;
    top: 190px;
    right: 10px;
    width: 150px;
  }

  .address-placed-dt1 p span {
    font-size: 12px;
  }
}

.color-img {
  width: 100%;
  display: inline-block;
}

.color-img ul {
  margin: 0px;
  padding: 0px;
}
.color-img ul li {
  list-style: none;
  display: inline-flex;
  border: 1px solid #ddd;
}

.product-now li img {
  width: 100%;
  border: 2px solid #fff;
}
.side h2 {
  margin-top: 15px !important;
  text-align: center;
}
.border-1 {
  border: 1px solid #ddd;
}

.product-specs {
  width: 100%;
  margin: 15px;
  padding: 30px;
}

.product-specs .product-specs-list li {
  position: relative;
  width: 50%;
  float: left;
  line-height: 28px;
  list-style: none;
  margin-left: 0;
}
.product-prop {
  display: inline-block;
  max-width: 50%;
}
.two-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-limit-length {
  white-space: nowrap;
}
.img-rounded {
  border-radius: 50%;
}
.product-specs .property-title {
  color: #999;
  font-size: 13px;
  padding-right: 15px;
}
.nav-link {
  display: block !important;
  padding: 1.2rem 1rem;
  font-weight: 600 !important;
}
.nav-tabs .nav-item a {
  color: #064b73 !important;
  text-transform: uppercase;
}
a .nav-link {
  color: #064b73 !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 2px solid #0157a7 !important;
}

.nav-tabs .nav-link {
  border: 0px solid transparent !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

/**** User review ***/

.btn-grey {
  background-color: #d8d8d8;
  color: #fff;
}
.rating-block {
  background-color: #fafafa;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 30px;
  margin: 15px;
}
.bold {
  font-weight: 700;
}
.padding-bottom-7 {
  padding-bottom: 7px;
}

.review-block {
  background-color: #fafafa;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 30px;
  margin: 15px;
}
.review-block-name {
  font-size: 12px;
  margin: 10px 0;
}
.review-block-date {
  font-size: 10px;
}
.review-block-rate {
  font-size: 13px;
  margin-bottom: 15px;
}
.review-block-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.review-block-description {
  font-size: 13px;
}

.heading {
  font-size: 25px;
  margin-right: 25px;
}

.fa {
  font-size: 25px;
}

.checked {
  color: orange;
}

/* Three column layout */
.side-blk {
  float: left;
  width: 12%;
  margin-top: 5px;
}

.middle {
  margin-top: 10px;
  float: left;
  width: 76%;
}

/* Place text to the right */
.right {
  text-align: left;
  padding-left: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The bar container */
.bar-container {
  width: 100%;
  background-color: #f1f1f1;
  text-align: center;
  color: white;
}

/* Individual bars */
.bar-5 {
  width: 60%;
  height: 5px;
  background-color: #04aa6d;
}
.bar-4 {
  width: 30%;
  height: 5px;
  background-color: #2196f3;
}
.bar-3 {
  width: 10%;
  height: 5px;
  background-color: #00bcd4;
}
.bar-2 {
  width: 4%;
  height: 5px;
  background-color: #ff9800;
}
.bar-1 {
  width: 15%;
  height: 5px;
  background-color: #f44336;
}

/* Responsive layout - make the columns stack on top of each other instead of next to each other */
@media (max-width: 400px) {
  .side,
  .middle {
    width: 100%;
  }
  /*.right {
    display: none;
  }*/
}

.img-blk {
  width: 100%;
}

.img-blk img {
  width: 100%;
}
.green {
  color: #47dd09 !important;
  font-size: 1.7rem;
}
.red {
  color: #0157a7 !important;
  font-size: 1.7rem;
}
.checout-step .form-group {
  margin-bottom: 18px;
  display: inline-block;
  width: 100%;
}
.right-cart-dt-body {
  width: 100%;

  overflow-y: scroll;
  height: 300px;
}
.side {
  /* display: table; */
  /* float: left; */
  text-align: left;
  /* padding-right: 15px; */
  width: 75px;
}
.side1 {
  /* display: table; */
  /* float: left; */
  text-align: left;
  /* padding-right: 15px; */
  width: auto;
}

.ins {
  width: 100%;
  text-align: left;
  background: #0157a7;
  padding: 10px 30px;
  color: #fff;
}
.ins h6 {
  margin-bottom: 0px;
}

.signup-link1 p a {
  color: #fff;
  font-size: 12px;
  background: #0157a7;
  padding: 7px 10px;
  border-radius: 3px;
  display: inline-block;
  margin-left: 5px;
  text-transform: uppercase;
}
.signup-link1 {
  margin-top: 15px;
  border-radius: 0 0 5px 5px;
  text-align: center;
  padding: 15px 10px;

  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.signup-link1 p a:hover {
  color: #fff !important;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}
.map-responsive {
  overflow: hidden;

  padding-bottom: 56.25%;

  position: relative;

  height: 0;
  margin-bottom: 15px;
}

.map-responsive iframe {
  left: 0;

  top: 0;

  height: 100%;

  width: 100%;

  position: absolute;
}
.action-btns li i {
  font-size: 20px;
}
.action-btns li a {
  font-size: 12px;
}
.action-btns li {
  font-size: 12px;
}
.order-details .address-item {
  border-bottom: 0px;
}
.bg-green {
  background: #cdf9da;
}

.rating-form {
  width: 100%;
  padding: 20px;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 4px 8px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.cart-add {
  position: absolute;
  bottom: 10px;
  right: 0;
  background: transparent;
  border: 0;
  font-size: 18px;
  color: #0157a7;
}
.cart-add a {
  color: #0157a7;
}

.default-card {
  position: absolute;
  bottom: 0px;
  right: 0;
  background: transparent;
  border: 0;
  font-size: 12px;
  color: #0157a7;
}
.default-card a {
  color: #0157a7;
}
.returns-blk {
  width: 100%;
}
.returns {
  width: 40%;
  text-align: center;
  margin: 0 auto;
  display: table;
}
.returns img {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.returns-blk h4 {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 50px;
}
.returns small {
  text-align: center;
  float: none;
}

/***** New Css *****/

.main-banner-slider .container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.cate-img img {
  width: 100%;
  border-radius: 5px;
}
.cart-text h4 a {
  color: #2b2f4c;
}
.cart-text h4 {
  padding-right: 20px;
}
.order-dt47 h4 a {
  color: #2b2f4c;
  display: none;
}
.product-now li i {
  font-size: 20px;
}
.contact img {
  width: 100%;
}
.ordr-crt-share .order-btn a {
  color: #fff;
}
.ordr-crt-share .add-cart-btn a {
  color: #fff;
}
.login-btn.hover-btn a {
  color: #000000 !important;
}
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 10px 0 40px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 12px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png")
    6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}
.social-log {
  width: 100%;
  margin: 0 auto;
  display: table;
  text-align: center;
  margin-top: 10px;
}

/*** Save Card Css ***/

label {
  width: 100%;
}
.card-input-element + .card {
  height: calc(30px + 2 * 1rem);
  color: #29292d;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 4px;
}

.card-input-element + .card:hover {
  cursor: pointer;
}

.card-input-element:checked + .card {
  border: 2px solid #47dd09;
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}

.card-input-element:checked + .card::after {
  content: "\e8d7";
  color: #47dd09;
  font-family: "unicons";
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.card.card-body img {
  width: 50px;
}
.ui.input.col-3 {
  padding: 0px !important;
  font-size: 0.9em;
}
#cvv1 {
  display: none;
}
#cvv2 {
  display: none;
}
#add-card {
  display: none;
}

.address-fieldset .login-phone::after {
  top: 52px !important;
  font-size: 16px;
}
.address-fieldset .lgn_icon {
  top: 42px !important;
  position: absolute;
  left: 15px;
}
.form-control {
  border: 1px solid #ced4da !important;
  height: calc(2em + 0.75rem + 2px);
}
.product-left-title h4 {
  font-size: 18px;
}

.empty-cart {
  width: 100%;
  padding: 15px;
}
.empty-cart img {
  width: 75%;
  margin: 0 auto;
  display: table;
}
.start-btn {
  display: table;
  margin-bottom: 0;
  margin: 0 auto;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 13px;
  color: #fff;
  background: #0157a7;
  font-weight: 500;
  cursor: pointer;
}
.start-btn a {
  color: #fff;
  text-transform: uppercase;
}

.empty-prd {
  width: 100%;
  padding: 30px;
  display: table;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 50px;
}
.empty-prd h2 {
  font-size: 18px;
}
.empty-prd img {
  width: 30%;
}
.continue-btn {
  display: table;
  margin-bottom: 0;
  margin: 0 auto;
  padding: 10px 15px;
  border-radius: 30px;
  font-size: 13px;
  color: #fff;
  background: #0157a7;
  font-weight: 500;
  cursor: pointer;
  margin-top: 15px;
}
.empty-prd h2 {
  margin: 0px;
}
.continue-btn a {
  color: #fff;
  display: block;
}
.empty-prd h3 {
  text-align: center;
  margin-top: 0px;
}

/*.order-dt47 {
margin-left: 15px;
overflow: hidden;
max-width: 150px;
text-overflow: ellipsis;
white-space: nowrap;
}*/
.product-now li.attr-wd {
  width: auto !important;
  /*min-width: 80px!important;*/
}
.product-now li.attr-wd label {
  position: static;
  padding: 2px 5px;
}

.product-now li.attr-image {
  min-width: 50px !important;
  min-height: 50px !important;
}
.empty-cart {
  padding-top: 0px;
}

.wrapper.page-blk {
  background: #fff;
}
.wrapper.page-blk .product-item {
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 1px solid #eaeded;
}
.filter-items label {
  display: inline;
}

.filter-bx {
  position: sticky;
  top: 46px;
  z-index: 99;
  background: #ffffff;
  width: 100%;
  text-align: center;
  line-height: 40px;
  border-bottom: 1px solid #e7eded;
  overflow: auto;
}
.filter-bx a {
  color: #0157a7;
}
.left-blk {
  float: left;
  padding-left: 15px;
}
.right-blk {
  float: right;
  padding-right: 15px;
}
.bg-white .col-lg-3.col-md-3 {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.checkout-atr-list li {
  border: 0px !important;
  line-height: 1.5rem;
  margin: 0 5px;
  clear: both;
  font-size: 11px;
  font-weight: 400;
  background: #eaeded;
  border-radius: 3px;
  min-width: 60px;
  height: 30px;
  overflow: hidden;
}
.empty-cart h3 {
  margin-top: 0px;
  text-align: center;
}
.main-banner-slider .slick-prev {
  left: 20px !important;
}
.main-banner-slider .slick-next {
  right: 30px !important;
}
.cate-img h5 {
  margin-top: 5px;
}

.mob-fil-blk .bs-canvas-right {
  left: auto !important;
  margin-left: 0px;
}

.mob-fil-blk .position-fixed {
  position: static !important;
}

.mob-fil-blk .bs-canvas {
  width: auto;
  top: auto;
}

.bs-canvas.bs-canvas-right.position-fixed.bg-cart.h-100 {
  height: auto !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.order-title h6 {
  padding-left: 15px;
  padding-bottom: 6px;
  color: #504159;
  font-weight: 500;
}

.success_page {
  text-align: center;
}
.success_page img {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  display: table;
}
.delivery-address-bg p {
  font-size: 14px;
}
.failed {
  color: #e8505b !important;
}
a.category-item {
  color: #000 !important;
}
.wrapper.page-blk .cat-blk {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pdpt-bg a.add-address.hover-btn {
  margin-left: 20px;
}
.col-lg-12.col-md-12.col-sm-12.col-xs-12.mb-15 {
  text-align: center;
}
.policy-des-dt li {
  color: #3e3f5e;
  font-size: 13px;
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 10px;
  font-weight: 400;
  text-align: justify;
}
.ui.form {
  padding: 15px 0px 30px 0px;
}

.css-4d550c-container {
  position: relative;
  box-sizing: border-box;
  height: calc(1.5em + 0.75rem + 6px);
  z-index: 9;
  width: 98%;
  margin: 0 auto;
}
.map-responsive {
  /* margin-top: -65px;*/
}
.magnify-container_1 {
  width: 100% !important;
}

/** Slide**/

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #0157a7 !important;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("../images/left-chevron.png");
  left: 10px;
  right: auto;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("../images/right-chevron.png");
  padding: 20px;
  border-radius: 30px;
  right: 10px;
  left: auto;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
}
#Search {
  width: 100%;
  border: 3px solid black;
  padding-left: 15px;
  padding-top: 1px;
  font-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
  outline: 0;
}

#Search::-webkit-search-cancel-button {
  position: relative;
  right: 20px;

  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background-image: url("../images/close.png");
}

.cl-start-wrap {
  list-style: none;
  padding: 0;
}
.cl-start-wrap ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.cl-start-wrap > li > .cl-label-wrap {
  padding: 7px 0 7px 0px;
  margin: 1px 0;
  background: #dadada;
}
.cl-start-wrap > li > .cl-label-wrap:hover {
  background: #cdcdcd;
}
.cl-start-wrap > li > ul > li > .cl-label-wrap {
  padding: 7px 0 7px 25px;
  background: #ffffff;
}
.cl-start-wrap > li > ul > li > .cl-label-wrap:hover {
  background: #f2f2f2;
}
.cl-start-wrap > li > ul > li > ul > li > .cl-label-wrap {
  padding: 7px 0 7px 50px;
  background: #ffffff;
}
.cl-start-wrap > li > ul > li > ul > li > .cl-label-wrap:hover {
  background: #f2f2f2;
}

.cl-item > .cl-label-wrap .cl-label-title {
  cursor: pointer;
}
.cl-item > .cl-label-wrap .cl-label-icon {
  cursor: pointer;
  text-align: center;
}
.cl-item > .cl-label-wrap .cl-label-icon:before {
  cursor: pointer;
  display: inline-block;
  font: normal normal normal 14px/1 "unicons";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.cl-item > .cl-label-wrap .cl-label-tools a {
  color: #9e9e9e;
}
.cl-item > .cl-label-wrap .cl-label-tools a:hover {
  color: #858585;
}

.cl-item:not(.cl-item-open) > ul {
  display: none;
}
.cl-item:not(.cl-item-open) > .cl-label-wrap .cl-label-icon:before {
  content: "";
}

.cl-item.cl-item-open > .cl-label-wrap .cl-label-icon:before {
  content: "\e82d";
}

.cl-item.cl-item-no-sub > .cl-label-wrap .cl-label-title {
  cursor: default;
}
.cl-item.cl-item-no-sub > .cl-label-wrap .cl-label-icon:before {
  content: "";
}

.cl-label-wrap {
  display: table-cell;
  -ms-display: flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
}
.cl-label-wrap .cl-label-title {
  order: 1;
  flex: 1 1 auto;
  align-self: auto;
}
.cl-label-wrap .cl-label-icon {
  order: 0;
  flex: 0 1 1.5em;
  align-self: auto;
}
.cl-label-wrap .cl-label-tools {
  order: 2;
  flex: 0 1 auto;
  align-self: auto;
  white-space: nowrap;
  padding: 0 10px;
}
.filtr-cate-title h4 {
  background: #eaeded;
  padding: 10px 15px;
  border-radius: 5px;
}
.filter-items:first-child {
  padding-top: 0px !important;
}
.product-now.slc input[type="radio"]:checked + label,
.Checked + label {
  border-radius: 30px !important;
  height: 30px;
  width: 50px;
}
.product-now.slc label {
  border-radius: 30px !important;
  height: 30px;
  width: 50px;
}
.newsletter-btn.map-btn {
  width: 100px;
  font-size: 13px !important;
}
.search-map {
  background: #e7eded;
  padding: 15px;
  margin-bottom: 10px;
}
.product-now.slc li {
  margin-right: 15px;
}
.close-pop {
  color: #fff;
  z-index: 99;
  position: absolute;
  right: 0px;
  top: 5px;
}
.show-more {
  text-decoration: underline;
}
.mg-flat li.dropdown a {
  font-weight: 300;
}
#more {
  display: none;
}
.content-blk {
  margin-bottom: 30px;
  padding-top: 15px;
}
.brand-list {
  max-height: 500px;
  overflow-y: auto;
}
.product-now li.attr-wd {
  display: none !important;
}
.checkout-atr-list {
  display: none !important;
}
button.login-btn.hover-btn.google-login-btn.mt-2 {
  display: none;
}

#root .wrapper{
  padding-top: 87px;
}
.top-level-header {
  background-color: #0157a7;
}

.top-level-warpper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top-level-warpper a {
  color: #fff;
}

.top-level-warpper span {
  color: #fff;
}
span.free_delivery {
  padding: 13px 24px;
  font-weight: 900;
  font-size: 18px;
  animation: animateBg 2s linear infinite;
  background-image: linear-gradient(90deg,#FF5722,#ff1100,#db5779,#FF5722,#ff0000);
  background-size: 400% 100%;
}

@keyframes animateBg {
0% { background-position: 0% 0%; }
100% { background-position: 100% 0%; }
}
@media (max-width: 575.98px){
  .medphn {
      display: none;
  }
  .top-header{
    padding-top:10px;
  }
  }
  .offer-link:hover {
    color: #ffffff!important;
}
.content-blk{
background-color: #eaeded;
}
.content-blk p{
  background-color: #eaeded;
}
.content-blk h1,.status-meeting,.read-more-content{
    padding-left:18px;
    padding-right:18px;
}
.read-more {
  padding-left:18px;
  margin-bottom: 9px;
}
.content-blk h2{
  margin-left: 12px !important;
  }
  .content-blk h1{
  letter-spacing: normal !important;
  font-size: 3rem !important;
  }
  .content-blk {
    margin-bottom: 30px;
    padding-top: 20px!important;
    margin-top: 45px!important;
}