@media (max-width: 320px) {
  .loginBtn {
    font-size: 8px;
    padding: 0 10px 0 40px;
  }
  .option_links {
    padding: 10px 3px !important;
    font-size: 18px !important;
    display: none;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .bg-white .col-lg-6.col-md-6 {
    width: 49%;
  }
  .main_logo {
    margin-left: 5px !important;
    width: 110px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .option_links {
    padding: 10px 3px !important;
    font-size: 18px !important;
  }

  .opts_account img {
    margin-left: 5px !important;
    width: 30px !important;
  }

  .menu.dropdown_loc {
    z-index: 9999 !important;
  }

  .menu.dropdown_mn {
    margin-right: -43px !important;
    z-index: 9999 !important;
  }

  .menu.dropdown_ms {
    margin-right: -82px !important;
    z-index: 9999 !important;
  }

  .menu.dropdown_account {
    z-index: 9999 !important;
  }

  .noti_count {
    right: 0 !important;
  }

  .opts_account .user__name {
  }

  .res_main_logo {
    display: inline-block;
    margin-left: 10px;
  }

  .main_logo {
    display: none;
  }

  .blog_logo {
    display: block !important;
    width: 125px !important;
  }

  .select_location {
    margin-left: 10px;
  }

  .header_right ul li:first-child {
    display: none;
  }

  .header_right ul li:nth-child(2) {
  }

  .header_right ul li:nth-child(3) {
  }

  .header_right .blog-nav li:first-child {
    display: block !important;
  }

  .header_right .blog-nav li:nth-child(2) {
    display: block !important;
  }

  .header_right .blog-nav li:nth-child(3) {
    display: block !important;
  }

  .menu.dropdown_mn {
    margin-right: -82px !important;
  }

  .ui.dropdown > .dropdown_loc.left.menu {
    right: auto !important;
    right: 0 !important;
  }

  .ui.inline.dropdown.loc-title > .text {
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .web-blk {
    display: none;
  }
  .navbar-nav.main_nav.align-self-stretch {
    background: #e21959;
  }
  .cate__btn {
    font-size: 12px;
  }

  .ui.dropdown {
    display: none;
  }

  .cart__btn {
    padding: 22px 10px 21px !important;
  }

  .icon__14 {
    top: 0px;
    position: absolute;
  }

  .mobileMenu {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow: auto;
    scroll-behavior: auto;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .mt1-5 {
    border-top: 1px solid #efefef;
  }

  .menu.dropdown_page {
    position: static !important;
    box-shadow: none !important;
    border: 0 !important;
    margin-top: 0 !important;
  }

  .ui.dropdown.nav__menu {
    text-align: center !important;
  }

  .page__links {
    text-align: center !important;
  }

  .wrapper {
    padding-top: 0px !important;
  }

  .wrapper-blog {
    padding-top: 60px !important;
  }

  .cart__btn {
    padding: 18px !important;
  }

  .catey__icon {
    display: block !important;
    padding: 17px 15px;
  }
  .navbar-light .navbar-toggler {
    display: none;
  }

  .search__icon {
    display: block;
  }

  .category-by-cat li {
    width: 50% !important;
  }

  .search-ground-area {
    max-width: 100% !important;
  }

  .bs-canvas {
    width: 320px !important;
  }

  .side-cart-items {
    height: 310px !important;
  }

  .offers-banner .owl-stage-outer {
    overflow: hidden !important;
  }

  .footer-last-row {
    text-align: left !important;
    padding-bottom: 50px;
  }
  .financial-institutes {
    float: none;
    text-align: center;
    margin: 0 auto;
    display: table;
    padding-top: 10px;
  }

  .product-left-title {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .filter-btn {
    float: left;
    width: 115px !important;
    font-size: 10px;
    height: 25px;
    padding: 0px;
    line-height: 25px;
    position: fixed;
    left: 0;
    z-index: 9;
    bottom: 60px;
    right: 0;
    margin: 0 auto;
    text-align: center;
    background-color: #cc0000;
  }

  .product-sort {
    margin-right: 0 !important;
  }

  .product-dt-right {
    margin-top: 30px !important;
    margin-left: 0 !important;
  }

  .pdp-details ul li {
    border-bottom: 1px solid #efefef;
    display: block !important;
    width: 100% !important;
    border-right: 0 !important;
  }

  .pdp-details ul li:last-child {
    border-bottom: 0;
  }

  .ordr-crt-share li {
    display: inline !important;
  }

  .add-cart-btn {
    margin-bottom: 10px;
  }

  .icon-bar {
    display: none;
  }

  .radio--group-inline-container_1 li {
    display: block !important;
    width: 100% !important;
  }

  .left-chck145 {
    margin-bottom: 30px;
  }

  .mrt-0 {
    margin-top: 8px !important;
  }

  .call-email-alt li {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .call-email-alt li:last-child {
    margin-bottom: 0 !important;
  }

  .social-links-footer {
    text-align: center !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }

  .ddsh-body ul li:last-child {
    margin-top: 15px;
    margin-right: 0;
  }

  .wallet-list li {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
  }
  .col-md-4.col-sm-4.col-xs-12.cent-blk {
    margin-bottom: 15px !important;
  }
  .foot-logo {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .left-text {
    text-align: center !important;
  }

  .about-img {
    margin-top: 58px !important;
  }

  .sign-img {
    display: none;
  }

  .sign-inup {
    padding: 50px 0 !important;
  }

  .signup-code-list li {
    width: 18%;
  }

  ul.signup-code-list input {
    width: 100% !important;
  }

  .chck-btn {
    padding: 8px 10px !important;
  }

  .contact-title h2 {
    margin-top: 44px !important;
  }

  /*** New Responsive Css Start ***/

  .main-title-left span {
    font-size: 12px;
  }
  .offer-blk i {
    font-size: 1rem;
  }
  .main-title-left h2 {
    font-size: 15px;
    text-transform: capitalize;
  }
  .main-title-left h1 {
    font-size: 15px;
    text-transform: capitalize;
  }
  .offer-blk {
    line-height: 24px;
    padding: 2px 8px;
  }
  .product-item {
    padding: 8px;
  }
  .banner li span {
    font-size: 0.6rem;
    line-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  .banner li {
    font-size: 0.4rem;
    width: 28px;
  }

  .add-cart-btn {
    height: 50px;
    padding: 0 10px;
    font-size: 0.8rem;
    width: 100%;
  }
  .buy-blk .add-cart-btn {
    width: 80% !important;
    height: 35px !important;
  }
  .product-text-dt h4 {
    font-size: 10px;
    font-weight: 400;
    height: 40px;
  }
  .product-price {
    font-size: 15px;
  }
  .product-price span {
    font-size: 13px;
  }
  .mt1-5 {
    margin-top: 0px !important;
    border-top: 0px !important;
    margin-bottom: 70px;
  }
  .navbar-light .main_nav .nav-link:hover {
    color: #064b73 !important;
  }
  .offer-badge-1 {
    font-size: 10px;
    line-height: 15px;
  }
  .code-offer-item img {
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .opts_account .user__name {
    font-size: 0.7rem;
  }
  .opts_account {
    padding: 5px 5px;
    margin-left: 0px;
  }
  .ui.dropdown .menu > .channel_item {
    font-size: 11px;
  }
  .social-links-footer span {
    font-size: 11px;
  }
  .footer-first-row span {
    font-size: 11px;
  }
  .callemail {
    font-size: 11px;
  }
  .social-links-footer ul li {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .social-links-footer ul li a {
    font-size: 12px;
  }
  .callemail i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  .second-row-item ul li a {
    font-size: 10px;
  }
  .second-row-item h4 {
    font-size: 11px;
  }
  .second-row-item {
    margin-top: 30px;
  }
  .social-links-footer ul {
    display: block;
  }
  .category_drop {
    border-right: 0px;
  }

  .bg-white span {
    width: 100%;
  }
  .show-more-btn {
    height: 30px;
  }
  .cart-item-price span {
    font-size: 11px;
  }
  .cart-item-price {
    font-size: 11px;
  }
  /*span.like-icon{width:24px; height:24px;line-height: 26px; font-size:12px;}*/
  .footer-bottom-links li a {
    font-size: 11px;
  }
  .copyright-text {
    font-size: 11px;
  }
  .ui.selection.dropdown {
    width: 100%;
    font-size: 11px;
  }
  .product-sort {
    width: 100%;
  }
  .ui.selection.dropdown .menu > .item {
    font-size: 11px;
  }
  .noti_count2 {
    top: 5px;
    right: 5px;
    font-size: 11px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .gambo-Breadcrumb .breadcrumb {
    font-size: 12px;
  }
  .product-text-dt p {
    font-size: 11px;
  }
  /*.quantity.buttons_added .minus, .quantity.buttons_added .plus { padding:3px 5px 5px; height:24px; width:24px;}*/
  .product-left-title h2 {
    font-size: 14px;
  }
  .rate-left:not(:checked) > label {
    font-size: 11px;
  }
  .category-item {
    padding: 5px;
  }
  .brand-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    top: 0%;
    height: 30px !important;
    width: 30px !important;
    left: -30px;
  }
  .brand-slider .owl-nav .owl-next {
    font-size: 22px !important;
    top: 0%;
    height: 30px !important;
    width: 30px !important;
    right: -30px;
  }
  .cate-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .cate-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .featured-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .featured-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .offers-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
    left: -15px;
  }
  .offers-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
    right: -15px;
  }
  .xzoom {
    width: 100% !important;
  }
  .product-dt-right h2 {
    font-size: 11px;
  }
  .no-stock p {
    font-size: 10px;
  }
  .product-dt-view {
    padding: 15px;
  }
  .main-price span {
    font-size: 18px;
  }
  .mrp-price {
    font-size: 11px;
  }
  .color-discount {
    font-size: 10px;
  }
  .xzoom-gallery,
  .xzoom-gallery2,
  .xzoom-gallery3,
  .xzoom-gallery4,
  .xzoom-gallery5 {
    width: 12.5% !important;
  }
  .product-now li {
    width: 36px;
    height: 36px;
  }
  .product-now label {
    line-height: 30px;
    font-size: 10px;
  }
  .product-group-dt {
    padding: 0px;
  }
  .order-btn {
    height: 50px;
    padding: 0px 15px;
  }
  .ordr-crt-share .order-btn {
    width: 100%;
    font-size: 14px;
    margin-bottom: 15px;
  }
  .ordr-crt-share .add-cart-btn {
    width: 100%;
    font-size: 14px;
  }
  .ordr-crt-share li {
    margin-right: 5px !important;
  }
  .offer-badge {
    font-size: 9px;
    line-height: normal;
    padding: 1px 5px 0px;
  }
  .container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .col-sm.col-xs-4 {
    width: 25%;
    padding: 5px;
  }
  .cate-img img {
    width: 100%;
  }
  .section145 .cat-blk {
    padding: 15px;
  }
  .cate-slider .owl-nav .owl-prev {
    left: -30px;
  }
  .cate-slider .owl-nav .owl-next {
    right: -30px;
  }
  .col-lg-2.col-md-3.col-sm-4.col-xs-6.mb-15 {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-md-3.col-sm-6.col-xs-6 {
    width: 50%;
  }
  .foot-logo img {
    width: 100px;
    text-align: center;
    margin: 0 auto;
    display: table;
  }
  .nav-link {
    padding: 0.7rem 0.5rem !important;
    font-weight: 600 !important;
    font-size: 9px;
  }
  /*.quantity .input-text.qty{width:24px; height:24px;}*/
  .pdct-dt-step h4 {
    font-size: 12px;
  }
  .pdct-dt-step p {
    font-size: 12px;
  }
  .product_attr p {
    font-size: 12px;
  }
  .heading {
    font-size: 12px;
  }
  .rating-block .fa {
    font-size: 12px;
  }
  .rating-block p {
    font-size: 12px;
  }
  .side {
    font-size: 11px;
    padding-top: 5px;
    width: 40px;
  }
  .product-specs {
    margin: 0px;
    padding: 15px;
  }
  .product-prop {
    max-width: 100%;
  }
  .product-specs .property-title {
    font-size: 12px;
  }
  .line-limit-length {
    font-size: 12px;
  }
  .product-specs .product-specs-list li {
    width: 100%;
  }
  .review-block-name {
    font-size: 12px;
  }
  .review-block-title {
    font-size: 12px;
  }
  .review-block-description {
    font-size: 12px;
  }
  .middle {
    width: 60%;
  }
  .pdp-text-dt span {
    font-size: 12px;
  }
  .pdp-text-dt p {
    font-size: 12px;
  }
  .cart-text h4 {
    font-size: 12px;
    line-height: 15px;
  }
  .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    font-size: 7px;
  }
  .bill-btn5 {
    font-size: 11px;
    padding: 5px 10px;
  }
  .delivery-man {
    font-size: 12px;
  }
  .product-left-title h4 {
    font-size: 0.9rem;
  }
  .pdpt-title h6 {
    font-size: 12px;
  }
  .color-discount {
    font-size: 12px;
  }
  .order-dt47 h4 {
    font-size: 12px;
    line-height: normal;
  }
  .color-delivered {
    font-size: 10px;
  }
  .user-item {
    font-size: 12px;
    padding: 10px 20px;
  }
  .order-dt47 p {
    font-size: 12px;
  }
  .pdpt-bg {
    margin-top: 10px;
  }
  .order-title {
    font-size: 12px;
  }
  .call-bill {
    padding: 5px 20px;
  }
  .second-row-item ul li {
    padding-bottom: 0px;
  }
  .order-dtsll span {
    font-size: 12px;
  }
  .rate-block:not(:checked) > label {
    font-size: 14px;
  }
  .rating-form label {
    font-size: 10px;
  }
  .address-btns {
    font-size: 12px;
  }
  .checkout-step {
    padding: 15px !important;
  }
  .add-address {
    padding: 4px 10px;
    font-size: 12px;
  }
  .address-dt-all h4 {
    font-size: 12px;
  }
  .address-dt-all p {
    font-size: 12px;
    line-height: normal;
  }
  .action-btns li i {
    font-size: 15px;
  }
  .action-btns li a {
    font-size: 12px;
  }
  label.control-label {
    font-size: 12px;
  }
  .checout-address-step .form-control {
    font-size: 12px;
  }
  .wizard-btn {
    font-size: 16px !important;
  }
  .checkout-step-number {
    width: 24px !important;
    height: 24px !important;
    padding: 3px !important;
    font-size: 11px !important;
  }
  .radio-label_1 {
    font-size: 14px;
    line-height: 12px;
    padding: 15px 20px;
    margin-bottom: 15px;
  }
  .next-btn16 {
    height: auto;
    padding: 15px 12px;
    width: 100%;
    text-align: center;
  }
  .checkout-step-body p {
    font-size: 10px;
    line-height: normal;
  }
  .phn145 .edit-no-btn {
    font-size: 12px;
  }
  .resend-link {
    font-size: 12px;
  }

  .ui.checkbox label {
    font-size: 13px !important;
  }
  .pymnt_title h4 {
    font-size: 12px;
  }
  .ui.input > input {
    font-size: 12px;
  }
  .cart-total-dil h4 {
    font-size: 12px;
    line-height: normal;
  }
  .cart-total-dil span {
    font-size: 12px;
  }
  .main-total-cart h2 {
    font-size: 14px;
  }
  .main-total-cart span {
    font-size: 18px;
  }
  .promo-link45 {
    font-size: 12px;
  }
  .coupon-input .form-control {
    font-size: 12px;
  }
  .payment-secure {
    font-size: 12px;
  }
  .checkout-safety-alerts p {
    font-size: 12px;
  }
  .coupon-btn {
    font-size: 12px;
  }
  .ui.dropdown.form-dropdown .menu > .item {
    font-size: 12px !important;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 12px;
  }
  .login-phone .form-control {
    font-size: 12px;
  }
  .chck-btn {
    font-size: 11px;
    height: 32px;
  }
  .right-cart-dt-body {
    display: none;
  }
  .order-placed-dt p {
    font-size: 12px;
  }
  .table td,
  .table th {
    font-size: 12px;
  }
  .returns {
    width: 100%;
  }
  .default-card {
    position: static;
  }
  .cart-text strong {
    font-size: 12px;
  }
  .show-more-btn {
    font-size: 12px;
  }
  .form-control {
    font-size: 0.9rem !important;
  }
  .main-cart-title {
    font-size: 12px;
  }
  .password-forgor a {
    font-size: 12px;
  }
  .signup-link p {
    font-size: 12px;
  }
  .signup-link1 p a {
    font-size: 12px;
  }

  .form-inpts {
    padding: 15px 15px 0;
  }
  .chck-btn {
    float: right;
  }
  .login-phone:after {
    font-size: 12px !important;
    top: 18px !important;
  }
  .form-title h6 {
    font-size: 14px;
  }
  .sign-inner p {
    font-size: 12px;
  }
  .figure-img.pt-5.align-middle {
    padding-top: 0px !important;
  }
  .panel-title a {
    font-size: 13px;
  }
  .panel-body p {
    font-size: 12px;
  }
  .default-title h2 {
    font-size: 16px;
  }
  .loginBtn {
    font-size: 12px;
    padding: 0 10px 0 40px;
    width: 145px;
  }
  .save-card {
    font-size: 12px;
  }
  .card.card-body img {
    width: 24px;
  }
  .payment_method-checkout .card.card-body {
    padding: 0.5rem;
  }

  body {
    background: #ffffff !important;
  }
  .padding-30 {
    padding: 0px 10px;
  }
  .section145 {
    padding: 0px 0px 0px 0px;
  }
  .slick-list .item {
    padding: 0px 2px !important;
  }
  .next-slick-arrow,
  .prev-slick-arrow {
    display: none;
  }
  .section145 .cat-blk {
    padding: 0px;
  }
  .product-item {
    box-shadow: none;
  }
  .cart-icon {
    margin-right: 30px;
  }
  .side1 .col-lg-6.col-md-4.col-sm-3 {
    width: 50%;
  }
  .owl-theme .owl-nav {
    display: none;
  }
  .col-lg-3.col-md-4.col-sm-4.col-xs-6.mb-15 {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
    border-right: 5px solid #f6f7f8;
    border-top: 5px solid #f6f7f7;
    margin-bottom: 0px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .col-lg-3.col-md-4.col-sm-4.col-xs-6.mb-15:nth-child(2n + 2) {
    border-right: 0px;
  }
  .res_main_logo {
    width: 150px;
  }
  .social-links-footer ul li {
    margin-right: 8px;
  }
  .sub-header {
    background: #eaeded;
    position: fixed;
    bottom: 0;
    z-index: 99;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }

  .banner li span {
    padding: 0px 5px;
  }
  .order-body10 .col {
    width: 100%;
    flex-basis: auto;
  }
  .address-btns .next-btn16 {
    line-height: normal;
    width: 130px;
    padding: 5px 10px;
    margin: 0px;
    font-size: 10px;
  }
  .order-bill-slip {
    display: table;
  }
  .delivery-man a {
    display: table;
    margin-right: 0px;
  }
  i.icon1 {
    font-size: 20px !important;
  }
  .placed-bottom-dt {
    text-align: center;
  }
  .delivery-address-bg {
    background: #f6f7f7;
  }

  .checkout-atr-list li {
    font-size: 9px;
  }
  .checkout-step-body .next-btn16 {
    width: 100%;
    padding: 10px;
    font-size: 12px;
  }
  .noti_count2 {
    background: #0157a7;
  }
  .cart-item-price {
    display: flex;
    flex-direction: column;
    align-items: inherit;
  }
  .wrapper.page-blk .product-item {
    border-bottom: 0px;
    border: none;
  }
  .filter-body {
    border-right: 0px;
  }

  .bg-white .col-lg-3.col-md-3 {
    width: 25%;
  }
  .cate__btn {
    padding: 20px 0px 19px;
  }
  .filter-items {
    padding: 15px 15px 0;
  }
  .bx-wrapper .bx-prev {
    background-size: 100% !important;
  }
  .bx-wrapper .bx-next {
    background-size: 100% !important;
    right: 0px !important;
  }
  .empty-prd img {
    width: 60%;
  }

  .md-mb-15 {
    margin-bottom: 15px;
  }
  .header_cart {
    margin-right: 15px;
  }

  .bs-canvas.bs-canvas-right.position-fixed.bg-cart.h-100 {
    height: 100% !important;
  }
  .mob-fil-blk .bs-canvas {
    width: auto;
    top: 0;
  }
  .mob-fil-blk .position-fixed {
    position: fixed !important;
  }
  .mob-fil-blk .bs-canvas-right {
    left: 0 !important;
    margin-left: -400px;
  }
  .delivery-address-bg p {
    font-size: 12px;
  }
  .title585 h4 {
    font-size: 14px;
  }
  .delivery-address-bg {
    padding: 10px;
  }
  .map-responsive {
    margin-top: -65px;
    height: 250px !important;
  }
  .gty-wish-share {
    margin: 0 auto;
    display: table;
    margin-top: 20px;
  }
  .magnify-container_1 {
    width: 100%;
  }
  .bg-white.padding-30 .col-lg-4.col-md-4 {
    width: 33.33%;
  }
  .res_main_logo img {
    width: 90px;
  }
  .top-header .col-auto {
    padding: 0px !important;
  }
  .search-sm .newsletter-input {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .call-email-alt {
    text-align: center;
  }

  /*.product-group-dt {
position: fixed;
bottom: 50px;
z-index: 9;
background: #e7e8e8;
width: 100%;
left: 0;
padding: 15px 30px;
}
.order-btn.hover-btn {
display: none;
}
ul.gty-wish-share {
display: inline-block;
}*/
}

/*** New Responsive Css End ***/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /*** New Responsive Css Start ***/

  .main-title-left span {
    font-size: 12px;
  }
  .offer-blk i {
    font-size: 1rem;
  }
  .main-title-left h2 {
    font-size: 15px;
    text-transform: capitalize;
  }
  .main-title-left h1 {
    font-size: 15px;
    text-transform: capitalize;
  }
  .offer-blk {
    line-height: 24px;
  }
  .product-item {
    padding: 8px;
  }
  .banner li span {
    font-size: 0.6rem;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .banner li {
    font-size: 0.4rem;
    width: 30px;
  }
  .buy-blk .add-cart-btn {
    width: 100% !important;
    height: 40px !important;
  }
  .add-cart-btn {
    height: 40px;
    padding: 0 10px;
    font-size: 0.8rem;
  }
  .product-text-dt h4 {
    font-size: 10px;
    font-weight: 400;
  }
  .product-price {
    font-size: 15px;
  }
  .product-price span {
    font-size: 12px;
  }
  .navbar-light .main_nav .nav-link:hover {
    color: #064b73 !important;
  }
  .offer-badge-1 {
    font-size: 10px;
    line-height: 15px;
  }
  .code-offer-item img {
    margin-bottom: 15px;
    border-radius: 5px;
  }
  .opts_account .user__name {
    font-size: 0.7rem;
  }
  .opts_account {
    padding: 5px 10px;
  }
  .ui.dropdown .menu > .channel_item {
    font-size: 10px;
  }
  .social-links-footer span {
    font-size: 10px;
  }
  .footer-first-row span {
    font-size: 10px;
  }
  .callemail {
    font-size: 10px;
  }
  .social-links-footer ul li {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .social-links-footer ul li a {
    font-size: 12px;
  }
  .callemail i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  .second-row-item ul li a {
    font-size: 10px;
  }
  .second-row-item h4 {
    font-size: 10px;
  }
  .second-row-item {
    margin-top: 30px;
  }
  .social-links-footer ul {
    display: block;
  }
  .category_drop {
    border-right: 0px;
  }

  .bg-white span {
    width: 100%;
  }
  .cart-item-price span {
    font-size: 10px;
  }
  .cart-item-price {
    font-size: 10px;
  }
  span.like-icon {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 14px;
  }
  .footer-bottom-links li a {
    font-size: 10px;
  }
  .copyright-text {
    font-size: 10px;
  }
  .gambo-Breadcrumb .breadcrumb {
    font-size: 12px;
  }
  .product-left-title h2 {
    font-size: 14px;
  }
  .rate-left:not(:checked) > label {
    font-size: 10px;
  }
  .category-item {
    padding: 5px;
  }
  .brand-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    top: 0%;
    height: 30px !important;
    width: 30px !important;
  }
  .brand-slider .owl-nav .owl-next {
    font-size: 22px !important;
    top: 0%;
    height: 30px !important;
    width: 30px !important;
  }
  .cate-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .cate-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .featured-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .featured-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
  }
  .offers-slider .owl-nav .owl-prev {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
    left: -15px;
  }
  .offers-slider .owl-nav .owl-next {
    font-size: 22px !important;
    height: 30px !important;
    width: 30px !important;
    right: -15px;
  }
  .xzoom {
    width: 100% !important;
  }
  .product-dt-right h2 {
    font-size: 12px;
  }
  .product-dt-view {
    padding: 15px;
  }
  .main-price span {
    font-size: 13px;
  }
  .mrp-price {
    font-size: 12px;
  }
  .color-discount {
    font-size: 10px;
  }
  .xzoom-gallery,
  .xzoom-gallery2,
  .xzoom-gallery3,
  .xzoom-gallery4,
  .xzoom-gallery5 {
    width: 12.5% !important;
  }
  .product-now li {
    width: 36px;
    height: 36px;
  }
  .product-now label {
    line-height: 30px;
    font-size: 10px;
  }
  .product-group-dt {
    padding: 0px;
  }
  .order-btn {
    height: 40px;
    padding: 0px 5px;
  }
  .ordr-crt-share .order-btn {
    width: 100%;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .ordr-crt-share .add-cart-btn {
    width: 100%;
    font-size: 13px;
  }
  .ordr-crt-share li {
    margin-right: 5px !important;
  }
  .container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .col-sm.col-xs-4 {
    width: 25%;
    padding-bottom: 8px;
  }
  .cate-img img {
    width: 100%;
  }
  .section145 .cat-blk {
    padding: 15px;
  }
  .cate-slider .owl-nav .owl-prev {
    left: -30px;
  }
  .cate-slider .owl-nav .owl-next {
    right: -30px;
  }
  .col-lg-2.col-md-3.col-sm-4.col-xs-6.mb-15 {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .col-md-3.col-sm-6.col-xs-6 {
    width: 50%;
  }
  .foot-logo img {
    width: 180px;
    text-align: center;
    margin: 0 auto;
    display: table;
  }
  .nav-link {
    padding: 0.7rem 0.5rem !important;
    font-weight: 600 !important;
    font-size: 12px;
  }
  .quantity .input-text.qty {
    width: 24px;
    height: 24px;
  }
  .pdct-dt-step h4 {
    font-size: 12px;
  }
  .pdct-dt-step p {
    font-size: 10px;
  }

  .product_attr p {
    font-size: 10px;
  }
  .heading {
    font-size: 12px;
  }
  .rating-block .fa {
    font-size: 12px;
  }
  .side {
    font-size: 10px;
    padding-top: 5px;
    width: 40px;
  }
  .product-specs {
    margin: 0px;
    padding: 15px;
  }
  .product-prop {
    max-width: 100%;
  }
  .product-specs .property-title {
    font-size: 10px;
  }
  .line-limit-length {
    font-size: 10px;
  }
  .product-specs .product-specs-list li {
    width: 100%;
  }
  .review-block-title {
    font-size: 11px;
  }
  .review-block-description {
    font-size: 10px;
  }
  .middle {
    width: 65%;
  }
  .pdp-text-dt span {
    font-size: 11px;
  }
  .pdp-text-dt p {
    font-size: 10px;
  }
  .cart-text h4 {
    font-size: 10px;
    line-height: 15px;
  }
  .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    font-size: 10px;
  }
  .bill-btn5 {
    font-size: 11px;
    padding: 5px 10px;
  }
  .delivery-man {
    font-size: 11px;
  }
  .product-left-title h4 {
    font-size: 1rem;
  }
  .pdpt-title h6 {
    font-size: 12px;
  }
  .color-discount {
    font-size: 10px;
  }
  .order-dt47 h4 {
    font-size: 10px;
    line-height: normal;
  }
  .color-delivered {
    font-size: 9px;
  }
  .user-item {
    font-size: 12px;
    padding: 10px 20px;
  }
  .order-dt47 p {
    font-size: 12px;
  }
  .pdpt-bg {
    margin-top: 10px;
  }
  .order-title {
    font-size: 12px;
  }
  .call-bill {
    padding: 5px 20px;
  }
  .second-row-item ul li {
    padding-bottom: 0px;
  }
  .add-address {
    padding: 4px 10px;
    font-size: 10px;
  }
  .address-dt-all h4 {
    font-size: 12px;
  }
  .address-dt-all p {
    font-size: 12px;
    line-height: normal;
  }
  .action-btns li i {
    font-size: 15px;
  }
  .action-btns li a {
    font-size: 12px;
  }
  label.control-label {
    font-size: 12px;
  }
  .checout-address-step .form-control {
    font-size: 11px;
  }
  .wizard-btn {
    font-size: 14px !important;
  }
  .checkout-step-number {
    width: 26px !important;
    height: 26px !important;
    padding: 4px !important;
    font-size: 12px !important;
  }
  .radio-label_1 {
    font-size: 14px;
    line-height: 14px;
    padding: 15px 20px;
    margin-bottom: 15px;
  }
  .next-btn16 {
    height: 30px;
    padding: 10px 12px;
  }
  .checkout-step-body p {
    font-size: 10px;
    line-height: normal;
  }
  .phn145 .edit-no-btn {
    font-size: 10px;
  }
  .resend-link {
    font-size: 11px;
  }
  .next-btn16 {
    font-size: 12px;
  }
  .ui.checkbox label {
    font-size: 13px !important;
  }
  .pymnt_title h4 {
    font-size: 13px;
  }
  .ui.input > input {
    font-size: 12px;
  }
  .cart-total-dil h4 {
    font-size: 12px;
    line-height: normal;
  }
  .cart-total-dil span {
    font-size: 12px;
  }
  .main-total-cart h2 {
    font-size: 14px;
  }
  .main-total-cart span {
    font-size: 14px;
  }
  .promo-link45 {
    font-size: 11px;
  }
  .coupon-input .form-control {
    font-size: 12px;
  }
  .payment-secure {
    font-size: 12px;
  }
  .checkout-safety-alerts p {
    font-size: 12px;
  }
  .coupon-btn {
    font-size: 11px;
  }
  .ui.dropdown.form-dropdown .menu > .item {
    font-size: 12px !important;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 12px;
  }
  .login-phone .form-control {
    font-size: 12px;
  }
  .chck-btn {
    font-size: 11px;
    height: auto;
    padding: 9px 12px;
  }
  .right-cart-dt-body {
    display: none;
  }
  .table td,
  .table th {
    font-size: 12px;
  }
  .returns {
    width: 60%;
  }
  .form-control {
    font-size: 0.9rem !important;
  }
  .main-cart-title {
    font-size: 13px;
  }
  .password-forgor a {
    font-size: 12px;
  }
  .signup-link p {
    font-size: 11px;
  }
  .signup-link1 p a {
    font-size: 12px;
  }

  .chck-btn {
    float: right;
  }
  .login-phone:after {
    font-size: 12px !important;
  }
  .panel-title a {
    font-size: 14px;
  }
  .panel-body p {
    font-size: 12px;
  }
  .default-title h2 {
    font-size: 18px;
  }
  .loginBtn {
    font-size: 12px;
    padding: 0 10px 0 40px;
    width: 145px;
  }

  /*** New Responsive Css End ***/

  .bg-white .col-lg-6.col-md-6 {
    width: 49%;
  }
  .main_logo {
    margin-left: 10px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
  }

  .main_logo {
    display: none;
  }

  .blog_logo {
    display: block !important;
  }

  .res_main_logo {
    display: inline-block;
    margin-left: 10px;
  }

  .main_logo {
    display: none;
  }

  .select_location {
    margin-left: 10px;
  }

  .menu.dropdown_loc {
    z-index: 9999 !important;
  }

  .header_right ul li:first-child {
    display: none;
  }

  .header_right .blog-nav li:first-child {
    display: block !important;
  }

  .menu.dropdown_mn {
    z-index: 9999 !important;
  }

  .menu.dropdown_ms {
    z-index: 9999 !important;
  }

  .menu.dropdown_account {
    z-index: 9999 !important;
  }

  .mobileMenu {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow: auto;
    scroll-behavior: auto;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .mt1-5 {
    border-top: 1px solid #efefef;
  }

  .menu.dropdown_page {
    position: static !important;
    box-shadow: none !important;
    border: 0 !important;
    margin-top: 0 !important;
  }

  .ui.dropdown.nav__menu {
    text-align: center !important;
  }

  .page__links {
    text-align: center !important;
  }

  .wrapper {
    padding-top: 125px !important;
  }

  .wrapper-blog {
    padding-top: 60px !important;
  }

  .web-blk {
    display: none;
  }
  .navbar-nav.main_nav.align-self-stretch {
    background: #e21959;
  }
  .cate__btn {
    font-size: 12px;
  }

  .cart__btn {
    padding: 17px 30px 18px !important;
  }

  .catey__icon {
    display: block !important;
  }

  .search__icon {
    display: block;
  }

  .offers-banner .owl-stage-outer {
    overflow: hidden !important;
  }

  .footer-last-row {
    text-align: center !important;
  }

  img.download-btn {
    width: 110px !important;
  }

  .product-left-title {
    float: left;
    width: 100%;
    margin-bottom: 20px;
  }

  .filter-btn {
    float: left;
    width: 85px !important;
    font-size: 10px;
    height: 28px;
    padding: 0px;
    line-height: 28px;
  }

  .product-sort {
    margin-right: 0 !important;
  }

  .product-dt-right {
    margin-top: 30px !important;
    margin-left: 0 !important;
  }

  .pdp-details ul li {
    border-bottom: 1px solid #efefef;
    display: block !important;
    width: 100% !important;
    border-right: 0 !important;
  }

  .pdp-details ul li:last-child {
    border-bottom: 0;
  }

  .ordr-crt-share li {
    display: inline !important;
  }

  .add-cart-btn {
    margin-bottom: 10px;
  }

  .icon-bar {
    display: none;
  }

  .radio--group-inline-container_1 li {
    display: block !important;
    width: 100% !important;
  }

  .left-chck145 {
    margin-bottom: 30px;
  }

  .mrt-0 {
    margin-top: 8px !important;
  }

  .call-email-alt li {
    display: block !important;
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  .call-email-alt li:last-child {
    margin-bottom: 0 !important;
  }

  .wallet-list li {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
  }

  .left-text {
    text-align: center !important;
  }

  .about-img {
    margin-top: 58px !important;
  }

  .sign-img {
    display: none;
  }

  .sign-inup {
    padding: 50px 0 !important;
  }

  .contact-title h2 {
    margin-top: 44px !important;
  }
  .section145.padd .col-lg-3.col-md-6 {
    width: 50%;
  }
  .order-body10 .col {
    width: 100%;
    flex-basis: auto;
  }
  .address-btns .next-btn16 {
    line-height: normal;
  }
  .order-bill-slip {
    display: table;
  }
  .delivery-man a {
    display: table;
    margin-right: 0px;
  }
  .placed-bottom-dt {
    text-align: center;
  }
  .delivery-address-bg {
    background: #f6f7f7;
  }
  .checkout-atr-list li {
    font-size: 9px;
  }
  .filter-body {
    border-right: 0px;
  }
  .bg-white .col-lg-3.col-md-3 {
    width: 25%;
  }
  .filter-items {
    padding: 15px 15px 0;
  }

  .md-mb-15 {
    margin-bottom: 15px;
  }

  .bs-canvas.bs-canvas-right.position-fixed.bg-cart.h-100 {
    height: 100% !important;
  }
  .mob-fil-blk .bs-canvas {
    width: 320px;
    top: 0;
  }
  .mob-fil-blk .position-fixed {
    position: fixed !important;
  }
  .mob-fil-blk .bs-canvas-right {
    left: 0 !important;
    margin-left: -400px;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .main_logo {
    margin-left: 10px !important;
  }

  .search120 {
    display: none;
    margin-left: 0 !important;
    /*width:38%;*/
  }

  .menu.dropdown_loc {
    z-index: 9999 !important;
  }

  .menu.dropdown_mn {
    z-index: 9999 !important;
  }

  .menu.dropdown_ms {
    z-index: 9999 !important;
  }

  .menu.dropdown_account {
    z-index: 9999 !important;
  }

  .mobileMenu {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    transform: translateX(-100%);
    transition: all ease 0.25s;
    overflow: auto;
    scroll-behavior: auto;
  }
  .open {
    transform: translateX(0);
    z-index: 11;
  }

  .mt1-5 {
    border-top: 1px solid #efefef;
  }

  .menu.dropdown_page {
    position: static !important;
    box-shadow: none !important;
    border: 0 !important;
    margin-top: 0 !important;
  }

  .ui.dropdown.nav__menu {
    text-align: center !important;
  }

  .page__links {
    text-align: center !important;
  }

  .wrapper {
    padding-top: 125px !important;
  }

  .wrapper-blog {
    padding-top: 60px !important;
  }

  .web-blk {
    display: none;
  }
  .cate__btn {
    font-size: 12px;
  }
  .navbar-nav.main_nav.align-self-stretch {
    background: #e21959;
  }

  .cart__btn {
    padding: 17px 30px 18px !important;
  }

  .catey__icon {
    display: block !important;
  }

  .search__icon {
    display: block;
  }

  .offers-banner .owl-stage-outer {
    overflow: hidden !important;
  }

  .offr-none {
    display: none;
  }

  .pdp-details ul li {
    border-bottom: 1px solid #efefef;
    display: block !important;
    width: 100% !important;
    border-right: 0 !important;
  }

  .pdp-details ul li:last-child {
    border-bottom: 0;
  }

  .radio--group-inline-container_1 li {
    display: block !important;
    width: 100% !important;
  }

  .radio--group-inline-container_1 {
    display: block !important;
  }

  .left-text {
    text-align: center !important;
  }

  .about-img {
    margin-top: 58px !important;
  }

  .sign-img {
    display: none;
  }

  .sign-inup {
    padding: 50px 0 !important;
  }
  .main_nav .nav-link {
  }
  .col-sm.col-xs-4 {
    width: 20%;
    padding-bottom: 8px;
  }
  .cate-img img {
    width: 100%;
  }
  .foot-logo img {
    width: 100%;
  }
  .second-row-item ul li {
    padding-bottom: 5px;
  }
  .second-row-item ul li a {
    font-size: 10px;
  }
  .second-row-item h4 {
    font-size: 10px;
  }
  .footer-bottom-links li a {
    font-size: 10px;
  }
  .copyright-text {
    font-size: 10px;
  }
  .social-links-footer span {
    font-size: 10px;
  }
  .footer-first-row span {
    font-size: 10px;
  }
  .callemail {
    font-size: 10px;
  }
  .social-links-footer ul li {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .social-links-footer ul li a {
    font-size: 12px;
  }
  .callemail i {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
  .second-row-item ul li a {
    font-size: 10px;
  }
  .second-row-item h4 {
    font-size: 10px;
  }
  .second-row-item {
    margin-top: 30px;
  }
  .social-links-footer ul {
    display: block;
  }
  .middle {
    width: 70%;
  }
  .ordr-crt-share .add-cart-btn {
    width: 200px;
    font-size: 12px;
  }
  .ordr-crt-share .order-btn {
    width: 130px;
    font-size: 12px;
  }
  .add-cart-btn {
    padding: 0px 10px;
  }
  .order-btn {
    padding: 0px 10px;
  }
  .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    font-size: 11px;
  }
  .bill-btn5 {
    font-size: 11px;
    padding: 5px 10px;
  }
  .delivery-man {
    font-size: 11px;
  }
  .product-left-title h4 {
    font-size: 1.1rem;
  }
  .pdpt-title h6 {
    font-size: 11px;
  }

  .order-dt47 h4 {
    font-size: 11px;
    line-height: normal;
  }
  .color-delivered {
    font-size: 10px;
  }
  .color-discount {
    font-size: 10px;
  }
  .user-item {
    font-size: 12px;
    padding: 10px 20px;
  }
  .ui.selection.dropdown {
    width: 11em;
    font-size: 11px;
  }
  .ui.selection.dropdown .menu > .item {
    font-size: 11px;
  }
  .second-row-item ul li {
    padding-bottom: 0px;
  }

  .add-address {
    padding: 4px 10px;
    font-size: 10px;
  }
  .address-dt-all h4 {
    font-size: 11px;
  }
  .address-dt-all p {
    font-size: 11px;
    line-height: normal;
  }
  .action-btns li i {
    font-size: 15px;
  }
  .action-btns li a {
    font-size: 11px;
  }
  label.control-label {
    font-size: 11px;
  }
  .checout-address-step .form-control {
    font-size: 11px;
  }
  .wizard-btn {
    font-size: 14px !important;
  }
  .checkout-step-number {
    width: 26px !important;
    height: 26px !important;
    padding: 4px !important;
    font-size: 11px !important;
  }
  .radio-label_1 {
    font-size: 13px;
    line-height: 14px;
    padding: 8px 20px;
  }
  .next-btn16 {
    height: 30px;
    padding: 8px 12px;
  }
  .checkout-step-body p {
    font-size: 10px;
    line-height: normal;
  }
  .phn145 .edit-no-btn {
    font-size: 10px;
  }
  .resend-link {
    font-size: 11px;
  }
  .next-btn16 {
    font-size: 13px;
  }
  .ui.checkbox label {
    font-size: 11px !important;
  }
  .pymnt_title h4 {
    font-size: 13px;
  }
  .ui.input > input {
    font-size: 11px;
  }
  .cart-total-dil h4 {
    font-size: 11px;
    line-height: normal;
  }
  .cart-total-dil span {
    font-size: 11px;
  }
  .main-total-cart h2 {
    font-size: 14px;
  }
  .main-total-cart span {
    font-size: 14px;
  }
  .promo-link45 {
    font-size: 11px;
  }
  .coupon-input .form-control {
    font-size: 11px;
  }
  .payment-secure {
    font-size: 12px;
  }
  .checkout-safety-alerts p {
    font-size: 11px;
  }
  .coupon-btn {
    font-size: 11px;
  }
  .ui.dropdown.form-dropdown .menu > .item {
    font-size: 11px !important;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 11px;
  }
  .login-phone .form-control {
    font-size: 11px;
  }
  .chck-btn {
    font-size: 10px;
    height: 36px;
  }
  .right-cart-dt-body {
    display: none;
  }
  .table td,
  .table th {
    font-size: 11px;
  }
  .default-card {
    bottom: -15px;
  }
  .form-control {
    font-size: 0.9rem !important;
  }
  .main-cart-title {
    font-size: 13px;
  }

  .chck-btn {
    float: right;
  }
  .panel-title a {
    font-size: 14px;
  }
  .panel-body p {
    font-size: 11px;
  }
  .loginBtn {
    font-size: 11px;
    padding: 0 10px 0 40px;
    width: 145px;
  }
  .social-links-footer ul li {
    margin-right: 5px;
  }
  .offer-link {
    display: none;
  }

  .ui.search {
    margin-left: 15px;
  }
  .address-btns .next-btn16 {
    line-height: normal;
  }
  .product-text-dt h4 {
    font-size: 10px;
  }
  .bg-white .col-lg-3.col-md-3 {
    width: 25%;
  }
  .md-mb-15 {
    margin-bottom: 15px;
  }
  .border-radius-15 {
    border-radius: 5px;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .social-links-footer ul li {
    width: 30px;
  }
  .callemail i {
    font-size: 13px;
  }
  .callemail {
    font-size: 13px;
  }
  body {
    overflow-y: scroll;
  }

  .logo img {
    width: 200px;
    text-align: center;
    margin-top: 10px;
    float: none;
  }

  .search120 {
    /* width: 45% !important;*/
    margin-left: 15px;
  }

  .header_right ul li:first-child {
    display: none;
  }

  .header_right .blog-nav li:first-child {
    display: block !important;
  }

  .menu.dropdown_loc {
    z-index: 9999 !important;
  }

  .menu.dropdown_mn {
    z-index: 9999 !important;
  }

  .menu.dropdown_ms {
    z-index: 9999 !important;
  }

  .menu.dropdown_account {
    z-index: 9999 !important;
  }
  .main_nav .nav-link {
    padding: 10px 5px !important;
    font-size: 0.7rem;
  }
  .category_drop {
    padding: 17px 15px;
    font-size: 0.7rem;
  }
  .foot-logo img {
    width: 150px;
    margin-top: 0px;
  }
  .social-links-footer ul {
    display: block;
  }
  .second-row-item ul li {
    padding-bottom: 5px;
  }
  .second-row-item ul li a {
    font-size: 10px;
  }
  .second-row-item h4 {
    font-size: 10px;
  }
  .footer-bottom-links li a {
    font-size: 10px;
  }
  .copyright-text {
    font-size: 10px;
  }
  .middle {
    width: 70%;
  }

  .add-address {
    padding: 4px 10px;
    font-size: 10px;
  }
  .address-dt-all h4 {
    font-size: 11px;
  }
  .address-dt-all p {
    font-size: 11px;
    line-height: normal;
  }
  .action-btns li i {
    font-size: 15px;
  }
  .action-btns li a {
    font-size: 11px;
  }
  label.control-label {
    font-size: 11px;
  }
  .checout-address-step .form-control {
    font-size: 11px;
  }
  .wizard-btn {
    font-size: 14px !important;
  }
  .checkout-step-number {
    width: 26px !important;
    height: 26px !important;
    padding: 4px !important;
    font-size: 11px !important;
  }
  .radio-label_1 {
    font-size: 13px;
    line-height: 14px;
    padding: 8px 20px;
  }
  .radio--group-inline-container_1 li {
    width: 48%;
  }
  .next-btn16 {
    height: auto;
    padding: 8px 12px;
  }
  .checkout-step-body p {
    font-size: 10px;
    line-height: normal;
  }
  .phn145 .edit-no-btn {
    font-size: 10px;
  }
  .resend-link {
    font-size: 11px;
  }
  .next-btn16 {
    font-size: 13px;
  }
  .ui.checkbox label {
    font-size: 11px !important;
  }
  .pymnt_title h4 {
    font-size: 13px;
  }
  .ui.input > input {
    font-size: 11px;
  }
  .cart-total-dil h4 {
    font-size: 11px;
    line-height: normal;
  }
  .cart-total-dil span {
    font-size: 11px;
  }
  .main-total-cart h2 {
    font-size: 14px;
  }
  .main-total-cart span {
    font-size: 14px;
  }
  .promo-link45 {
    font-size: 11px;
  }
  .coupon-input .form-control {
    font-size: 11px;
  }
  .payment-secure {
    font-size: 12px;
  }
  .checkout-safety-alerts p {
    font-size: 11px;
  }
  .coupon-btn {
    font-size: 11px;
  }
  .ui.dropdown.form-dropdown .menu > .item {
    font-size: 11px !important;
  }
  .form-check-label {
    margin-bottom: 0;
    font-size: 11px;
  }
  .login-phone .form-control {
    font-size: 11px;
  }
  .chck-btn {
    font-size: 10px;
    height: 32px;
  }
  .table td,
  .table th {
    font-size: 12px;
  }

  .bg-white span {
    font-size: 12px;
  }
  .show-more-btn {
    height: 30px;
  }
  .loginBtn {
    font-size: 11px;
    padding: 0 10px 0 40px;
    width: 145px;
  }
  .social-links-footer ul li {
    margin-right: 5px;
  }
  .ordr-crt-share .add-cart-btn {
    width: 200px;
    font-size: 15px;
  }
  .ordr-crt-share .order-btn {
    width: 155px;
    font-size: 15px;
  }
  .md-mb-15 {
    margin-bottom: 15px;
  }
  .border-radius-15 {
    border-radius: 5px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .container-fluid {
    max-width: 100% !important;
  }
  .menu.dropdown_loc {
    z-index: 9999 !important;
  }

  .menu.dropdown_mn {
    z-index: 9999 !important;
  }

  .menu.dropdown_ms {
    z-index: 9999 !important;
  }

  .menu.dropdown_account {
    z-index: 9999 !important;
  }
  .social-links-footer ul li {
    margin-right: 5px;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100% !important;
  }
  .social-links-footer ul li {
    margin-right: 5px;
  }

  .search120 {
    /*width: 46%;*/
    margin-left: 15px;
  }
}

@media (min-width: 1441px) {
  .container {
    max-width: 1500px !important;
  }
  .main-banner-slider .container-fluid {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .search120 {
    /*width: 56% !important;*/
    margin-left: 15px;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
}

@media (min-width: 1441px) {
  .container-fluid {
    max-width: 1500px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .social-links-footer ul li {
    margin-right: 5px;
  }
}

@media (min-width: 1601px) {
  .search120 {
    width: 100% !important;
    margin-left: 15px;
  }
}

@media (min-width: 1920px) {
  .search120 {
    /*width: 64% !important;*/
    margin-left: 15px;
  }
  .gambo-Breadcrumb .breadcrumb .breadcrumb-item.active {
    display: none !important;
  }
}
